import { Injectable } from '@angular/core';
import {Location} from '@angular/common';
import jwt_decode from 'jwt-decode';

import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import {UserIdService} from '../services/user-id.service';
import {ApsService} from '../services/aps.service';
import {ApsAttributes} from '../models/aps-attributes';
import {Subject} from 'rxjs';
import { UserLoginDetailService } from '../services/user-login-detail.service'
import { UserLoginDetail } from '../models/UserLoginDetail'

/** This guard is used in the oauth callback flow, consuming the URL before the Angular router can.
 * The URL built by the auth provider is technically invalid (no ? for the queryParams), so this guard
 * needs to consume the URL before Angular's router (which would fail to parse it).
 */
@Injectable()
export class UrlConsumerService implements CanActivate {

	apsAttributesSubject = new Subject<ApsAttributes>();
	userLoginDetail :UserLoginDetail;

	/** Creates an instance of the OauthTokenGuard
	 *
	 * @param router route instance for current routing params
	 * @param idService the angular service for handling user ID
	 */
	constructor(private router: Router, private idService: UserIdService, private location: Location ,private apsService: ApsService, private userLoginService: UserLoginDetailService) {
	}

	/** the actual guard function. Parses the queryString and stores the params in sessionStorage.
	 * Redirects the user to the default route, or to the route that was stored before the auth redirect.
	 *
	 * @param route the snapshot of the current ActivatedRoute
	 * @param state the snapshot of the current RouterState
	 * @returns whether route can be activated or not
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		console.log('Url consumer service called');
		const queryParamsObj = this.parseParamString();
		this.userLoginDetail = new UserLoginDetail(this.userLoginDetail);
		if (queryParamsObj && queryParamsObj['access_token'] != null) {
			try {
				//token is part 2 of a JWT (index 1)
				console.log('atob decoding');
				const accessToken = atob(queryParamsObj['access_token'].split('.')[1]);
				const jsonToken = JSON.parse(accessToken);

				//store off token information in the appropriate places
				// this.storeTokenInformation(accessToken, queryParamsObj['access_token'], jsonToken);
				console.log('setting token information');
				sessionStorage.setItem('strAccessToken', accessToken);
				sessionStorage.setItem('encodedAccessToken', queryParamsObj['access_token']);
				sessionStorage.setItem('tokenIssue', jsonToken.iat);
				sessionStorage.setItem('tokenExp', jsonToken.exp);
				sessionStorage.setItem('userId', jsonToken.CommonName);
				sessionStorage.setItem('userName', jsonToken.givenName);
				let displayName = jsonToken.sn + ', ' + jsonToken.givenName;
				if (jsonToken.initials !== null && jsonToken.initials !== undefined) {
					displayName = displayName + ' (' + jsonToken.initials + ')';
				}
				sessionStorage.setItem('displayLeadName', displayName);
				sessionStorage.setItem('userMailId', jsonToken.mail);

				this.idService.setUserID(jsonToken.CommonName);
				// this.idService.setUserName(jsonToken.givenName);
				//Username set to display lead Name
				this.idService.setUserName(displayName);

				this.apsService.retrieveAttributes().subscribe(async attributes => {
					const attributeString = JSON.stringify(attributes);
					// console.log('User Role::' + JSON.parse(attributeString).userRole);
					await sessionStorage.setItem('navUserRole', JSON.parse(attributeString).userRole);
					this.userLoginDetail.userCDSID = jsonToken.CommonName;
					this.userLoginDetail.userMail = jsonToken.mail;
					this.userLoginDetail.userName = displayName;
					this.userLoginDetail.countryCode = jsonToken.c;
					this.userLoginDetail.userRole = JSON.parse(attributeString).userRole;
					console.log('save user details: ');
					this.saveUserDetails();
					this.apsAttributesSubject.next(attributes);
				});
				const urlSegments = this.extractRedirectUrlSegments(sessionStorage.getItem('redirectURL'));
				this.router.navigateByUrl(urlSegments.path, urlSegments.queryParams);
				return true;
			} catch (e) {
				console.error('atob token decode exception');
				console.log('jwt decoding');
				const jwtToken = jwt_decode(queryParamsObj['access_token']);
				// const jwtToken = '';
				console.log('entries ' + Object.entries(jwtToken));
				// sessionStorage.setItem('strAccessToken', accessToken);
				sessionStorage.setItem('encodedAccessToken', queryParamsObj['access_token']);
				sessionStorage.setItem('tokenIssue', jwtToken['iat']);
				sessionStorage.setItem('tokenExp', jwtToken['exp']);
				sessionStorage.setItem('userId', jwtToken['CommonName']);
				sessionStorage.setItem('userName', jwtToken['givenName']);
				let displayName = jwtToken['sn'] + ', ' + jwtToken['givenName'];
				if (jwtToken['initials'] !== null && jwtToken['initials'] !== undefined) {
					displayName = displayName + ' (' + jwtToken['initials'] + ')';
				}
				sessionStorage.setItem('displayLeadName', displayName);
				sessionStorage.setItem('userMailId', jwtToken['mail']);
				// sessionStorage.setItem('displayLeadPhone', jwtToken['telephoneNumber']);
				// sessionStorage.setItem('displayLeadCdsid', jwtToken['uid']);
				this.idService.setUserID(jwtToken['CommonName']);
				this.idService.setUserName(displayName);

				this.apsService.retrieveAttributes().subscribe(async attributes => {
					const attributeString = JSON.stringify(attributes);
					// console.log('User Role::' + JSON.parse(attributeString).userRole);
					await sessionStorage.setItem('navUserRole', JSON.parse(attributeString).userRole);
					this.apsAttributesSubject.next(attributes);
					// alert(JSON.parse(attributeString).userRole)
					this.userLoginDetail.userCDSID = jwtToken['CommonName'];
					this.userLoginDetail.userMail = jwtToken['mail'];
					this.userLoginDetail.userName = displayName;
					this.userLoginDetail.userRole = JSON.parse(attributeString).userRole;
					console.log('save user details: ');

					this.saveUserDetails();
				});
				const urlSegments = this.extractRedirectUrlSegments(sessionStorage.getItem('redirectURL'));
				this.router.navigateByUrl(urlSegments.path, urlSegments.queryParams);
				return true;
			}
		} else {
			console.error('Invalid Token');
		}
		return false;
	}

	/** This function set values in sessionStorage from the given parameters
	 *
	 * @param strAccessToken string representation of the access token
	 * @param encodedAccessToken encoded JWT representation of the access token as sent on the url
	 * @param jsonToken parsed representation of the access token
	 */
	storeTokenInformation(strAccessToken, encodedAccessToken, jsonToken) {
		console.log('setting token information');
		sessionStorage.setItem('strAccessToken', strAccessToken);
		sessionStorage.setItem('encodedAccessToken', encodedAccessToken);
		sessionStorage.setItem('tokenIssue', jsonToken.iat);
		sessionStorage.setItem('tokenExp', jsonToken.exp);
		sessionStorage.setItem('userId', jsonToken.CommonName);
		sessionStorage.setItem('userName', jsonToken.givenName);
		let displayName = jsonToken.sn + ', ' + jsonToken.givenName;
		if (jsonToken.initials !== null && jsonToken.initials !== undefined) {
			displayName = displayName + ' (' + jsonToken.initials + ')';
		}
		sessionStorage.setItem('displayLeadName', displayName);
		sessionStorage.setItem('userMailId', jsonToken.mail);

		this.idService.setUserID(jsonToken.CommonName);
		// this.idService.setUserName(jsonToken.givenName);
		//Username set to display lead Name
		this.idService.setUserName(displayName);

		this.apsService.retrieveAttributes().subscribe( async attributes => {
			const attributeString = JSON.stringify(attributes);
			// console.log('User Role::' + JSON.parse(attributeString).userRole);
			await sessionStorage.setItem('navUserRole', JSON.parse(attributeString).userRole);
			this.apsAttributesSubject.next(attributes);
			// alert(JSON.parse(attributeString).userRole)
		});
	}

	/** This function return a parsed segment object given a valid url with and without query parameter
	 *
	 * @param url url for redirection
	 * @returns segments object that contains properties used for navigateByUrl call
	 */
	extractRedirectUrlSegments(url: string) {
		const segments = {
			path: '/',
			queryParams: {}
		};
		if (url) {
			if (url.indexOf('?') > -1) {
				const [path, queryString] = url.split('?');
				segments.path = path.substring(path.lastIndexOf('/') + 1);
				segments.queryParams = this.getRedirectQueryParams(queryString);
			} else {
				segments.path = url;
			}
		}
		return segments;
	}

	/** This function return list of params object with valid query and return empty object with undefined query
	 *
	 * @param string query string from the url to parse parameters from
	 * @returns object containing the parsed query string params from the given url
	 */
	getRedirectQueryParams(query: string) {
		const queryObj = {};
		if (query) {
			const queryArgs = query.split('&');
			queryArgs.forEach(item => {
				const pair = item.split('=');
				if(pair!==null && pair!==undefined && pair.length>=1 )
					queryObj[pair[0]] = pair[1];
			});
			return queryObj;
		}
		return queryObj;
	}

	/** Parses the technically malformed queryString to pick off the token and associated properties.
	 * @param paramString the param string from the url
	 * @returns The queryString params in Object format, or null if the string was invalid.
	 */
	parseParamString() {
		if (this.location.path(true).indexOf('access_token') === 0) {
			const queryString = this.location.path(true);

			//URLSearchParams should be the solution here. it's not working. so we did it manually
			const paramArray = queryString.split('&');
			const queryParamsObj = new Object();
			for (const param of paramArray) {
				//we can't use a simple split() call here as base64 allows for = padding
				const i = param.indexOf('=');
				const splitArray = [param.slice(0, i), param.slice(i + 1)];
				if(typeof splitArray[0] === 'string' && !splitArray[0].includes('__proto__'))
					queryParamsObj[splitArray[0]] = splitArray[1];
			}
			return queryParamsObj;
		} else {
			return null;
		}
	}

	saveUserDetails(){
		this.userLoginService.saveUserDetails(this.userLoginDetail).subscribe(resp =>{
			console.log('User Tracker Updated Successfully');
			console.log(resp);
		});
	}
}
