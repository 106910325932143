import {ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ConfirmationService, MenuItem, SelectItem} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierViewService} from '../../services/supplier-view.service';
import {PswPartDetail} from '../../models/psw-part-detail';
import {
	auditLayout,
	DropdownOption,
	requestSubmitLevel
} from '../../models/dropdown-option';
import {EpswHomepageService} from '../../services/epsw-homepage.service';
import {PpapPackageIdentifier} from '../../models/ppap-package-identifier';
import {Message, MessageService} from 'primeng';
import {PpapPackageParts} from '../../models/ppap-package-parts';
import {DigitalPpapPackageService} from '../../services/digital-ppap-package.service';
import {RoleBasedAccessProviderService} from '../../psw-approval-flow/role-based-access-provider.service';
import {GcpMcpvSupplierVolumes} from '../../models/gcp-mcpv-supplier-volumes';
import {GcpMcpvSupplierCapcity} from '../../models/gcp-mcpv-supplier-capacity';
import {DatePipe} from '@angular/common';
import {PpapElementRecords} from '../../models/ppap-element-records';
import {CmmsPartsPpap} from '../../models/cmms-parts-ppap';
import {LdapResponse} from '../../models/ldap-response';
import {FamilyPswAnnexDetails} from '../../models/family-psw-annex-details';
import {ColorPartsFamilyPsw} from '../../models/color-parts-family-psw';
import {FamilyPswAnnexureOptionalHeader} from '../../models/FamilyPswAnnexureOptionalHeader';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {PswResponse} from '../../models/response/PswResponse';

interface City {
	name: string;
	code: string;
}

interface ApprovalSectionDetail {
	supplierInfo: string;
	status: string;
	reviewedBy: string;
	reviewedOn: string;
	comments: string;
}

@Component({
	selector: 'psw-part-form',
	templateUrl: './psw-part-form.component.html',
	styleUrls: ['./psw-part-form.component.scss'],
	providers: [ConfirmationService, MessageService]
})

export class PswPartFormComponent implements OnInit {
	// ppapPackageIdentifierID: any;
	constructor(private confirmationService: ConfirmationService,
				private router: Router, private digitalPpapPackageService: DigitalPpapPackageService,
				private supplierViewService: SupplierViewService, private route: ActivatedRoute, private homePageService: EpswHomepageService,
				private messageService: MessageService,
				private changeDetection: ChangeDetectorRef,
				private digitalService: DigitalPpapPackageService, private datePipe: DatePipe, private spinnerService: SpinnerService,) {
		this.displayPpapPhase = sessionStorage.getItem('displayPpapPhase');
		this.ppapPackageIdentifierId = this.route.snapshot.paramMap.get('id');
		this.mode = this.route.snapshot.paramMap.get('mode');
		this.actionMode = this.route.snapshot.paramMap.get('actionMode');
		// @ts-ignore
		if (this.actionMode === 'familyNew' || this.actionMode === 'familyView')
			this.spinnerService.showFamilySpinner();
		console.log('*********', this.mode);
		console.log('**********', this.actionMode);
		this.ppapPackagePartsEntityId = Number(this.route.snapshot.paramMap.get('partId'));
		//Added for US3853075
		this.familyPswId = sessionStorage.getItem('familyPswId');
		// this.familyPswID = sessionStorage.getItem('familyPswId');
		this.phaseType = sessionStorage.getItem('ppapPhase');
		this.getSelectedPartDataSupplierView();
		//Added for US3853075
		this.ppapPackageIdentifierDisplay = new PpapPackageIdentifier(this.ppapPackageIdentifierDisplay);
		this.entityIdList = sessionStorage.getItem('checkedEntityIdList');
		// if (this.familyPswId === 0) {
		// 	if(this.entityIdList !== null) {
		// 		this.supplierViewService.getAllPartListData(this.entityIdList).subscribe(resp => {
		// 			this.ppapPartsArray = resp;
		// 			this.disableDelete = false;
		// 			this.isShownAddParts = false;
		// 		});
		// 	}
		// } else {
		// 	if(this.familyPswId !== null && this.familyPswId !== undefined && this.familyPswId !== 0){
		// 		this.isShownAddParts = true;
		// 		this.disableDelete = true;
		// 		this.supplierViewService.getFamilyRetrievalData(this.familyPswId).subscribe(respOne => {
		// 			this.ppapPartsArray = respOne;
		// 			let id = '';
		// 			for(const data of this.ppapPartsArray){
		// 				if (id === '') {
		// 					id = data.ppapPackagePartsEntityID.toString();
		// 				} else {
		// 					id = id + ',' + data.ppapPackagePartsEntityID.toString();
		// 				}
		// 			}
		// 			this.entityIdList = id;
		// 			sessionStorage.setItem('checkedEntityIdList', this.entityIdList.toString());
		// 		});
		// 	} else {
		// 		this.isShownAddParts = false;
		// 		this.disableDelete = false;
		// 	}
		// }
		// this.ppapPackageIdentifierId = this.ppapPartsArray[0].ppapPackageIdentifierID;
		// this.ppapLevelParts = this.ppapPartsArray[0].ppapLevel;
		// this.ppapPackagePartsEntityId = this.router.getCurrentNavigation().extras.state.PpapPackagePartsId;
		// this.programCode = this.router.getCurrentNavigation().extras.state.programCode;
		// this.noPartPrefix = this.router.getCurrentNavigation().extras.state.noPartPrefix;
		// this.noPartBase = this.router.getCurrentNavigation().extras.state.noPartBase;
		// this.noPartSuffix = this.router.getCurrentNavigation().extras.state.noPartSuffix;
		// this.ppapLevel = this.router.getCurrentNavigation().extras.state.ppapLevel;
		// this.ppapPackageIdentifierFromSupplier = this.router.getCurrentNavigation().extras.state.ppapPackageIdentifier;
		// this.ppapPackageIdentifierFromSupplier = this.ppapPackageIdentifier;
		// console.log(this.router.getCurrentNavigation().extras.state.actionMode);
		// this.actionMode = this.router.getCurrentNavigation().extras.state.actionMode;
		//this.pswPartDetailId = this.router.getCurrentNavigation().extras.state.PswPartDetailId;
		// this.mode = this.router.getCurrentNavigation().extras.state.mode;
		//this.isAuditLayoutVisible = false;
		this.isShownMandatory = false;
		if (this.mode === 'view') {
			this.enableOnViewMode = false;
		} else {
			this.enableOnViewMode = true;
		}
		this.isOtherTextVisible = false;
		this.isDesignExplnVisible = false;
		this.isViewMode = true;
		this.displayDialog = false;
		this.display = false;
		this.showPanel = false;
		this.partNumberDetail = [];
		this.homePageService.getDataOfSelectedRow(this.ppapPackageIdentifierId).subscribe(resp => {
			this.ppapPackageIdentifier = resp.ppapPackageIdentifierEntity;
			console.log('Testing '+this.ppapPackageIdentifier);
			console.log(this.ppapPackageIdentifier.siteCode);
			this.ppapPackageIdentifierFromSupplier = this.ppapPackageIdentifier;
			if (sessionStorage.getItem('attributes') !== null) {
				this.apsAttributes = sessionStorage.getItem('attributes');
				this.userRole = JSON.parse(this.apsAttributes).userRole;
				this.pswAccessControl = RoleBasedAccessProviderService
					.getPhaseBasedAccessForSupplier(this.userRole, this.phaseType);
				this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
					|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
				if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
					(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
					if (this.userRole === 'STASiteEngineer' || this.userRole === 'APQPSiteSTAEngineer'
						|| this.userRole === 'STASiteSupervisor' || this.userRole === 'STASiteManager') {
						if (this.userRole === 'STASiteEngineer') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteEngineer', true)
							}
						} else if (this.userRole === 'STASiteSupervisor') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteSupervisor', true)
							}
						} else if (this.userRole === 'STASiteManager') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteManager', true)
							}
						} else {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('APQPSiteSTAEngineer', true)
							}
						}
					} else if (this.userRole === 'PDEngineer') {
						if (this.ppapPackageIdentifierFromSupplier.pdApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
							this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('PDEngineer', true)
						}
					} else {
						this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover(this.userRole, false)
					}
				} else {
					if (this.userRole === 'STASiteEngineer' || this.userRole === 'APQPSiteSTAEngineer'
						|| this.userRole === 'STASiteSupervisor' || this.userRole === 'STASiteManager') {
						if (this.userRole === 'STASiteEngineer') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteEngineer', true)
							}
						} else if (this.userRole === 'STASiteSupervisor') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteSupervisor', true)
							}
						} else if (this.userRole === 'STASiteManager') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteManager', true)
							}
						} else {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('APQPSiteSTAEngineer', true)
							}
						}
					} else {
						this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover(this.userRole, false)
					}
				}
			}
			if (sessionStorage.getItem('attributes') !== null) {
				this.apsAttributes = sessionStorage.getItem('attributes');
				this.userRole = JSON.parse(this.apsAttributes).userRole;
				this.pswAccessControl = RoleBasedAccessProviderService.getAccessForSupplierRole(this.userRole);
				if (this.pswAccessControl.get('supplierAccess')) {
					this.isVisibleFordRequirements = false;
				}
			}
			this.getFormCompletionStatus();
			this.getAllPartsLengthDetails();
			this.getAllIdentifierDetails();
			//this.isViewMode = false;
			//this.supplierMode = false;
			//this.staMode = false;
			this.val1 = 'Phase 1';
			this.val2 = 'Phase 2';
			this.val3 = 'Phase 3';
			//this.selectedCheckBox = false;
			if (this.actionMode === 'view') {
				this.enableAction = false;
				if (this.ppapPackagePartsEntityId !== undefined && this.ppapPackagePartsEntityId !== null) {
					this.supplierViewService.getData(this.ppapPackagePartsEntityId).subscribe(response => {
						const respOne = response.pswPartDetailEntity;
						console.log('get Data: ' + respOne);
						if (respOne.ppapPackagePartsEntityID !== null) {
							this.pswPartDetailSave = respOne;
							this.pswPartDetailSave.reqSubmitLvl = {name: respOne.reqSubmitLvl, code: null};
							this.pswPartDetailSave.paReqSource = respOne.paReqSource;
							this.pswPartDetailSave.aftrPaReqSource = respOne.aftrPaReqSource;
							//this.pswPartDetailSave.nextAuditPlan = {name: respOne.nextAuditPlan, code: null};
						}
					});
				} else {
					this.getAllData();
					this.supplierViewService.getAllPswData(this.ppapPackageIdentifierId).subscribe(resptwo => {
						this.pswPartDetailFamily = resptwo;
						this.supplierViewService.getAllPswPartDetailData().subscribe(respOne => {
							this.pswPartDetailSaveAll = respOne;
							// this.loading = false;
							// for(const item of this.ppapPartsArray){
							// 	this.supplierViewService.getFamilyAnnexDetails(item.ppapPackagePartsEntityID).subscribe(respOne => {
							// 		this.ppapPartsArray = respOne;
							// 	});
							// }
						});
					});
				}
				//Added for US3853075
				// if (this.familyPswId === 0) {
				// 	if(this.entityIdList !== null) {
				// 		this.supplierViewService.getAllPartListData(this.entityIdList).subscribe(response => {
				// 			this.ppapPartsArray = response;
				// 			// this.disableDelete = false;
				// 			this.isShownAddParts = false;
				// 		});
				// 	}
				// } else {
				// 	if(this.familyPswId !== null && this.familyPswId !== undefined && this.familyPswId !== 0){
				// 		this.isShownAddParts = true;
				// 		// this.disableDelete = true;
				// 		this.supplierViewService.getFamilyRetrievalData(this.familyPswId).subscribe(respOne => {
				// 			this.ppapPartsArray = respOne;
				// 			this.retrieveFamilyAnnexDetails();
				// 			let id = '';
				// 			for(const data of this.ppapPartsArray){
				// 				if (id === '') {
				// 					id = data.ppapPackagePartsEntityID.toString();
				// 				} else {
				// 					id = id + ',' + data.ppapPackagePartsEntityID.toString();
				// 				}
				// 			}
				// 			this.entityIdList = id;
				// 			sessionStorage.setItem('checkedEntityIdList', this.entityIdList.toString());
				// 		});
				// 	} else {
				// 		this.isShownAddParts = false;
				// 		// this.disableDelete = false;
				// 	}
				// }
			} else {
				this.enableAction = false;
				this.getAllData();
				this.supplierViewService.getAllPswData(this.ppapPackageIdentifierId).subscribe(resp1 => {
					this.pswPartDetailFamily = resp1;
					this.supplierViewService.getAllPswPartDetailData().subscribe(respOne => {
						this.pswPartDetailSaveAll = respOne;
						// this.getSupplierDetails();
						this.getAllSupplierDetails();
						this.onSelectDropdown();
						this.getInterimDisplay();
						this.getFamilyPSWAnnexureOptionalHeader();
						// this.loading = false;
						// for(const item of this.ppapPartsArray){
						// 	this.supplierViewService.getFamilyAnnexDetails(item.ppapPackagePartsEntityID).subscribe(respOne => {
						// 		this.ppapPartsArray = respOne;
						// 	});
						// }
					});
				});
			}
		});
	}

	/**
	 * The color parts list are made distinct based on unqiuedId
	 * The color parts having same siteCode, programName, prefix, base and sufix won't be repeated
	 */
	get distinctColorPartsList(): ColorPartsFamilyPsw[] {
		if (!this.colorPartsList?.length) return [];
		const distinctColorParts: ColorPartsFamilyPsw[] = [];
		this.colorPartsList.forEach((t: ColorPartsFamilyPsw) => {
			if (distinctColorParts.findIndex(
				(part) => (
					t.siteCode === part.siteCode &&
					t.programName === part.programName &&
					t.noPartBase === part.noPartBase &&
					t.noPartPrefix === part.noPartPrefix &&
					t.noPartSuffix === part.noPartSuffix
				)) === -1)
				distinctColorParts.push(t)
		})
		return distinctColorParts
	}
	@ViewChildren('optionaltext1') optionaltext1: QueryList<ElementRef>;
	@ViewChildren('optionaltext2') optionaltext2: QueryList<ElementRef>;
	@ViewChildren('optionaltext3') optionaltext3: QueryList<ElementRef>;
	@ViewChildren('optionaltext4') optionaltext4: QueryList<ElementRef>;
	@ViewChildren('optionaltext5') optionaltext5: QueryList<ElementRef>;
	selectedCheckBox: string;
	selectedSubmissionResultCheckBox: string;
	val1: string;
	val2: string;
	val3: string;
	isShown: boolean;
	display: boolean;
	cities: City[];
	selectedCities: City[];
	submissionLevelOption: DropdownOption[] = requestSubmitLevel;
	isViewMode: boolean;
	cols: any[];
	approvalSectionDetail: ApprovalSectionDetail[];
	supplierMode: boolean;
	staMode: boolean;
	isVisible: boolean;
	pswPartDetailSave: PswPartDetail;
	cmmsPartsPpapSave: CmmsPartsPpap[];
	pswPartDetail: PswPartDetail;
	//engChangeLvlDate: Date;
	auditLayout: DropdownOption[] = auditLayout;
	ppapPackagePartsEntityId: number;
	ppapPackageIdentifierId: any;
	ppapPackageIdentifier: PpapPackageIdentifier;
	msgs: Message[] = [];
	menuItems: MenuItem[];
	home: MenuItem;
	gcpMcpvVolumes: GcpMcpvSupplierVolumes[];
	previousVal: any;
	supplierName: string;
	partNumberDetail: PpapPackageParts[] = [];
	ppapPackagePartSave: PpapPackageParts;
	ppapPackageParts: PpapPackageParts[];
	isValidSave = true;
	private noPartPrefix: any;
	private noPartBase: any;
	private noPartSuffix: any;
	gcpMcpvCapacity: GcpMcpvSupplierCapcity[];
	showPanel: any;
	displayDialog: boolean;
	enableOnViewMode: boolean;
	apsAttributes: any;
	userRole: any;
	pswAccessControl: Map<string, any> = new Map<string, any>();
	pswApproverAccessControl: Map<string, any> = new Map<string, any>();
	mode: any;
	actionMode: string;
	disableApproverActions: boolean;
	needApproverAction: boolean;
	ppappackagePartsEntityId: number;
	ppapPdStatus: any;
	ppapStaComments = '';
	ppapPdComments = '';
	overallStatus: any;
	ppapStaStatusBy: any;
	ppapStaStatusOn: any;
	ppapPdStatusBy: any;
	ppapPdStatusOn: any;
	ppapStaStatus: any;
	pswStaStatus: any;
	pswStaComments = '';
	pswStaStatusBy: any;
	pswStaStatusOn: any;
	pswPdStatus: any;
	pswPdComments = '';
	pswPdStatusBy: any;
	pswPdStatusOn: any;
	pswOverallStatus: any;
	disableAuditFields = false;
	commentsEnableCondition: boolean;
	ppapOverallStatus: string;
	enableRejectButton: boolean;
	enableNeedClarificationButton: boolean;
	disableApproverOptions: boolean;
	submittedBy: any;
	submittedOn: any;
	enableApproveroptions: boolean;
	isOtherTextVisible: boolean;
	isDesignExplnVisible: boolean;
	selectedInterimCheckBox = false;
	ppapLevel: any;
	ppapPackageIdentifierFromSupplier: any;
	isVisibleFordRequirements = true;
	paReqSourceOption: SelectItem[];
	aftrPaReqSourceOption: SelectItem[];
	isAuditLayoutVisible = false;
	isPhaseCheckboxVisible = false;
	isShownMandatory: boolean;
	staPdStatus: string;
	tier2Saved = false;
	cdsidNotSaved = false;
	staPdStatusList: string[] = [];
	displayInterimAccepted: boolean;
	requiredElementsNotSaved = false;
	ppapAssessmentResponse: PpapElementRecords[] = [];
	disableSubmit = true;
	status: any;
	hideSubmit = false;
	isSubmitted = false;
	private programCode: any;
	sectionList: any[] = [];
	private ldapResponse: LdapResponse[];
	private approverCdsidStaMail: string;
	private approverCdsidStaName: string;
	private approverCdsidPdMail: string;
	private approverCdsidPdName: string;
	selectedPhaseValue: boolean;
	private pswStaUserId: string;
	selectedPhaseValueYN: string;
	private pswPdUserId: string;
	phaseType: string;
	displayPpapPhase: string;
	isInterimCheckboxVisible: boolean;
	//Added for US3853075
	ppapPartsArray: PpapPackageParts[] = [];
	ppapPackageIdentifierDisplay: PpapPackageIdentifier;
	partNumbersOption: SelectItem[] = [];
	partNumbers: DropdownOption;
	partDisableEnable = true;
	ppapPartsArrayLength: any;
	dtOrgList: any[];
	partPappLevel: any;
	entityIdList: string;
	pswPartDetailSaveAll: PswPartDetail[];
	pswPartDetailFamily: PswPartDetail[];
	isLoading = false;
	familyPswId: any;
	isShownAddParts: boolean;
	disableDelete: boolean;
	familyPswAnnexDetails: FamilyPswAnnexDetails[];
	clonedData: { [ppapPackagePartsEntityID: number]: PpapPackageParts; } = {};
	loading: any;
	familyId: any;
	private familyPswID: any;
	colorPartsList: ColorPartsFamilyPsw[] = [];
	colorPartsListFromUnfiltered: ColorPartsFamilyPsw[] = [];
	enableAction: boolean;
	colorPartNumbersOption: SelectItem[] = [];
	colorPartNumbers: DropdownOption;
	colorPartDisableEnable = true;
	enableAddParts: boolean;
	partNumberList: string[] = [];
	familyPswAnnexureOptionalHeaderpartNumberList: string[] = ['APW', 'MPW', 'APPC', 'MPPC', 'OPTIONAL'];
	isOptional1 = true;
	isOptional2 = true;
	isOptional3 = true;
	isOptional4 = true;
	isOptional5 = true;
	familyPswOptionalObject: FamilyPswAnnexureOptionalHeader;
	familyHeaderID: any;
	isOtherTextSave: boolean;
	isDesignTextSave: boolean;
	isInterimTextSave: boolean;
	isReasonSave: boolean;
	isSubmissionResultSave: boolean;
	isAnnexureSave: boolean;
	isHideAnnexureForIndividualpart: boolean;
	pswResponse: PswResponse | void;

	ecarPpapAssessmentResponse: PpapElementRecords[] = [];
	supplierRemark = '';
	errorState = false;
	checkStatus = '';
	initialRemark = '';

	ngOnInit(): void {
		console.log('in ngOnInit' +   this.ppapPackageIdentifierId)
		this.onSelectedPhaseValue();
		this.ecarPpapAssessmentResponse = [];
		this.pswPartDetailSave = new PswPartDetail(this.pswPartDetail);
		this.supplierViewService.getRetrievalData(this.ppapPackagePartsEntityId).subscribe(response => {
			const resp = response.pswPartDetailEntity;
			console.log('this is response'+ JSON.stringify(response));
			console.log(resp);

			const ppapPackageIdentifierId = resp.ppapPackageIdentifierID; // Access directly from resp
			// OR
			const ppapPackageIdentifierIdFromParts = resp.ppapPackagePartsEntity?.ppapPackageIdentifierID; // Access via ppapPackagePartsEntity (using optional chaining for safety)

			console.log('ppapPackageIdentifierId: ', ppapPackageIdentifierId);
			console.log('ppapPackageIdentifierId (from parts): ', ppapPackageIdentifierIdFromParts);

			if(Object.keys(resp).length){
			if (resp.ppapPackagePartsEntityID !== null) {
				this.pswPartDetailSave = resp;
				if (this.pswPartDetailSave.custDivision === undefined ||
					this.pswPartDetailSave.custDivision === null || this.pswPartDetailSave.custDivision === '') {
					this.pswPartDetailSave.custDivision = 'Ford Motor Company';
				}
				if (resp.submissionReason.includes('Other - please specify')) {
					this.isOtherTextVisible = true;
				} else {
					this.isOtherTextVisible = false;
				}
				if (resp.meetDesignReq === 'No') {
					this.isDesignExplnVisible = true;
				} else {
					this.isDesignExplnVisible = false;
				}
				if (resp.interimPpapStatus === 'true') {
					this.selectedInterimCheckBox = true;
					this.displayInterimAccepted = true;
					if (this.selectedInterimCheckBox === true) {
						this.isShownMandatory = true;
					}
				} else {
					this.selectedInterimCheckBox = false;
					this.displayInterimAccepted = false;
					if (this.selectedInterimCheckBox === false) {
						this.isShownMandatory = false;
					}
				}
				const submissionReasonResult = resp.submissionReason.split('#');
				this.pswPartDetailSave.submissionReason = submissionReasonResult;
				const submitResult = resp.submitResultFor.split('#');
				this.pswPartDetailSave.submitResultFor = submitResult;
				this.pswPartDetailSave.reqSubmitLvl = {name: resp.reqSubmitLvl, code: null};
				this.pswPartDetailSave.paReqSource = resp.paReqSource;
				this.pswPartDetailSave.aftrPaReqSource = resp.aftrPaReqSource;
				//this.pswPartDetailSave.nextAuditPlan = {name: resp.nextAuditPlan, code: null};
				this.getInterimDisplay();
				//Added for US4027839
				// if(this.familyPswId !== 0) {
				// 	// this.supplierViewService.saveFamilyAnnexDetails(this.familyPswId).subscribe(
				// 	// 	respThree => {
				// 			this.retrieveFamilyAnnexDetails();
				// 		// });
				// }
			}
			} else {
				console.log('Screen 3'+this.pswPartDetail)
				this.pswPartDetailSave = new PswPartDetail(this.pswPartDetail);
				this.pswPartDetailSave.custDivision = 'Ford Motor Company';
				if (this.actionMode !== 'familyNew') {
					this.getAllPartsDetails();
				}
				this.getSupplierDetails();
				this.onSelectDropdown();
				this.getInterimDisplay();
			}
		});
		this.cities = [
			{name: 'AB39 – 2122345 – AE', code: 'NY'},
			{name: 'AB39 – 2122346 – AE', code: 'RM'},
			{name: 'AB39 – 2122347 – AE', code: 'LDN'},
			{name: 'AB39 – 2122348 – AE', code: 'IST'},
			{name: 'AB39 – 2122349 – AE', code: 'PRS'}
		];

		this.cols = [
			{field: 'supplierInfo', header: ' '},
			{field: 'status', header: 'Status'},
			{field: 'reviewedBy', header: 'Reviewed By'},
			{field: 'reviewedOn', header: 'Reviewed On'},
			{field: 'comments', header: 'Comments'}
		];
		this.approvalSectionDetail = [
			{
				supplierInfo: 'STA',
				status: 'APPROVED',
				reviewedBy: 'User 1',
				reviewedOn: '08-Jul-2021 4:38PM',
				comments: 'Application Approved'
			},
			{
				supplierInfo: 'PD',
				status: 'INTERIM ACCEPTED',
				reviewedBy: 'User 1',
				reviewedOn: '08-Jul-2021 4:38PM',
				comments: 'Application Interim accepted'
			}
		]

		//Breadcrumb
		this.home = {icon: 'fa fa-home'};
		this.menuItems = [
			{
				label: 'PPAP PACKAGE',
				//routerLink: '/DigitalPPAPPackageComponent'
			},
			{
				label: 'PSW HOME',
				//routerLink: '/SupplierViewComponent/'+ this.ppapPackageIdentifierId,
			},
			{
				label: 'PSW',
				//routerLink: '/PswPartFormComponent',
			}
		];
		//Added for TA7514465
		this.paReqSourceOption = [
			{label: 'Select', value: null},
			{label: 'Initial Tool Order', value: 'Initial Tool Order'},
			{label: 'WebQuote', value: 'WebQuote'},
			{label: 'CPA (Commercial and Program Agreement)', value: 'CPA (Commercial and Program Agreement)'},
			{label: 'SAL (Sourcing Agreement Letter)', value: 'SAL (Sourcing Agreement Letter)'},
			{label: 'Other (specify in detail at right)', value: 'Other (specify in detail at right)'}
		];

		this.aftrPaReqSourceOption = [
			{label: 'Select', value: null},
			{label: 'Amended or subsequent Tool Order', value: 'Amended or subsequent Tool Order'},
			{label: 'WebQuote', value: 'WebQuote'},
			{label: 'CPA (Commercial and Program Agreement)', value: 'CPA (Commercial and Program Agreement)'},
			{label: 'SAL (Sourcing Agreement Letter)', value: 'SAL (Sourcing Agreement Letter)'},
			{label: 'Other (specify in detail at right)', value: 'Other (specify in detail at right)'}
		];
		if (this.actionMode === 'familyNew') {
			this.pswPartDetailSave = new PswPartDetail(this.pswPartDetail);
			this.supplierViewService.getAllPswData(this.ppapPackageIdentifierId).subscribe(resp => {
				console.log(resp);
				this.pswPartDetailFamily = resp;
				// this.digitalPpapPackageService.getAllData(this.ppapPackageIdentifierId).subscribe(respTwo => {
				// 	this.ppapPackageParts = respTwo;
				if (resp.length === 0) {
					this.pswPartDetailSave.ppapPackageIdentifierID = this.ppapPackageIdentifierId;
					this.pswPartDetailSave.programCode = this.programCode;
					this.saveInterimPpapStatusCheckboxValues();
					this.saveSubmissionReasonCheckboxValues();
					this.saveSubmissionResultCheckboxValues();
					this.saveRequestLvlDropdown();
					this.savePaReqSourceDropdown();
					this.saveAftrPaReqSourceDropdown();
					//this.saveAnnualDropdown();
					// this.savePhaseCheckboxValues();
					this.pswPartDetailSave.isFormCompleted = 'false';
					if (this.pswPartDetailSave.authSignDate === null ||
						this.pswPartDetailSave.authSignDate === undefined) {
						this.pswPartDetailSave.authSignDate = new Date();
					}
					this.supplierViewService.saveAllData(this.pswPartDetailSave, this.entityIdList, 'CREATE').subscribe(respOne => {
						console.log('Save Data: ' + respOne);
						this.savePpapRecords();
						this.pswPartDetailSaveAll = respOne[0];
						this.familyPswAnnexDetails = respOne[1];
						// this.supplierViewService.getAllPswPartDetailData().subscribe(resp => {
						// 	this.pswPartDetailSaveAll = resp;
						this.ppapPartsArray = respOne[2];
						console.log('PPAP_PARTS_Response_10: ', this.ppapPartsArray, '\nSIZE_10: ', this.ppapPartsArray.length);
						for (const data of this.ppapPartsArray) {
							this.familyId = data.familyPswId;
						}
						if (this.familyId !== undefined && this.familyId !== null) {
							sessionStorage.setItem('familyPswId', this.familyId.toString());
							this.familyPswId = sessionStorage.getItem('familyPswId');
						}
						// this.digitalService.getAllData(this.ppapPackageIdentifierId).subscribe(respOne => {
						// 	this.partNumberDetail = respOne;
						// for (const partDetail of this.partNumberDetail) {
						this.spinnerService.hideFamilySpinner();
					});
				} else {
					this.getAllData();
					this.supplierViewService.getAllPswData(this.ppapPackageIdentifierId).subscribe(resp2 => {
						this.pswPartDetailFamily = resp2;
						this.supplierViewService.getAllPswPartDetailData().subscribe(respOne => {
							this.pswPartDetailSaveAll = respOne;
							this.getAllSupplierDetails();
							this.onSelectDropdown();
							this.getInterimDisplay();
						});
						// this.loading = false;
						// for(const item of this.ppapPartsArray){
						// 	this.supplierViewService.getFamilyAnnexDetails(item.ppapPackagePartsEntityID).subscribe(respOne => {
						// 		this.ppapPartsArray = respOne;
						// 	});
						// }
					});
				}
			});
			// });
		}
		if (this.ppapPackagePartsEntityId !== null && this.ppapPackagePartsEntityId !== undefined && this.ppapPackagePartsEntityId !== 0) {
			this.supplierViewService.getPartsRetrievalData(this.ppapPackagePartsEntityId).subscribe(response => {
				const resp = response.ppapPackagePartsEntity;
				if (resp.ppapPackagePartsEntityID !== null) {
					this.ppapPackagePartSave = resp;
					if (resp.phase1Phase2 === 'true') {
						this.selectedPhaseValue = true;
						this.selectedPhaseValueYN = 'Yes';
					} else if (resp.phase1Phase2 === 'false') {
						this.selectedPhaseValue = false;
						this.selectedPhaseValueYN = 'No';
					}
				}
			});
		}
		this.familyPswOptionalObject = {
			createdBy: '',
			createdOn: undefined,
			familyPswAnnexureOptionalHeader1: '',
			familyPswAnnexureOptionalHeader2: '',
			familyPswAnnexureOptionalHeader3: '',
			familyPswAnnexureOptionalHeader4: '',
			familyPswAnnexureOptionalHeader5: '',
			familyPswAnnexureOptionalHeaderID: 0,
			lastUpdatedBy: sessionStorage.getItem('userName'),
			lastUpdatedOn: undefined,
			ppapPackageIdentifierID: 0
		};
		this.getFamilyPSWAnnexureOptionalHeader();
		this.hideAnnexureForIndividualPart();

		this.getEcarDetails(this.ppapPackageIdentifierId);
	}

	private hideAnnexureForIndividualPart() {
		this.supplierViewService.getAllMinCreatedByData(this.ppapPackageIdentifierId).subscribe(respOne => {
			console.log('Annexure Display for family & Hide for Individual');
			console.log('this.pswPartDetailFamily.length ' + respOne);
			if (respOne.length >= 1) {
				console.log('Hiding annexure, Since the package created before family psw concept introduced');
				this.isHideAnnexureForIndividualpart = true;
			} else {
				console.log('Displaying annexure, Since the package created after family psw concept introduced');
				this.isHideAnnexureForIndividualpart = false;
			}
			this.getColorParts();
		});
	}

	retriveipackage() {
		this.homePageService.getDataOfSelectedRow(this.ppapPackageIdentifierId).subscribe(resp => {
			this.ppapPackageIdentifier = resp.ppapPackageIdentifierEntity;
			console.log(this.ppapPackageIdentifier.siteCode);
			console.log('Setting ppapPackageIdentifier ');
			this.ppapPackageIdentifierFromSupplier = this.ppapPackageIdentifier;
			console.log('Setting ppapPackageIdentifierFromSupplier ');
			console.log(this.ppapPackageIdentifier);
			if (sessionStorage.getItem('attributes') !== null) {
				this.apsAttributes = sessionStorage.getItem('attributes');
				this.userRole = JSON.parse(this.apsAttributes).userRole;
				this.pswAccessControl = RoleBasedAccessProviderService
					.getPhaseBasedAccessForSupplier(this.userRole, this.phaseType);
				this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
					|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
				if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
					(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
					if (this.userRole === 'STASiteEngineer' || this.userRole === 'APQPSiteSTAEngineer'
						|| this.userRole === 'STASiteSupervisor' || this.userRole === 'STASiteManager') {
						if (this.userRole === 'STASiteEngineer') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteEngineer', true)
							}
						} else if (this.userRole === 'STASiteSupervisor') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteSupervisor', true)
							}
						} else if (this.userRole === 'STASiteManager') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteManager', true)
							}
						} else {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('APQPSiteSTAEngineer', true)
							}
						}
					} else if (this.userRole === 'PDEngineer') {
						if (this.ppapPackageIdentifierFromSupplier.pdApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
							this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('PDEngineer', true)
						}
					} else {
						this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover(this.userRole, false)
					}
				} else {
					if (this.userRole === 'STASiteEngineer' || this.userRole === 'APQPSiteSTAEngineer'
						|| this.userRole === 'STASiteSupervisor' || this.userRole === 'STASiteManager') {
						if (this.userRole === 'STASiteEngineer') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteEngineer', true)
							}
						} else if (this.userRole === 'STASiteSupervisor') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteSupervisor', true)
							}
						} else if (this.userRole === 'STASiteManager') {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteManager', true)
							}
						} else {
							if (this.ppapPackageIdentifierFromSupplier.staApproverCdsid === sessionStorage.getItem('userId').toLocaleUpperCase()) {
								this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('APQPSiteSTAEngineer', true)
							}
						}
					} else {
						this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover(this.userRole, false)
					}
				}
			}
			if (sessionStorage.getItem('attributes') !== null) {
				this.apsAttributes = sessionStorage.getItem('attributes');
				this.userRole = JSON.parse(this.apsAttributes).userRole;
				this.pswAccessControl = RoleBasedAccessProviderService.getAccessForSupplierRole(this.userRole);
				if (this.pswAccessControl.get('supplierAccess')) {
					this.isVisibleFordRequirements = false;
				}
			}
			this.getFormCompletionStatus();
			this.getAllPartsLengthDetails();
			this.getAllIdentifierDetails();
			//this.isViewMode = false;
			//this.supplierMode = false;
			//this.staMode = false;
			this.val1 = 'Phase 1';
			this.val2 = 'Phase 2';
			this.val3 = 'Phase 3';
			//this.selectedCheckBox = false;
			if (this.actionMode === 'view') {
				this.enableAction = false;
				if (this.ppapPackagePartsEntityId !== undefined && this.ppapPackagePartsEntityId !== null) {
					this.supplierViewService.getData(this.ppapPackagePartsEntityId).subscribe(response => {
						const respOne = response.pswPartDetailEntity;
						console.log('get Data: ' + respOne);
						if (respOne.ppapPackagePartsEntityID !== null) {
							this.pswPartDetailSave = respOne;
							this.pswPartDetailSave.reqSubmitLvl = {name: respOne.reqSubmitLvl, code: null};
							this.pswPartDetailSave.paReqSource = respOne.paReqSource;
							this.pswPartDetailSave.aftrPaReqSource = respOne.aftrPaReqSource;
							//this.pswPartDetailSave.nextAuditPlan = {name: respOne.nextAuditPlan, code: null};
						}
					});
				} else {
					this.getAllData();
					this.supplierViewService.getAllPswData(this.ppapPackageIdentifierId).subscribe(resptwo => {
						this.pswPartDetailFamily = resptwo;
						this.supplierViewService.getAllPswPartDetailData().subscribe(respOne => {
							this.pswPartDetailSaveAll = respOne;
							// this.loading = false;
							// for(const item of this.ppapPartsArray){
							// 	this.supplierViewService.getFamilyAnnexDetails(item.ppapPackagePartsEntityID).subscribe(respOne => {
							// 		this.ppapPartsArray = respOne;
							// 	});
							// }
						});
					});
				}
				//Added for US3853075
				// if (this.familyPswId === 0) {
				// 	if(this.entityIdList !== null) {
				// 		this.supplierViewService.getAllPartListData(this.entityIdList).subscribe(response => {
				// 			this.ppapPartsArray = response;
				// 			// this.disableDelete = false;
				// 			this.isShownAddParts = false;
				// 		});
				// 	}
				// } else {
				// 	if(this.familyPswId !== null && this.familyPswId !== undefined && this.familyPswId !== 0){
				// 		this.isShownAddParts = true;
				// 		// this.disableDelete = true;
				// 		this.supplierViewService.getFamilyRetrievalData(this.familyPswId).subscribe(respOne => {
				// 			this.ppapPartsArray = respOne;
				// 			this.retrieveFamilyAnnexDetails();
				// 			let id = '';
				// 			for(const data of this.ppapPartsArray){
				// 				if (id === '') {
				// 					id = data.ppapPackagePartsEntityID.toString();
				// 				} else {
				// 					id = id + ',' + data.ppapPackagePartsEntityID.toString();
				// 				}
				// 			}
				// 			this.entityIdList = id;
				// 			sessionStorage.setItem('checkedEntityIdList', this.entityIdList.toString());
				// 		});
				// 	} else {
				// 		this.isShownAddParts = false;
				// 		// this.disableDelete = false;
				// 	}
				// }
			} else {
				this.enableAction = false;
				this.getAllData();
				this.supplierViewService.getAllPswData(this.ppapPackageIdentifierId).subscribe(resp1 => {
					this.pswPartDetailFamily = resp1;
					this.supplierViewService.getAllPswPartDetailData().subscribe(respOne => {
						this.pswPartDetailSaveAll = respOne;
						// this.getSupplierDetails();
						this.getAllSupplierDetails();
						this.onSelectDropdown();
						this.getInterimDisplay();
						this.getFamilyPSWAnnexureOptionalHeader();
						// this.loading = false;
						// for(const item of this.ppapPartsArray){
						// 	this.supplierViewService.getFamilyAnnexDetails(item.ppapPackagePartsEntityID).subscribe(respOne => {
						// 		this.ppapPartsArray = respOne;
						// 	});
						// }
					});
				});
			}
		});
	}

	onSelectedPhaseValue() {
		if (this.selectedPhaseValueYN === 'Yes') {
			this.selectedPhaseValue = true;
		} else if (this.selectedPhaseValueYN === 'No') {
			this.selectedPhaseValue = false;
		}
	}

	showConfirmationDialogPhase(valu) {
		const confirmationMsg: string = (valu === 'Yes') ? 'Selecting \'Yes\' confirms you have only One Production Stream (Phase1 = Phase2) to produce the part/parts?' :
			'Selecting \'No\' confirms you have more than One Production Stream (Phase1≠Phase2) to produce the part/parts?';
		if (this.isViewMode) {
			this.confirmationService.confirm({
				message: confirmationMsg,
				header: 'Confirmation',
				icon: 'pi pi-info-circle',
				acceptLabel: 'OK',
				rejectVisible: false,
				accept: () => {
					this.msgs = [];
					this.onSelectedPhaseValue();
				},
				reject: () => {
					this.selectedPhaseValueYN = (this.selectedPhaseValue === false) ? 'No' : (this.selectedPhaseValue === true) ? 'Yes' : 'NA';
					this.onSelectedPhaseValue();
				}
			});
		} else {
			this.selectedPhaseValueYN = (this.selectedPhaseValue === false) ? 'No' : (this.selectedPhaseValue === true) ? 'Yes' : 'NA';
			this.onSelectedPhaseValue();
		}
	}

	showDialog() {
		this.display = true;
	}

	getSupplierDetails() {
		this.homePageService.getDataOfSelectedRow(this.ppapPackageIdentifierId).subscribe(resp => {
			this.ppapPackageIdentifier = resp.ppapPackageIdentifierEntity;
			this.homePageService.getSupplierName(this.ppapPackageIdentifier.siteCode).subscribe(respOne => {
				console.log('Response for Supplier Name : ' + respOne);
				this.pswPartDetailSave.supplierCode = respOne.simsahSelectedSiteCodeC;
				this.pswPartDetailSave.orgName = respOne.simsahSelectedSiteName;
				this.pswPartDetailSave.street = respOne.simsahStaSelectedSiteStreetName;
				this.pswPartDetailSave.city = respOne.simsahStaSelectedSiteCityName;
				this.pswPartDetailSave.state = respOne.simsahStaSelectedStateProvince;
				this.pswPartDetailSave.postalCode = respOne.simsahStaSelectedSitePost;
				this.pswPartDetailSave.country = respOne.simsahStaSelectedCountry;
				this.pswPartDetailSave.authTitle = 'Quality Manager';
				this.pswPartDetailSave.orgAuthSign = respOne.simsahProdQcMgr;
				this.pswPartDetailSave.authPrintName = respOne.simsahProdQcMgr;
				this.pswPartDetailSave.authSignDate = new Date();
				this.pswPartDetailSave.authEmail = respOne.simsahProdQcMgrEmail;
				this.pswPartDetailSave.authPhone = respOne.simsahProdQcMgrPhone;
			});
		});
	}

	getAllPartsDetails() {
		this.homePageService.getDataOfSelectedRow(this.ppapPackageIdentifierId).subscribe(value => {
			this.ppapPackageIdentifier = value.ppapPackageIdentifierEntity;
			this.supplierViewService.getCmmsData(this.noPartPrefix, this.noPartBase, this.noPartSuffix, this.programCode, this.ppapPackageIdentifier.siteCode).subscribe(
				resp => {
					//this.cmmsPartsPpapSave = resp;
					//for (let data of this.cmmsPartsPpapSave) {
					this.pswPartDetailSave.custPartName = resp.partDescription;
					this.pswPartDetailSave.custPartNumber = resp.noPartPrefix + '-' + resp.noPartBase + '-' + resp.noPartSuffix;
					this.pswPartDetailSave.engChangeLvl = resp.noLstNotice;
					this.pswPartDetailSave.engChangeLvlDate = resp.dtLstNotice;
					//}
					this.pswPartDetailSave.custDivision = 'Ford Motor Company';

				});
		});
	}

	/**
	 * If any input field has the error-input class
	 * show the warning message and
	 * return true
	 * Note : error-input will be injected only if the
	 * user input exceed the defined maximum length
	 */
	checkForErrors() {
		const errorInputs = document.querySelectorAll('.error-input');
		if (errorInputs.length > 0) {
			this.messageService.add({
				severity: 'warn',
				summary: 'warning',
				detail: 'You have exceeded the limit of characters.',
				life: 3000
			});
			return true;
		}
		return false;
	}

	onSave(save) {
		console.log('save', save);
		if (this.checkForErrors()) {
			return;
		}
		this.sectionList = [];
		if (!this.validateData()) {
			if (this.phaseType === 'Phase1' && (this.selectedPhaseValue === undefined || this.selectedPhaseValue === null)) {
				if (!this.sectionList.includes('ePSW INFORMATION: Phase1=Phase2')) {
					this.sectionList.push('ePSW INFORMATION: Phase1=Phase2');
				}
			}
			if ((this.pswPartDetailSave.custPartName === undefined || this.pswPartDetailSave.custPartName === null
					|| this.pswPartDetailSave.custPartName.length <= 0) ||
				(this.pswPartDetailSave.custPartNumber === undefined || this.pswPartDetailSave.custPartNumber === null
					|| this.pswPartDetailSave.custPartNumber.length <= 0) ||
				(this.pswPartDetailSave.engChangeLvl === undefined || this.pswPartDetailSave.engChangeLvl === null
					|| this.pswPartDetailSave.engChangeLvl.length <= 0) ||
				(this.pswPartDetailSave.engChangeLvlDate === undefined || this.pswPartDetailSave.engChangeLvlDate === null
					|| this.pswPartDetailSave.engChangeLvlDate === '') ||
				(this.pswPartDetailSave.drawingNumber === undefined || this.pswPartDetailSave.drawingNumber === null
					|| this.pswPartDetailSave.drawingNumber.length <= 0) ||
				(this.pswPartDetailSave.orgPartNumber === undefined || this.pswPartDetailSave.orgPartNumber === null
					|| this.pswPartDetailSave.orgPartNumber.length <= 0) ||
				(this.pswPartDetailSave.addEngChanges === undefined || this.pswPartDetailSave.addEngChanges === null
					|| this.pswPartDetailSave.addEngChanges.length <= 0) ||
				(this.pswPartDetailSave.addEngChangesDate === undefined || this.pswPartDetailSave.addEngChangesDate === null
					|| this.pswPartDetailSave.addEngChangesDate === '') ||
				(this.pswPartDetailSave.safetyRegulations === undefined || this.pswPartDetailSave.safetyRegulations === null
					|| this.pswPartDetailSave.safetyRegulations.length <= 0) ||
				(this.pswPartDetailSave.poNumber === undefined || this.pswPartDetailSave.poNumber === null
					|| this.pswPartDetailSave.poNumber.length <= 0) ||
				(this.pswPartDetailSave.weight === undefined || this.pswPartDetailSave.weight === null
					|| this.pswPartDetailSave.weight.length <= 0) ||
				(this.pswPartDetailSave.checkAidNumber === undefined || this.pswPartDetailSave.checkAidNumber === null
					|| this.pswPartDetailSave.checkAidNumber.length <= 0) ||
				(this.pswPartDetailSave.checkAidEngChangeLvl === undefined || this.pswPartDetailSave.checkAidEngChangeLvl === null
					|| this.pswPartDetailSave.checkAidEngChangeLvl.length <= 0) ||
				(this.pswPartDetailSave.checkAidEngChangeLvlDate === undefined || this.pswPartDetailSave.checkAidEngChangeLvlDate === null
					|| this.pswPartDetailSave.checkAidEngChangeLvlDate === '')) {
				if (!this.sectionList.includes('SECTION 1:PART INFORMATION')) {
					this.sectionList.push('SECTION 1:PART INFORMATION');
				}
			}
			if ((this.pswPartDetailSave.orgName === undefined || this.pswPartDetailSave.orgName === null
					|| this.pswPartDetailSave.orgName.length <= 0) ||
				(this.pswPartDetailSave.supplierCode === undefined || this.pswPartDetailSave.supplierCode === null
					|| this.pswPartDetailSave.supplierCode.length <= 0) ||
				(this.pswPartDetailSave.street === undefined || this.pswPartDetailSave.street === null
					|| this.pswPartDetailSave.street.length <= 0) ||
				(this.pswPartDetailSave.city === undefined || this.pswPartDetailSave.city === null
					|| this.pswPartDetailSave.city.length <= 0) ||
				(this.pswPartDetailSave.state === undefined || this.pswPartDetailSave.state === null
					|| this.pswPartDetailSave.state.length <= 0) ||
				(this.pswPartDetailSave.postalCode === undefined || this.pswPartDetailSave.postalCode === null
					|| this.pswPartDetailSave.postalCode.length <= 0) ||
				(this.pswPartDetailSave.country === undefined || this.pswPartDetailSave.country === null
					|| this.pswPartDetailSave.country.length <= 0)) {
				if (!this.sectionList.includes('SECTION 2:ORGANIZATION MANUFACTURING INFORMATION')) {
					this.sectionList.push('SECTION 2:ORGANIZATION MANUFACTURING INFORMATION');
				}
			}
			if ((this.pswPartDetailSave.custDivision === null || this.pswPartDetailSave.custDivision === undefined
					|| this.pswPartDetailSave.custDivision.length <= 0) ||
				(this.pswPartDetailSave.buyerCode === null || this.pswPartDetailSave.buyerCode === undefined
					|| this.pswPartDetailSave.buyerCode.length <= 0) ||
				(this.pswPartDetailSave.application === null || this.pswPartDetailSave.application === undefined
					|| this.pswPartDetailSave.application.length <= 0)) {
				if (!this.sectionList.includes('SECTION 3:CUSTOMER SUBMITTAL INFORMATION')) {
					this.sectionList.push('SECTION 3:CUSTOMER SUBMITTAL INFORMATION');
				}
			}
			if ((this.pswPartDetailSave.custConcernReport === undefined || this.pswPartDetailSave.custConcernReport === null
					|| this.pswPartDetailSave.custConcernReport.length <= 0) ||
				(this.pswPartDetailSave.imds === undefined || this.pswPartDetailSave.imds === null
					|| this.pswPartDetailSave.imds.length <= 0) ||
				(this.pswPartDetailSave.polymerPartsISO === undefined || this.pswPartDetailSave.polymerPartsISO === null
					|| this.pswPartDetailSave.polymerPartsISO.length <= 0)) {
				if (!this.sectionList.includes('SECTION 4:MATERIALS REPORTING')) {
					this.sectionList.push('SECTION 4:MATERIALS REPORTING');
				}
			}
			if ((this.pswPartDetailSave.submissionReason === undefined || this.pswPartDetailSave.submissionReason === null
				|| this.pswPartDetailSave.submissionReason.length <= 0 || this.pswPartDetailSave.submissionReason === '')) {
				if (!this.sectionList.includes('SECTION 5:REASON FOR SUBMISSION (Check at least one)')) {
					this.sectionList.push('SECTION 5:REASON FOR SUBMISSION (Check at least one)');
				}
			} else {
				let i;
				let j = 0;
				for (i = 0; i < this.pswPartDetailSave.submissionReason.length; i++) {
					if (this.pswPartDetailSave.submissionReason[i].length === 0) {
						j++;
					}
					if (this.pswPartDetailSave.submissionReason.length === j) {
						if (!this.sectionList.includes('SECTION 5:REASON FOR SUBMISSION (Check at least one)')) {
							this.sectionList.push('SECTION 5:REASON FOR SUBMISSION (Check at least one)');
						}
					}
				}
				if (this.isOtherTextVisible === true) {
					if (this.pswPartDetailSave.otherReason === undefined ||
						this.pswPartDetailSave.otherReason === null || this.pswPartDetailSave.otherReason.length <= 0) {
						if (!this.sectionList.includes('SECTION 5:REASON FOR SUBMISSION (Check at least one)')) {
							this.sectionList.push('SECTION 5:REASON FOR SUBMISSION (Check at least one)');
						}
					}
				}
			}
			if ((this.pswPartDetailSave.submitResultFor === undefined || this.pswPartDetailSave.submitResultFor === null
					|| this.pswPartDetailSave.submitResultFor.length <= 0) ||
				(this.pswPartDetailSave.meetDesignReq === undefined || this.pswPartDetailSave.meetDesignReq === null
					|| this.pswPartDetailSave.meetDesignReq.length <= 0) ||
				(this.pswPartDetailSave.moldCavityProdProc === undefined || this.pswPartDetailSave.moldCavityProdProc === null
					|| this.pswPartDetailSave.moldCavityProdProc.length <= 0)) {
				if (!this.sectionList.includes('SECTION 7:SUBMISSION RESULTS')) {
					this.sectionList.push('SECTION 7:SUBMISSION RESULTS');
				}
			} else {
				let i;
				let j = 0;
				for (i = 0; i < this.pswPartDetailSave.submitResultFor.length; i++) {
					if (this.pswPartDetailSave.submitResultFor[i].length === 0) {
						j++;
					}
					if (this.pswPartDetailSave.submitResultFor.length === j) {
						if (!this.sectionList.includes('SECTION 7:SUBMISSION RESULTS')) {
							this.sectionList.push('SECTION 7:SUBMISSION RESULTS');
						}
					}
				}
				if (this.isDesignExplnVisible === true) {
					if (this.pswPartDetailSave.designReqExpln === undefined || this.pswPartDetailSave.designReqExpln === null
						|| this.pswPartDetailSave.designReqExpln.length <= 0) {
						if (!this.sectionList.includes('SECTION 7:SUBMISSION RESULTS')) {
							this.sectionList.push('SECTION 7:SUBMISSION RESULTS');
						}
					}
				}
			}
			if ((this.pswPartDetailSave.paReqSource === undefined || this.pswPartDetailSave.paReqSource === null
					|| this.pswPartDetailSave.paReqSource === '') ||
				(this.pswPartDetailSave.paReqSrcDetail === undefined || this.pswPartDetailSave.paReqSrcDetail === null
					|| this.pswPartDetailSave.paReqSrcDetail.length <= 0) ||
				(this.pswPartDetailSave.paReqSrcDate === undefined || this.pswPartDetailSave.paReqSrcDate === null
					|| this.pswPartDetailSave.paReqSrcDate === '') ||
				(this.pswPartDetailSave.paReqAPW === undefined || this.pswPartDetailSave.paReqAPW === null
					|| this.pswPartDetailSave.paReqAPW === 0) ||
				(this.pswPartDetailSave.paReqMPW === undefined || this.pswPartDetailSave.paReqMPW === null
					|| this.pswPartDetailSave.paReqMPW === 0)) {
				if (!this.sectionList.includes('SECTION 8:CAPACITY REQUIREMENTS')) {
					this.sectionList.push('SECTION 8:CAPACITY REQUIREMENTS');
				}
			}
			if (this.pswPartDetailSave.paReqMPW !== null && this.pswPartDetailSave.paReqAPW !== null) {
				if (this.pswPartDetailSave.paReqAPW > this.pswPartDetailSave.paReqMPW) {
					if (!this.sectionList.includes('PA')) {
						this.sectionList.push('PA');
					}
				}
			}
			if (this.pswPartDetailSave.aftrPaReqMPW !== null && this.pswPartDetailSave.aftrPaReqAPW !== null) {
				if (this.pswPartDetailSave.aftrPaReqAPW > this.pswPartDetailSave.aftrPaReqMPW) {
					if (!this.sectionList.includes('Revised PA')) {
						this.sectionList.push('Revised PA');
					}
				}
			}
			if ((this.pswPartDetailSave.aftrPaReqSource !== undefined && this.pswPartDetailSave.aftrPaReqSource !== null
					&& this.pswPartDetailSave.aftrPaReqSource !== '') ||
				(this.pswPartDetailSave.aftrPaReqSrcDetail !== undefined && this.pswPartDetailSave.aftrPaReqSrcDetail !== null
					&& this.pswPartDetailSave.aftrPaReqSrcDetail.length > 0) ||
				(this.pswPartDetailSave.aftrPaReqSrcDate !== undefined && this.pswPartDetailSave.aftrPaReqSrcDate !== null &&
					this.pswPartDetailSave.aftrPaReqSrcDate.toString() !== '') ||
				(this.pswPartDetailSave.aftrPaReqAPW !== undefined && this.pswPartDetailSave.aftrPaReqAPW !== null
					&& this.pswPartDetailSave.aftrPaReqAPW !== 0) ||
				(this.pswPartDetailSave.aftrPaReqMPW !== undefined && this.pswPartDetailSave.aftrPaReqMPW !== null
					&& this.pswPartDetailSave.aftrPaReqMPW !== 0) ||
				(this.pswPartDetailSave.aftrPaReqMetDate !== undefined && this.pswPartDetailSave.aftrPaReqMetDate !== null &&
					this.pswPartDetailSave.aftrPaReqMetDate.toString() !== '')) {
				if ((this.pswPartDetailSave.aftrPaReqSource === undefined || this.pswPartDetailSave.aftrPaReqSource === null
						|| this.pswPartDetailSave.aftrPaReqSource === '') ||
					(this.pswPartDetailSave.aftrPaReqSrcDetail === undefined || this.pswPartDetailSave.aftrPaReqSrcDetail === null
						|| this.pswPartDetailSave.aftrPaReqSrcDetail.length <= 0) ||
					(this.pswPartDetailSave.aftrPaReqSrcDate === undefined || this.pswPartDetailSave.aftrPaReqSrcDate === null
						|| this.pswPartDetailSave.aftrPaReqSrcDate.toString() === '') ||
					(this.pswPartDetailSave.aftrPaReqAPW === undefined || this.pswPartDetailSave.aftrPaReqAPW === null
						|| this.pswPartDetailSave.aftrPaReqAPW === 0) ||
					(this.pswPartDetailSave.aftrPaReqMPW === undefined || this.pswPartDetailSave.aftrPaReqMPW === null
						|| this.pswPartDetailSave.aftrPaReqMPW === 0)) {
					if (!this.sectionList.includes('SECTION 8:Source of the revised requirements after (PA) & Revised requirements after (PA) APW/MPW: All fields must be filled or none should be filled.')) {
						this.sectionList.push('SECTION 8:Source of the revised requirements after (PA) & Revised requirements after (PA) APW/MPW: All fields must be filled or none should be filled.');
					}
				}
			}
			if ((this.pswPartDetailSave.demonCpctyAPPC === undefined || this.pswPartDetailSave.demonCpctyAPPC === null
					|| this.pswPartDetailSave.demonCpctyAPPC === 0) ||
				(this.pswPartDetailSave.demonCpctyMPPC === undefined || this.pswPartDetailSave.demonCpctyMPPC === null
					|| this.pswPartDetailSave.demonCpctyMPPC === 0) ||
				(this.pswPartDetailSave.demonCpctyDate === undefined || this.pswPartDetailSave.demonCpctyDate === null
					|| this.pswPartDetailSave.demonCpctyDate === '')) {
				if (!this.sectionList.includes('SECTION 9:DEMONSTRATED CAPACITY')) {
					this.sectionList.push('SECTION 9:DEMONSTRATED CAPACITY');
				}
			}
			if (this.supplierRemark !== undefined && this.supplierRemark !== null && this.supplierRemark !== '') {
				const regex = /^[0-9;]+$/;
				if (!regex.test(this.supplierRemark)) {
					if (!this.sectionList.includes('SECTION 9: eCar Number')) {
						this.sectionList.push('SECTION 9: eCar Number');
					}
				}
			}

			if(this.initialRemark && !this.supplierRemark){
				if (!this.sectionList.includes('SECTION 9: eCar Number null validation')) {
					this.sectionList.push('SECTION 9: eCar Number null validation');
				}
			}
			if (this.pswPartDetailSave.demonCpctyMPPC !== null && this.pswPartDetailSave.demonCpctyAPPC !== null) {
				if (this.pswPartDetailSave.demonCpctyAPPC > this.pswPartDetailSave.demonCpctyMPPC) {
					if (!this.sectionList.includes('SECTION 9: HIGH')) {
						this.sectionList.push('SECTION 9: HIGH');
					}
				}
			}
			if ((this.pswPartDetailSave.prodRate === undefined || this.pswPartDetailSave.prodRate === null
					|| this.pswPartDetailSave.prodRate.length <= 0) ||
				(this.pswPartDetailSave.prodHours === undefined || this.pswPartDetailSave.prodHours === null
					|| this.pswPartDetailSave.prodHours.length <= 0) ||
				(this.pswPartDetailSave.prodStreams === undefined || this.pswPartDetailSave.prodStreams === null
					|| this.pswPartDetailSave.prodStreams.length <= 0)) {
				if (!this.sectionList.includes('SECTION 10:DECLARATION')) {
					this.sectionList.push('SECTION 10:DECLARATION');
				}
			}
			if ((this.pswPartDetailSave.comments === undefined || this.pswPartDetailSave.comments === null
					|| this.pswPartDetailSave.comments.length <= 0) ||
				(this.pswPartDetailSave.orgAuthSign === undefined || this.pswPartDetailSave.orgAuthSign === null
					|| this.pswPartDetailSave.orgAuthSign.length <= 0) ||
				(this.pswPartDetailSave.authPrintName === undefined || this.pswPartDetailSave.authPrintName === null
					|| this.pswPartDetailSave.authPrintName.length <= 0) ||
				(this.pswPartDetailSave.authSignDate === undefined || this.pswPartDetailSave.authSignDate === null) ||
				(this.pswPartDetailSave.authTitle === undefined || this.pswPartDetailSave.authTitle === null
					|| this.pswPartDetailSave.authTitle.length <= 0) ||
				(this.pswPartDetailSave.authPhone === undefined || this.pswPartDetailSave.authPhone === null
					|| this.pswPartDetailSave.authPhone.length <= 0) ||
				(this.pswPartDetailSave.authEmail === undefined || this.pswPartDetailSave.authEmail === null
					|| this.pswPartDetailSave.authEmail.length <= 0) ||
				(this.pswPartDetailSave.custToolTagNumber === undefined || this.pswPartDetailSave.custToolTagNumber === null
					|| this.pswPartDetailSave.custToolTagNumber.length <= 0)) {
				if (!this.sectionList.includes('SECTION 11:EXPLANATION/COMMENTS')) {
					this.sectionList.push('SECTION 11:EXPLANATION/COMMENTS');
				}
			}
			if (this.isShownMandatory === true) {
				if ((this.pswPartDetailSave.engAuthAlert === undefined || this.pswPartDetailSave.engAuthAlert === null
						|| this.pswPartDetailSave.engAuthAlert.length <= 0) ||
					(this.pswPartDetailSave.engAuthDesc === undefined || this.pswPartDetailSave.engAuthDesc === null
						|| this.pswPartDetailSave.engAuthDesc.length <= 0)) {
					// ||
					// (this.pswPartDetailSave.engAuthIncomplPpapReq === undefined || this.pswPartDetailSave.engAuthIncomplPpapReq === null
					// 	|| this.pswPartDetailSave.engAuthIncomplPpapReq.length <= 0)) {
					if (!this.sectionList.includes('SECTION 12:INTERIM STATUS')) {
						this.sectionList.push('SECTION 12:INTERIM STATUS');
					}
				}
			}
			if (this.actionMode === 'familyNew') {
				console.log('PPAP_PARTS_Response_1: ', this.ppapPartsArray, '\nSIZE_1: ', this.ppapPartsArray.length);
				if (this.ppapPartsArray.length === 0) {
					if (!this.sectionList.includes('FAMILY PSW ANNEXURE')) {
						this.sectionList.push('FAMILY PSW ANNEXURE');
					}
				} else {
					for (const item of this.ppapPartsArray) {
						if ((item.familyPswOrgPartNumber === null || item.familyPswOrgPartNumber === undefined || item.familyPswOrgPartNumber.length <= 0
								|| item.familyPswOrgPartNumber === '')
							|| (item.familyPswPoNumber === null || item.familyPswPoNumber === undefined || item.familyPswPoNumber.length <= 0
								|| item.familyPswPoNumber === '')
							|| (item.familyPswWeight === null || item.familyPswWeight === undefined || item.familyPswWeight.length <= 0
								|| item.familyPswWeight === '')
							|| (item.familyPswIMDSIDNo === null || item.familyPswIMDSIDNo === undefined || item.familyPswIMDSIDNo.length <= 0
								|| item.familyPswIMDSIDNo === '')) {
							if (!this.sectionList.includes('FAMILY PSW ANNEXURE')) {
								this.sectionList.push('FAMILY PSW ANNEXURE');
							}
						}
					}
				}
			}
			// if (this.phaseType === 'Phase3') {
			// 	if ((this.pswPartDetailSave.nextAuditPlan === undefined || this.pswPartDetailSave.nextAuditPlan === null
			// 		|| this.pswPartDetailSave.nextAuditPlan.name <= 0)) {
			// 		if (!this.sectionList.includes('AUDIT LAYOUT PLANNED')) {
			// 			this.sectionList.push('AUDIT LAYOUT PLANNED');
			// 		}
			// 	}
			// }
			if (this.sectionList.length > 0 && (this.sectionList.includes('PA') ||
				this.sectionList.includes('Revised PA') || this.sectionList.includes('SECTION 9: HIGH') ||
				this.sectionList.includes('SECTION 9: eCar Number') || this.sectionList.includes('SECTION 9: eCar Number null validation'))) {
				if (this.sectionList.includes('PA')) {
					this.messageService.add({
						severity: 'error',
						summary: 'SECTION 8: ',
						detail: 'Program Approval (PA) Requirements: MPW should be >= APW',
						life: 5000
					});
				}
				if (this.sectionList.includes('Revised PA')) {
					this.messageService.add({
						severity: 'error',
						summary: 'SECTION 8: ',
						detail: 'Revised requirements after (PA) : MPW should be >= APW',
						life: 5000
					});
				}
				if (this.sectionList.includes('SECTION 9: HIGH')) {
					this.messageService.add({
						severity: 'error',
						summary: 'SECTION 9: ',
						detail: 'DEMONSTRATED CAPACITY: MPPC should be >= APPC',
						life: 5000
					});
				}
					const regex = /^[0-9;]+$/; // Only numbers and semicolon allowed
					if (!regex.test(this.supplierRemark)) {
						if (this.sectionList.includes('SECTION 9: eCar Number')) {
							this.messageService.add({
								severity: 'error',
								summary: 'SECTION 9: ',
								detail: 'eCarNumber: Only numbers and semicolon (;) are allowed.',
								life: 5000
							});
						} else {
							this.sectionList.push('SECTION 9: eCar Number');
						}
					}
							if (this.sectionList.includes('SECTION 9: eCar Number null validation')) {
								this.messageService.add({
									severity: 'error',
									summary: 'SECTION 9: ',
									detail: 'eCarNumber cannot be empty.',
									life: 5000
								});
							} else {
								this.sectionList.push('SECTION 9: eCar Number null validation');
							}
			} else if (this.sectionList.length === 0 && ((this.pswPartDetailSave.aftrPaReqSource !== undefined && this.pswPartDetailSave.aftrPaReqSource !== null
					&& this.pswPartDetailSave.aftrPaReqSource !== '') ||
				(this.pswPartDetailSave.aftrPaReqSrcDetail !== undefined && this.pswPartDetailSave.aftrPaReqSrcDetail !== null
					&& this.pswPartDetailSave.aftrPaReqSrcDetail.length > 0) ||
				(this.pswPartDetailSave.aftrPaReqSrcDate !== undefined && this.pswPartDetailSave.aftrPaReqSrcDate !== null &&
					this.pswPartDetailSave.aftrPaReqSrcDate.toString() !== '') ||
				(this.pswPartDetailSave.aftrPaReqAPW !== undefined && this.pswPartDetailSave.aftrPaReqAPW !== null
					&& this.pswPartDetailSave.aftrPaReqAPW !== 0) ||
				(this.pswPartDetailSave.aftrPaReqMPW !== undefined && this.pswPartDetailSave.aftrPaReqMPW !== null
					&& this.pswPartDetailSave.aftrPaReqMPW !== 0) ||
				(this.pswPartDetailSave.aftrPaReqMetDate !== undefined && this.pswPartDetailSave.aftrPaReqMetDate !== null &&
					this.pswPartDetailSave.aftrPaReqMetDate.toString() !== ''))) {
				this.savePswFormData('Complete', save);
			} else if (this.sectionList.length === 0 && this.isShownMandatory === true &&
				this.pswPartDetailSave.engAuthAlert.length > 0 && this.pswPartDetailSave.engAuthDesc.length > 0) {
				this.savePswFormData('Complete', save);
			} else {
				const msg = 'One or more mandatory fields is blank in the following Sections.\n' +
					'\n' +
					this.sectionList.toString().split(',').join('\r\n') +
					'\n' +
					'\nData must be entered in order to submit the psw form.\n' +
					'Are you sure you want to ' +
					'proceed with saving the current data?\n';
				this.confirmationService.confirm({
					message: msg,
					header: 'Confirmation',
					icon: 'pi pi-info-circle',
					accept: () => {
						this.savePswFormData('InComplete', save);
					},
					reject: () => {
					}
				});
			}
		} else {
			this.savePswFormData('Complete', save);
		}
	}

	private validateData() {
		if (this.phaseType === 'Phase0' || this.phaseType === 'Phase1'
			|| this.phaseType === 'Phase2') {
			this.validatePhaseData();
		}
		if (this.phaseType === 'Phase3') {
			this.validatePhase3Data();
		}
		return this.isValidSave;
	}

	showConfirmationDialog($event: MouseEvent) {
		if (this.isViewMode) {
			this.confirmationService.confirm({
				message: 'Are you sure you want to cancel?',
				header: 'Confirmation',
				icon: 'pi pi-info-circle',
				accept: () => {
					this.msgs = [];
					this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
					// this.isVisible = true;
					// this.isEpswInformation = true;
					// this.isCopyVisible = true;
					//this.router.navigate(['/home-page']);
				},
				reject: () => {
				}
			});
		} else {
			this.isShown = true;
			//this.isVisible = false;
		}
	}

	getAllPartsLengthDetails() {
		console.log('########################## getAllPartsLengthDetails ##################### : ' + this.mode);
		this.digitalService.getAllData(this.ppapPackageIdentifierId).subscribe(resp => {
			this.partNumberDetail = resp.listOfPpapPackageParts;
			if (this.mode !== 'create') {
				for (const partDetail of this.partNumberDetail) {
					if (this.ppapPackagePartsEntityId === partDetail.ppapPackagePartsEntityID) {
						this.ppappackagePartsEntityId = partDetail.ppapPackagePartsEntityID;
						this.ppapStaComments = partDetail.ppapStaComments;
						this.ppapPdComments = partDetail.ppapPdComments;
						this.ppapStaStatus = partDetail.ppapStaStatus;
						this.ppapStaStatusBy = partDetail.ppapStaStatusBy;
						this.ppapStaStatusOn = this.datePipe.transform(partDetail.ppapStaStatusOn, 'yyyy-MM-dd');
						this.ppapPdStatus = partDetail.ppapPdStatus;
						this.ppapPdStatusBy = partDetail.ppapPdStatusBy;
						this.ppapPdStatusOn = this.datePipe.transform(partDetail.ppapPdStatusOn, 'yyyy-MM-dd');
						this.pswStaComments = partDetail.pswStaComments;
						this.pswPdComments = partDetail.pswPdComments;
						this.pswStaStatus = partDetail.pswStaStatus;
						this.pswStaStatusBy = partDetail.pswStaStatusBy;
						this.pswStaUserId = partDetail.pswStaUserId;
						this.pswStaStatusOn = this.datePipe.transform(partDetail.pswStaStatusOn, 'yyyy-MM-dd');
						this.pswPdStatus = partDetail.pswPdStatus;
						this.pswPdStatusBy = partDetail.pswPdStatusBy;
						this.pswPdUserId = partDetail.pswPdUserId;
						this.pswPdStatusOn = this.datePipe.transform(partDetail.pswPdStatusOn, 'yyyy-MM-dd');
						this.overallStatus = partDetail.status;
						this.pswOverallStatus = partDetail.overallPswStatus;
						this.ppapOverallStatus = partDetail.overallPpapStatus;
						this.submittedBy = partDetail.submittedBy;
						this.submittedOn = this.datePipe.transform(partDetail.submittedOn, 'yyyy-MM-dd');
					}
				}
				//Added for print
				if (this.pswPdUserId !== undefined && this.pswPdUserId !== null && this.pswPdUserId !== '') {
					if (['PENDING', 'IN PROGRESS', 'REJECTED', 'NEEDS CLARIFICATION', 'INTERIM ACCEPTED', 'AWAITING APPROVAL'].indexOf(this.overallStatus) === -1) {
						this.approverCdsidPdMail = this.ppapPackageIdentifier.pdApproverEmailId;
						this.approverCdsidPdName = this.ppapPackageIdentifier.pdApproverName;
					} else {
						this.digitalPpapPackageService.searchFordBuyer(this.pswPdUserId).subscribe(ldapResponse => {
							console.log('pswPdUserId');
							this.ldapResponse = ldapResponse;
							for (const data of this.ldapResponse) {
								console.log('data.userId  : ' + data.userId);
								console.log('this.pswPdUserId : ' + this.pswPdUserId);
								if (data.userId === this.pswPdUserId.toLowerCase()) {
									this.approverCdsidPdMail = data.mail;
									this.approverCdsidPdName = data.fordDisplayName;
								}
							}
						});
					}
				}
				if (this.pswStaUserId !== undefined && this.pswStaUserId !== null && this.pswStaUserId !== '') {
					if (['PENDING', 'IN PROGRESS', 'REJECTED', 'NEEDS CLARIFICATION', 'INTERIM ACCEPTED', 'AWAITING APPROVAL'].indexOf(this.overallStatus) === -1) {
						this.approverCdsidStaMail = this.ppapPackageIdentifier.staApproverEmailId
						this.approverCdsidStaName = this.ppapPackageIdentifier.staApproverName
					} else {
						this.digitalPpapPackageService.searchFordBuyer(this.pswStaUserId).subscribe(ldapResponse => {
							console.log('pswStaUserId');
							this.ldapResponse = ldapResponse;
							for (const data of this.ldapResponse) {
								console.log('data.userId  : ' + data.userId);
								console.log('this.pswStaUserId : ' + this.pswStaUserId);
								if (data.userId === this.pswStaUserId.toLowerCase()) {
									this.approverCdsidStaMail = data.mail;
									this.approverCdsidStaName = data.fordDisplayName;
								}
							}
						});
					}
				}
				//Added for print
			}
			// editable - If the Package is rejected/needs clarification
			if (this.pswAccessControl.get('supplierAccess')) {
				if (this.overallStatus === 'REJECTED' || this.overallStatus === 'NEEDS CLARIFICATION' || this.overallStatus === 'INTERIM ACCEPTED') {
					this.enableOnViewMode = true;
				}
			}
			// Approver editable mode
			if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
				this.supplierViewService.getStaPdStatusFromAssessmentTable(this.ppapPackageIdentifierId).subscribe(respOne => {
					this.staPdStatusList = respOne;
					for (const status of this.staPdStatusList) {
						if (status === 'REJECTED') {
							this.staPdStatus = 'REJECTED';
							break;
						} else if (status === 'NEEDS CLARIFICATION') {
							this.staPdStatus = 'NEEDS CLARIFICATION';
						} else {
							if (this.staPdStatus !== 'NEEDS CLARIFICATION') {
								if (this.ppapLevel === '1') {
									this.staPdStatus = 'LEVEL 1 SELF APPROVED';
								} else {
									this.staPdStatus = 'AWAITING APPROVAL';
								}
							}
						}
					}
					//Enable&Disable Reject and Needs Clarification
					if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
						// if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
						if (this.staPdStatus === 'REJECTED') {
							this.enableApproveroptions = false;
							this.enableRejectButton = true;
							this.enableNeedClarificationButton = false;
						} else if (this.staPdStatus === 'NEEDS CLARIFICATION') {
							this.enableApproveroptions = false;
							this.enableRejectButton = true;
							this.enableNeedClarificationButton = true;
						} else {
							this.enableApproveroptions = true;
							this.enableRejectButton = false;
							this.enableNeedClarificationButton = false;
						}
						// }
						/*else {
							if (this.ppapPdStatus === 'REJECTED') {
								this.enableApproveroptions = false;
								this.enableRejectButton = true;
								this.enableNeedClarificationButton = false;
							} else if (this.ppapPdStatus === 'NEEDS CLARIFICATION') {
								this.enableApproveroptions = false;
								this.enableRejectButton = true;
								this.enableNeedClarificationButton = true;
							} else {
								this.enableApproveroptions = true;
								this.enableRejectButton = false;
								this.enableNeedClarificationButton = false;
							}
						}*/
						if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
							if (this.overallStatus === 'AWAITING APPROVAL' || this.overallStatus === 'LEVEL 1 SELF APPROVED') {
								if (this.pswStaStatus === null || this.pswStaStatus.length === 0) {
									this.disableApproverActions = false;
								} else {
									if (this.pswStaStatus === 'APPROVED') {
										this.disableApproverActions = true;
									} else {
										this.disableApproverActions = false;
									}
								}
							} else {
								if (this.pswStaStatus === null || this.pswStaStatus.length === 0) {
									if (this.overallStatus !== 'REJECTED' &&
										this.overallStatus !== 'NEEDS CLARIFICATION' &&
										this.overallStatus !== 'INTERIM ACCEPTED' && this.overallStatus !== 'IN PROGRESS') {
										this.disableApproverActions = false;
									} else {
										this.disableApproverActions = true;
									}
								} else {
									this.disableApproverActions = true;
								}
							}
						} else {
							if (this.overallStatus === 'AWAITING APPROVAL' || this.overallStatus === 'LEVEL 1 SELF APPROVED') {
								if (this.pswPdStatus === null || this.pswPdStatus.length === 0) {
									this.disableApproverActions = false;
								} else {
									if (this.pswPdStatus === 'APPROVED') {
										this.disableApproverActions = true;
									} else {
										this.disableApproverActions = false;
									}
								}
							} else {
								if (this.pswPdStatus === null || this.pswPdStatus.length === 0) {
									if (this.overallStatus !== 'REJECTED' &&
										this.overallStatus !== 'NEEDS CLARIFICATION' &&
										this.overallStatus !== 'INTERIM ACCEPTED' && this.overallStatus !== 'IN PROGRESS') {
										this.disableApproverActions = false;
									} else {
										this.disableApproverActions = true;
									}
								} else {
									this.disableApproverActions = true;
								}
							}
						}
					}
				});
			}

			// if (this.ppapOverallStatus === 'REJECTED') {
			// 	if (this.overallStatus === '' || this.overallStatus === null || this.overallStatus === 'AWAITING APPROVAL') {
			// 		this.enableApproveroptions = false;
			// 		this.enableRejectButton = true;
			// 		this.enableNeedClarificationButton = false;
			// 	} else {
			// 		this.enableApproveroptions = true;
			// 		this.enableRejectButton = false;
			// 		this.enableNeedClarificationButton = false;
			// 	}
			// } else if (this.ppapOverallStatus === 'NEEDS CLARIFICATION') {
			// 	if (this.overallStatus === '' || this.overallStatus === null || this.overallStatus === 'AWAITING APPROVAL') {
			// 		this.enableApproveroptions = false;
			// 		this.enableRejectButton = true;
			// 		this.enableNeedClarificationButton = true;
			// 	} else {
			// 		this.enableApproveroptions = true;
			// 		this.enableRejectButton = false;
			// 		this.enableNeedClarificationButton = false;
			// 	}
			// } else {
			// 	this.enableApproveroptions = true;
			// 	this.enableRejectButton = false;
			// 	this.enableNeedClarificationButton = false;
			// }
			// }

			//Enable STA/PD Comments
			if (this.overallStatus === 'REJECTED' ||
				this.overallStatus === 'NEEDS CLARIFICATION' || this.overallStatus === 'INTERIM ACCEPTED' || this.overallStatus === 'APPROVED') {
				this.commentsEnableCondition = true;
			} else { // if(this.overallStatus === 'AWAITING APPROVAL')
				if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
					this.commentsEnableCondition = true;
				} else {
					this.commentsEnableCondition = false;
				}
			} /*else {
				this.commentsEnableCondition = false;
			}*/
			//Added for US3853075
			// if (this.familyPswId === 0) {
			// 	this.checkIfPresent();
			// } else {
			// 	this.getNonFamilyParts()
			// }
		});
	}

	getAllIdentifierDetails() {
		this.homePageService.getDataOfSelectedRow(this.ppapPackageIdentifierId).subscribe(resp => {
			this.ppapPackageIdentifier = resp.ppapPackageIdentifierEntity;
			this.homePageService.getSupplierName(this.ppapPackageIdentifier.siteCode).subscribe(respOne => {
				console.log('Response for Supplier Name : ' + respOne);
				this.supplierName = respOne.simsahSelectedSiteName;
			});
			if (this.phaseType === 'Phase3') {
				this.isAuditLayoutVisible = false;
			} else {
				this.isAuditLayoutVisible = false;
			}
			if (this.phaseType === 'Phase1') {
				this.isPhaseCheckboxVisible = true;
			} else {
				this.isPhaseCheckboxVisible = false;
			}
		});
		this.getInterimDisplay();
	}

	savePswFormData(status: string, save: boolean) {
		this.pswPartDetailSave.ppapPackageIdentifierID = this.ppapPackageIdentifierId;
		this.pswPartDetailSave.programCode = this.programCode;
		this.saveInterimPpapStatusCheckboxValues();
		this.saveSubmissionReasonCheckboxValues();
		this.saveSubmissionResultCheckboxValues();
		this.saveRequestLvlDropdown();
		this.savePaReqSourceDropdown();
		this.saveAftrPaReqSourceDropdown();
		//this.saveAnnualDropdown();
		this.savePhaseCheckboxValues();
		if (status === 'Complete') {
			this.pswPartDetailSave.isFormCompleted = 'true';
		} else {
			this.pswPartDetailSave.isFormCompleted = 'false';
		}
		//Added for US3853075
		if (this.actionMode === 'familyNew') {
			if (save) {
				this.supplierViewService.saveAllData(this.pswPartDetailSave, this.entityIdList, 'UPDATE').subscribe(resp => {
					console.log('Save Data: ' + resp);
					this.pswPartDetailSaveAll = resp;
					this.savePpapRecords()
					console.log(this.pswPartDetailSaveAll[0].authSignDate);
					sessionStorage.setItem('saveMessage', JSON.stringify({
						status: 'success',
						message: 'Saved Successfully'
					}));
					// this.messageService.add({severity: 'success', summary: 'Saved Successfully', life: 3000});
					this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
				});
			} else {
				const messageExists = false;
				// this.imageNotUploaded = false;
				this.cdsidNotSaved = false;
				this.tier2Saved = false;
				console.log('Submitting Digital Ppap for Phase 0');
				if (this.ppapLevel !== '1') {
					this.digitalPpapPackageService.checkIfTier2PPAPPackageSaved(this.ppapPackageIdentifierId).subscribe(tier2Response => {
						if (tier2Response === 0) {
							this.tier2Saved = true;
						}
					});
				}
				this.digitalPpapPackageService.checkIfPdStaCdsidSaved(this.ppapPackageIdentifierId).subscribe(response => {
					// if (commodityResponse['PpapPackageCommodityImage'].length === 0) {
					// 	this.imageNotUploaded = true;
					// 	// this.messageService.add({severity: 'error', summary: 'Please Upload Commodity Image in PPAP Package', life: 5000});
					// }
					const commodityResponse = response.mapOfStringList;
					if (commodityResponse['PpapPackageIdentifierEntity'].pdApprovalRequired === '1') {
						if (commodityResponse['PpapPackageIdentifierEntity'].length === 0 || commodityResponse['PpapPackageIdentifierEntity'].length === 1) {
							this.cdsidNotSaved = true;
							// this.messageService.add({severity: 'error', summary: 'Please Provide CDSID for PD and STA in PPAP Package', life: 5000});
						}
					} else {
						if (commodityResponse['PpapPackageIdentifierEntity'].length === 0) {
							this.cdsidNotSaved = true;
							// this.messageService.add({severity: 'error', summary: 'Please Provide CDSID for STA in PPAP Package', life: 5000});
						}
					}
					console.log('PPAP Level  ' + this.ppapLevel);
					if (this.ppapLevel !== '1') {
						this.checkPpapRequiredElement(messageExists);
					} else if (!messageExists) {
						this.savePartsStatus(messageExists);
					}
				});
			}
		} else {
			this.supplierViewService.saveData(this.pswPartDetailSave, this.ppapPackagePartsEntityId).subscribe(resp => {
				console.log('Save Data: ' + resp);
				this.savePpapRecords();
				if (resp.reqSubmitLvl !== null && resp.reqSubmitLvl !== undefined) {
					this.pswPartDetailSave.reqSubmitLvl = {name: resp.reqSubmitLvl, code: null};
				} else {
					this.pswPartDetailSave.reqSubmitLvl = null;
				}
				if (resp.paReqSource !== null && resp.paReqSource !== undefined) {
					// this.pswPartDetailSave.paReqSource = {name: resp.paReqSource, code: null};
					this.pswPartDetailSave.paReqSource = resp.paReqSource;
				} else {
					this.pswPartDetailSave.paReqSource = null;
				}
				if (resp.submissionReason !== null && resp.submissionReason !== undefined) {
					const submissionReasonResult = resp.submissionReason.split('#');
					this.pswPartDetailSave.submissionReason = submissionReasonResult;
				}
				if (resp.submitResultFor !== null && resp.submitResultFor !== undefined) {
					const submitResult = resp.submitResultFor.split('#');
					this.pswPartDetailSave.submitResultFor = submitResult;
				}
				// this.pswPartDetailSave = resp;
				if (save) {
					sessionStorage.setItem('saveMessage', JSON.stringify({
						status: 'success',
						message: 'Saved Successfully'
					}));
					// this.messageService.add({severity: 'success', summary: 'Saved Successfully', life: 3000});
					this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
				} else {
					const messageExists = false;
					// this.imageNotUploaded = false;
					this.cdsidNotSaved = false;
					this.tier2Saved = false;
					console.log('Submitting Digital Ppap for Phase 0');
					if (this.ppapLevel !== '1') {
						this.digitalPpapPackageService.checkIfTier2PPAPPackageSaved(this.ppapPackageIdentifierId).subscribe(tier2Response => {
							if (tier2Response === 0) {
								this.tier2Saved = true;
							}
						});
					}
					this.digitalPpapPackageService.checkIfPdStaCdsidSaved(this.ppapPackageIdentifierId).subscribe(response => {
						// if (commodityResponse['PpapPackageCommodityImage'].length === 0) {
						// 	this.imageNotUploaded = true;
						// 	// this.messageService.add({severity: 'error', summary: 'Please Upload Commodity Image in PPAP Package', life: 5000});
						// }
						const commodityResponse = response.mapOfStringList;
						if (commodityResponse['PpapPackageIdentifierEntity'].pdApprovalRequired === 'true') {
							if (commodityResponse['PpapPackageIdentifierEntity'].length === 0 || commodityResponse['PpapPackageIdentifierEntity'].length === 1) {
								this.cdsidNotSaved = true;
								// this.messageService.add({severity: 'error', summary: 'Please Provide CDSID for PD and STA in PPAP Package', life: 5000});
							}
						} else {
							if (commodityResponse['PpapPackageIdentifierEntity'].length === 0) {
								this.cdsidNotSaved = true;
								// this.messageService.add({severity: 'error', summary: 'Please Provide CDSID for STA in PPAP Package', life: 5000});
							}
						}
						console.log('PPAP Level  ' + this.ppapLevel);
						if (this.ppapLevel !== '1') {
							this.checkPpapRequiredElement(messageExists);
						} else if (!messageExists) {
							this.savePartsStatus(messageExists);
						}
					});
				}
			});
		}
		//this.messageService.add({severity:'success', summary: ' Saved Successfully',life: 5000});
	}

	/**
	 * send value true to show loader and false to hide loader
	 * Detect changes used to fix the angular glitch and reset the view
	 * Glitch - Sometimes in few browsers input box will be not clickable after coming back
	 * from popup
	 * @param value - boolean
	 */
	showLoader(value: boolean) {
		this.isLoading = value;
		this.changeDetection.detectChanges();
	}

	selectCheckBox(submissionReason: string) {
		if (submissionReason !== null && submissionReason !== undefined) {
			let submisisonResultvalue = '';
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < submissionReason.length; i++) {
				submisisonResultvalue = submisisonResultvalue + this.pswPartDetailSave.submissionReason[i] + '#'
			}
			if (this.pswPartDetailSave.submissionReason.includes('Other - please specify')) {
				this.isOtherTextVisible = true;
			} else {
				this.isOtherTextVisible = false;
				if (this.isOtherTextVisible === false) {
					this.pswPartDetailSave.otherReason = '';
				}
			}
			this.selectedCheckBox = submisisonResultvalue;
		}
	}

	selectSubmissionResultCheckBox(submissionResult: string) {
		if (submissionResult !== null &&
			submissionResult !== undefined) {
			let submitResultvalue = '';
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < submissionResult.length; i++) {
				submitResultvalue = submitResultvalue + this.pswPartDetailSave.submitResultFor[i] + '#'
			}
			this.selectedSubmissionResultCheckBox = submitResultvalue;
		}
	}

	private saveSubmissionReasonCheckboxValues() {
		if (this.selectedCheckBox !== null && this.selectedCheckBox !== undefined) {
			this.pswPartDetailSave.submissionReason = this.selectedCheckBox;
		} else if (this.pswPartDetailSave.submissionReason !== undefined &&
			this.pswPartDetailSave.submissionReason.length > 1) {
			let submisisonResultvalue = '';
			//tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < this.pswPartDetailSave.submissionReason.length; i++) {
				submisisonResultvalue = submisisonResultvalue + this.pswPartDetailSave.submissionReason[i] + '#'
			}
			this.pswPartDetailSave.submissionReason = submisisonResultvalue;
		} else {
			const submisisonResultvalue = '';
			this.pswPartDetailSave.submissionReason = submisisonResultvalue;
		}
	}

	private saveSubmissionResultCheckboxValues() {
		if (this.selectedSubmissionResultCheckBox !== null && this.selectedSubmissionResultCheckBox !== undefined) {
			this.pswPartDetailSave.submitResultFor = this.selectedSubmissionResultCheckBox;
		} else if (this.pswPartDetailSave.submitResultFor !== undefined &&
			this.pswPartDetailSave.submitResultFor.length > 1) {
			let submitResultvalue = '';
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < this.pswPartDetailSave.submitResultFor.length; i++) {
				submitResultvalue = submitResultvalue + this.pswPartDetailSave.submitResultFor[i] + '#'
			}
			this.pswPartDetailSave.submitResultFor = submitResultvalue;
		} else {
			const submitResultvalue = '';
			this.pswPartDetailSave.submitResultFor = submitResultvalue;
		}
	}

	onSelectDropdown() {
		console.log('Inside select dropdown ' + this.ppapLevel);
		console.log('Inside select dropdown ' + this.ppapPackageIdentifierFromSupplier);
		if(this.ppapPackageIdentifierFromSupplier === undefined){
			console.log('Calling retriveipackage *** ');
			this.retriveipackage();
		} else if(this.ppapLevel === undefined){
			console.log('Calling getSelectedPartDataSupplierView *** ');
			this.getSelectedPartDataSupplierView();
		} else if (this.ppapLevel === '1' || this.ppapLevel === '3' || this.ppapLevel === '5') {
			console.log('Normal execution *** ');
			if (this.ppapLevel === '1' && this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '0') {
				this.pswPartDetailSave.reqSubmitLvl = {name: this.submissionLevelOption[0].name, code: null};
			}
			if (this.ppapLevel === '3' && this.ppapPackageIdentifierFromSupplier?.pdApprovalRequired === '0' ) {
				this.pswPartDetailSave.reqSubmitLvl = {name: this.submissionLevelOption[2].name, code: null};
			}
			if (this.ppapLevel === '5' && this.ppapPackageIdentifierFromSupplier?.pdApprovalRequired === '0' ) {
				this.pswPartDetailSave.reqSubmitLvl = {name: this.submissionLevelOption[4].name, code: null};
			}
			if (this.ppapPackageIdentifierFromSupplier?.pdApprovalRequired === '1') {
				this.pswPartDetailSave.reqSubmitLvl = {name: this.submissionLevelOption[4].name, code: null};
			}
		} else {
			//Added for US3853075
			if (Number(this.familyPswId) !== 0) {
				if (this.entityIdList !== null) {
					this.supplierViewService.getAllPartListData(this.entityIdList).subscribe(resp => {
						this.ppapPartsArray = resp;
						console.log('PPAP_PARTS_Response_2: ',this.ppapPartsArray,'\nSIZE_2: ',this.ppapPartsArray.length);
						for (const data of this.ppapPartsArray) {
							if (data.ppapLevel === '1') {
								this.pswPartDetailSave.reqSubmitLvl = {
									name: this.submissionLevelOption[0].name,
									code: null
								};
							}
							if (data.ppapLevel === '3') {
								this.pswPartDetailSave.reqSubmitLvl = {
									name: this.submissionLevelOption[2].name,
									code: null
								};
							}
							if (data.ppapLevel === '5') {
								this.pswPartDetailSave.reqSubmitLvl = {
									name: this.submissionLevelOption[4].name,
									code: null
								};
							}
						}
					});
				}
			}
		}
	}

	saveRequestLvlDropdown() {
		if (this.pswPartDetailSave.reqSubmitLvl !== null && this.pswPartDetailSave.reqSubmitLvl !== undefined) {
			if (this.pswPartDetailSave.reqSubmitLvl.name !== null && this.pswPartDetailSave.reqSubmitLvl.name !== undefined) {
				this.pswPartDetailSave.reqSubmitLvl = this.pswPartDetailSave.reqSubmitLvl.name;
			} else {
				this.pswPartDetailSave.reqSubmitLvl = null;
			}
		} else {
			this.pswPartDetailSave.reqSubmitLvl = null;
		}
	}

	/*onSelectAnnualDropdown(nextAuditPlan: any) {
		if (nextAuditPlan !== null && nextAuditPlan !== undefined ) {
			this.selectedAnnualDropdown = this.pswPartDetailSave.nextAuditPlan.name;
		}
	}*/

	// onSelectAnnualDropdown(nextAuditPlan: any) {
	// 	if (nextAuditPlan !== null && nextAuditPlan !== undefined) {
	// 		this.selectedAnnualDropdown = this.pswPartDetailSave.nextAuditPlan.name;
	// 	}
	// }

	private saveAnnualDropdown() {
		/*if (this.selectedAnnualDropdown !== null && this.selectedAnnualDropdown !== undefined) {
			this.pswPartDetailSave.nextAuditPlan = this.selectedAnnualDropdown;
		} else*/
		if (this.pswPartDetailSave.nextAuditPlan !== null && this.pswPartDetailSave.nextAuditPlan !== undefined) {
			if (this.pswPartDetailSave.nextAuditPlan.name !== null && this.pswPartDetailSave.nextAuditPlan.name !== undefined) {
				this.pswPartDetailSave.nextAuditPlan = this.pswPartDetailSave.nextAuditPlan.name;
			} else {
				this.pswPartDetailSave.nextAuditPlan = null;
			}
		} else {
			this.pswPartDetailSave.nextAuditPlan = null;
		}
		// else if (this.pswPartDetailSave.nextAuditPlan !== undefined) {
		// 	this.pswPartDetailSave.nextAuditPlan = this.pswPartDetailSave.nextAuditPlan.name;
		// }
		// else {
		// 	this.pswPartDetailSave.nextAuditPlan = this.auditLayout[0].name;
		// }
	}

	getGcpMcpvData() {
		this.displayDialog = true;
		this.homePageService.getDataOfSelectedRow(this.ppapPackageIdentifierId).subscribe(resp => {
			this.ppapPackageIdentifier = resp.ppapPackageIdentifierEntity;
			this.supplierViewService.getGcpMcpvVolumeData(resp.siteCode, this.noPartPrefix,
				this.noPartBase, this.noPartSuffix).subscribe(respOne => {
				const gcpSupplierVolumePre: GcpMcpvSupplierVolumes[] = [];
				let inDate;
				for (const data of respOne) {
					if (this.datePipe.transform(data[0], 'MMM') === 'Jan') {
						inDate = this.datePipe.transform(data[0], 'MMM-yyyy') + '(Q1)';
					} else if (this.datePipe.transform(data[0], 'MMM') === 'Apr') {
						inDate = this.datePipe.transform(data[0], 'MMM-yyyy') + '(Q2)';
					} else if (this.datePipe.transform(data[0], 'MMM') === 'Jul') {
						inDate = this.datePipe.transform(data[0], 'MMM-yyyy') + '(Q3)';
					} else {
						inDate = this.datePipe.transform(data[0], 'MMM-yyyy') + '(Q4)';
					}
					gcpSupplierVolumePre.push({
						effectiveInDate: inDate,
						effectiveOutDate: data[1],
						apw: data[2],
						mpw: data[3],
						appc: data[4],
						mppc: data[5]
					});
				}
				this.gcpMcpvVolumes = gcpSupplierVolumePre;
				console.log(this.gcpMcpvVolumes);
			});
			this.supplierViewService.getGcpMcpvCapacityData(resp.siteCode,
				this.noPartPrefix, this.noPartBase, this.noPartSuffix).subscribe(respTwo => {
				const gcpSupplierCapacityPre: GcpMcpvSupplierCapcity[] = [];
				for (const data of respTwo) {
					gcpSupplierCapacityPre.push({
						effectiveInDate: data[0],
						effectiveOutDate: data[1],
						appc: data[2],
						mppc: data[3]
					});
				}
				this.gcpMcpvCapacity = gcpSupplierCapacityPre;
				console.log(this.gcpMcpvVolumes);
			});
		});
	}

	showConfirmationDialogView($event: MouseEvent) {
		if (this.isViewMode) {
			this.confirmationService.confirm({
				message: 'Are you sure you want to close?',
				header: 'Confirmation',
				icon: 'pi pi-info-circle',
				accept: () => {
					this.msgs = [];
					this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
					// this.isVisible = true;
					// this.isEpswInformation = true;
					// this.isCopyVisible = true;
					//this.router.navigate(['/home-page']);
				},
				reject: () => {
				}
			});
		} else {
			this.isShown = true;
			//this.isVisible = false;
		}
	}

	approvePpapPackage() {
		const modifiedData = [];
		if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
			if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
				for (const data of this.partNumberDetail) {
					if (Number(this.familyPswId) !== 0) {
						if (data.familyPswId === Number(this.familyPswId)) {
							modifiedData.push(data.ppapPackagePartsEntityID);
							data.pswStaStatus = 'APPROVED';
							data.pswStaComments = this.pswStaComments;
							//data.pswStaStatusBy = sessionStorage.userId;
							data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
							data.pswStaUserId = sessionStorage.userId;
							data.pswStaStatusOn = new Date();
							this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
								|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
							if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
								(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
								if (data.pswPdStatus === 'APPROVED') {
									data.overallPswStatus = 'APPROVED';
								} else if (data.pswPdStatus === 'REJECTED') {
									data.overallPswStatus = 'REJECTED';
								} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
									data.overallPswStatus = 'NEEDS CLARIFICATION';
								} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
									data.overallPswStatus = 'INTERIM ACCEPTED';
								} else if (data.pswPdStatus === '') {
									data.overallPswStatus = 'AWAITING APPROVAL';
									// if (this.ppapLevel !== '1') {
									// 	data.overallPswStatus = 'AWAITING APPROVAL';
									// } else {
									// 	data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
									// }
								}
							} else {
								data.overallPswStatus = data.pswStaStatus;
							}
							data.status = data.overallPswStatus;
							data.overallPpapStatus = data.overallPswStatus;
						} else {
							if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
								modifiedData.push(data.ppapPackagePartsEntityID);
								data.pswStaStatus = 'APPROVED';
								data.pswStaComments = this.pswStaComments;
								//data.pswStaStatusBy = sessionStorage.userId;
								data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
								data.pswStaUserId = sessionStorage.userId;
								data.pswStaStatusOn = new Date();
								this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
									|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
								if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
									(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
									if (data.pswPdStatus === 'APPROVED') {
										data.overallPswStatus = 'APPROVED';
									} else if (data.pswPdStatus === 'REJECTED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
										data.overallPswStatus = 'NEEDS CLARIFICATION';
									} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
										data.overallPswStatus = 'INTERIM ACCEPTED';
									} else if (data.pswPdStatus === '') {
										data.overallPswStatus = 'AWAITING APPROVAL';
										// if (this.ppapLevel !== '1') {
										// 	data.overallPswStatus = 'AWAITING APPROVAL';
										// } else {
										// 	data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
										// }
									}
								} else {
									data.overallPswStatus = data.pswStaStatus;
								}
								data.status = data.overallPswStatus;
								data.overallPpapStatus = data.overallPswStatus;

							}
						}
					} else {
						if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
							modifiedData.push(data.ppapPackagePartsEntityID);
							data.pswStaStatus = 'APPROVED';
							data.pswStaComments = this.pswStaComments;
							//data.pswStaStatusBy = sessionStorage.userId;
							data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
							data.pswStaUserId = sessionStorage.userId;
							data.pswStaStatusOn = new Date();
							this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
								|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
							if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
								(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
								if (data.pswPdStatus === 'APPROVED') {
									data.overallPswStatus = 'APPROVED';
								} else if (data.pswPdStatus === 'REJECTED') {
									data.overallPswStatus = 'REJECTED';
								} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
									data.overallPswStatus = 'NEEDS CLARIFICATION';
								} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
									data.overallPswStatus = 'INTERIM ACCEPTED';
								} else if (data.pswPdStatus === '') {
									data.overallPswStatus = 'AWAITING APPROVAL';
									// if (this.ppapLevel !== '1') {
									// 	data.overallPswStatus = 'AWAITING APPROVAL';
									// } else {
									// 	data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
									// }
								}
							} else {
								data.overallPswStatus = data.pswStaStatus;
							}
							data.status = data.overallPswStatus;
							data.overallPpapStatus = data.overallPswStatus;
						}
					}
				}
			} else if (this.pswApproverAccessControl.get('pdApprovalConditionsEnabled')) {
				for (const data of this.partNumberDetail) {
					if (Number(this.familyPswId) !== 0) {
						if (data.familyPswId === Number(this.familyPswId)) {
							modifiedData.push(data.ppapPackagePartsEntityID);
							data.pswPdStatus = 'APPROVED';
							data.pswPdComments = this.pswPdComments;
							//data.pswPdStatusBy = sessionStorage.userId;
							data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
							data.pswPdUserId = sessionStorage.userId;
							data.pswPdStatusOn = new Date();
							if (data.pswStaStatus === 'APPROVED') {
								data.overallPswStatus = 'APPROVED';
							} else if (data.pswStaStatus === 'REJECTED') {
								data.overallPswStatus = 'REJECTED';
							} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
								data.overallPswStatus = 'NEEDS CLARIFICATION';
							} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
								data.overallPswStatus = 'INTERIM ACCEPTED';
							} else if (data.pswStaStatus === '') {
								data.overallPswStatus = 'AWAITING APPROVAL';
								// 	if (this.ppapLevel !== '1') {
								// 		data.overallPswStatus = 'AWAITING APPROVAL';
								// 	} else {
								// 		data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
								// 	}
							}
							data.status = data.overallPswStatus;
							data.overallPpapStatus = data.overallPswStatus;
						} else {
							if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
								modifiedData.push(data.ppapPackagePartsEntityID);
								data.pswPdStatus = 'APPROVED';
								data.pswPdComments = this.pswPdComments;
								//data.pswPdStatusBy = sessionStorage.userId;
								data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
								data.pswPdUserId = sessionStorage.userId;
								data.pswPdStatusOn = new Date();
								if (data.pswStaStatus === 'APPROVED') {
									data.overallPswStatus = 'APPROVED';
								} else if (data.pswStaStatus === 'REJECTED') {
									data.overallPswStatus = 'REJECTED';
								} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
									data.overallPswStatus = 'NEEDS CLARIFICATION';
								} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
									data.overallPswStatus = 'INTERIM ACCEPTED';
								} else if (data.pswStaStatus === '') {
									data.overallPswStatus = 'AWAITING APPROVAL';
									// if (this.ppapLevel !== '1') {
									// 	data.overallPswStatus = 'AWAITING APPROVAL';
									// } else {
									// 	data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
									// }
								}
								data.status = data.overallPswStatus;
								data.overallPpapStatus = data.overallPswStatus;
							}
						}
					} else {
						if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
							modifiedData.push(data.ppapPackagePartsEntityID);
							data.pswPdStatus = 'APPROVED';
							data.pswPdComments = this.pswPdComments;
							//data.pswPdStatusBy = sessionStorage.userId;
							data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
							data.pswPdUserId = sessionStorage.userId;
							data.pswPdStatusOn = new Date();
							if (data.pswStaStatus === 'APPROVED') {
								data.overallPswStatus = 'APPROVED';
							} else if (data.pswStaStatus === 'REJECTED') {
								data.overallPswStatus = 'REJECTED';
							} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
								data.overallPswStatus = 'NEEDS CLARIFICATION';
							} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
								data.overallPswStatus = 'INTERIM ACCEPTED';
							} else if (data.pswStaStatus === '') {
								data.overallPswStatus = 'AWAITING APPROVAL';
								// if (this.ppapLevel !== '1') {
								// 	data.overallPswStatus = 'AWAITING APPROVAL';
								// } else {
								// 	data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
								// }
							}
							data.status = data.overallPswStatus;
							data.overallPpapStatus = data.overallPswStatus;
						}
					}
				}
			}
		}
		this.saveApprovedStatus(modifiedData, this.partNumberDetail);
	}

	interimAcceptPackage() {
		const modifiedData = [];
		if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
			if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
				for (const data of this.partNumberDetail) {
					if (Number(this.familyPswId) !== 0) {
						if (data.familyPswId === Number(this.familyPswId)) {
							modifiedData.push(data.ppapPackagePartsEntityID);
							data.pswStaStatus = 'INTERIM ACCEPTED';
							data.pswStaComments = this.pswStaComments;
							//data.pswStaStatusBy = sessionStorage.userId;
							data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
							data.pswStaUserId = sessionStorage.userId;
							data.pswStaStatusOn = new Date();
							this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
								|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
							if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
								(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
								if (data.pswPdStatus === 'APPROVED') {
									data.overallPswStatus = 'INTERIM ACCEPTED';
								} else if (data.pswPdStatus === 'REJECTED') {
									data.overallPswStatus = 'REJECTED';
								} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
									data.overallPswStatus = 'NEEDS CLARIFICATION';
								} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
									data.overallPswStatus = 'INTERIM ACCEPTED';
								} else if (data.pswPdStatus === '') {
									data.overallPswStatus = 'AWAITING APPROVAL';
									// if (this.ppapLevel !== '1') {
									// 	data.overallPswStatus = 'AWAITING APPROVAL';
									// } else {
									// 	data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
									// }
								}
							} else {
								data.overallPswStatus = data.pswStaStatus;
							}
							data.status = data.overallPswStatus;
							data.overallPpapStatus = data.overallPswStatus;
						} else {
							if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
								modifiedData.push(data.ppapPackagePartsEntityID);
								data.pswStaStatus = 'INTERIM ACCEPTED';
								data.pswStaComments = this.pswStaComments;
								//data.pswStaStatusBy = sessionStorage.userId;
								data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
								data.pswStaUserId = sessionStorage.userId;
								data.pswStaStatusOn = new Date();
								this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
									|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
								if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
									(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
									if (data.pswPdStatus === 'APPROVED') {
										data.overallPswStatus = 'INTERIM ACCEPTED';
									} else if (data.pswPdStatus === 'REJECTED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
										data.overallPswStatus = 'NEEDS CLARIFICATION';
									} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
										data.overallPswStatus = 'INTERIM ACCEPTED';
									} else if (data.pswPdStatus === '') {
										data.overallPswStatus = 'AWAITING APPROVAL';
										// if (this.ppapLevel !== '1') {
										// 	data.overallPswStatus = 'AWAITING APPROVAL';
										// } else {
										// 	data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
										// }
									}
								} else {
									data.overallPswStatus = data.pswStaStatus;
								}
								data.status = data.overallPswStatus;
								data.overallPpapStatus = data.overallPswStatus;
							}
						}
					} else {
						if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
							modifiedData.push(data.ppapPackagePartsEntityID);
							data.pswStaStatus = 'INTERIM ACCEPTED';
							data.pswStaComments = this.pswStaComments;
							//data.pswStaStatusBy = sessionStorage.userId;
							data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
							data.pswStaUserId = sessionStorage.userId;
							data.pswStaStatusOn = new Date();
							this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
								|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
							if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
								(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
								if (data.pswPdStatus === 'APPROVED') {
									data.overallPswStatus = 'INTERIM ACCEPTED';
								} else if (data.pswPdStatus === 'REJECTED') {
									data.overallPswStatus = 'REJECTED';
								} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
									data.overallPswStatus = 'NEEDS CLARIFICATION';
								} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
									data.overallPswStatus = 'INTERIM ACCEPTED';
								} else if (data.pswPdStatus === '') {
									data.overallPswStatus = 'AWAITING APPROVAL';
									// if (this.ppapLevel !== '1') {
									// 	data.overallPswStatus = 'AWAITING APPROVAL';
									// } else {
									// 	data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
									// }
								}
							} else {
								data.overallPswStatus = data.pswStaStatus;
							}
							data.status = data.overallPswStatus;
							data.overallPpapStatus = data.overallPswStatus;
						}
					}
				}
			} else if (this.pswApproverAccessControl.get('pdApprovalConditionsEnabled')) {
				for (const data of this.partNumberDetail) {
					if (Number(this.familyPswId) !== 0) {
						if (data.familyPswId === Number(this.familyPswId)) {
							modifiedData.push(data.ppapPackagePartsEntityID);
							data.pswPdStatus = 'INTERIM ACCEPTED';
							data.pswPdComments = this.pswPdComments;
							//data.pswPdStatusBy = sessionStorage.userId;
							data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
							data.pswPdUserId = sessionStorage.userId;
							data.pswPdStatusOn = new Date();
							if (data.pswStaStatus === 'APPROVED') {
								data.overallPswStatus = 'INTERIM ACCEPTED';
							} else if (data.pswStaStatus === 'REJECTED') {
								data.overallPswStatus = 'REJECTED';
							} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
								data.overallPswStatus = 'NEEDS CLARIFICATION';
							} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
								data.overallPswStatus = 'INTERIM ACCEPTED';
							} else if (data.pswStaStatus === '') {
								data.overallPswStatus = 'AWAITING APPROVAL';
								// if (this.ppapLevel !== '1') {
								// 	data.overallPswStatus = 'AWAITING APPROVAL';
								// } else {
								// 	data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
								// }
							}
							data.status = data.overallPswStatus;
							data.overallPpapStatus = data.overallPswStatus;
						} else {
							if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
								modifiedData.push(data.ppapPackagePartsEntityID);
								data.pswPdStatus = 'INTERIM ACCEPTED';
								data.pswPdComments = this.pswPdComments;
								//data.pswPdStatusBy = sessionStorage.userId;
								data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
								data.pswPdUserId = sessionStorage.userId;
								data.pswPdStatusOn = new Date();
								if (data.pswStaStatus === 'APPROVED') {
									data.overallPswStatus = 'INTERIM ACCEPTED';
								} else if (data.pswStaStatus === 'REJECTED') {
									data.overallPswStatus = 'REJECTED';
								} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
									data.overallPswStatus = 'NEEDS CLARIFICATION';
								} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
									data.overallPswStatus = 'INTERIM ACCEPTED';
								} else if (data.pswStaStatus === '') {
									data.overallPswStatus = 'AWAITING APPROVAL';
									// if (this.ppapLevel !== '1') {
									// 	data.overallPswStatus = 'AWAITING APPROVAL';
									// } else {
									// 	data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
									// }
								}
								data.status = data.overallPswStatus;
								data.overallPpapStatus = data.overallPswStatus;
							}
						}
					} else {
						if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
							modifiedData.push(data.ppapPackagePartsEntityID);
							data.pswPdStatus = 'INTERIM ACCEPTED';
							data.pswPdComments = this.pswPdComments;
							//data.pswPdStatusBy = sessionStorage.userId;
							data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
							data.pswPdUserId = sessionStorage.userId;
							data.pswPdStatusOn = new Date();
							if (data.pswStaStatus === 'APPROVED') {
								data.overallPswStatus = 'INTERIM ACCEPTED';
							} else if (data.pswStaStatus === 'REJECTED') {
								data.overallPswStatus = 'REJECTED';
							} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
								data.overallPswStatus = 'NEEDS CLARIFICATION';
							} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
								data.overallPswStatus = 'INTERIM ACCEPTED';
							} else if (data.pswStaStatus === '') {
								data.overallPswStatus = 'AWAITING APPROVAL';
								// if (this.ppapLevel !== '1') {
								// 	data.overallPswStatus = 'AWAITING APPROVAL';
								// } else {
								// 	data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
								// }
							}
							data.status = data.overallPswStatus;
							data.overallPpapStatus = data.overallPswStatus;
						}
					}
				}
			}
		}
		this.saveInterimAcceptedStatus(modifiedData, this.partNumberDetail);
	}

	rejectPpapPackage() {
		const modifiedData = [];
		if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
			if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
				if (this.pswStaComments !== undefined && this.pswStaComments.length !== 0) {
					for (const data of this.partNumberDetail) {
						if (Number(this.familyPswId) !== 0) {
							if (data.familyPswId === Number(this.familyPswId)) {
								if (data.status !== 'APPROVED') {
									modifiedData.push(data.ppapPackagePartsEntityID);
									data.pswStaStatus = 'REJECTED';
									data.pswStaComments = this.pswStaComments;
									data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
									data.pswStaUserId = sessionStorage.userId;
									data.pswStaStatusOn = new Date();
									this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
										|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
									if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
										(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
										if (data.pswPdStatus === 'APPROVED') {
											data.overallPswStatus = 'REJECTED';
										} else if (data.pswPdStatus === 'REJECTED') {
											data.overallPswStatus = 'REJECTED';
										} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
											data.overallPswStatus = 'REJECTED';
										} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
											data.overallPswStatus = 'REJECTED';
										} else if (data.pswPdStatus === '') {
											data.overallPswStatus = 'REJECTED';
										}
									} else {
										data.overallPswStatus = data.pswStaStatus;
									}
									data.status = data.overallPswStatus;
									data.overallPpapStatus = data.overallPswStatus;
								}
							} else {
								if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
									if (data.status !== 'APPROVED') {
										modifiedData.push(data.ppapPackagePartsEntityID);
										data.pswStaStatus = 'REJECTED';
										data.pswStaComments = this.pswStaComments;
										data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
										data.pswStaUserId = sessionStorage.userId;
										data.pswStaStatusOn = new Date();
										this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
											|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
										if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
											(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
											if (data.pswPdStatus === 'APPROVED') {
												data.overallPswStatus = 'REJECTED';
											} else if (data.pswPdStatus === 'REJECTED') {
												data.overallPswStatus = 'REJECTED';
											} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
												data.overallPswStatus = 'REJECTED';
											} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
												data.overallPswStatus = 'REJECTED';
											} else if (data.pswPdStatus === '') {
												data.overallPswStatus = 'REJECTED';
											}
										} else {
											data.overallPswStatus = data.pswStaStatus;
										}
										data.status = data.overallPswStatus;
										data.overallPpapStatus = data.overallPswStatus;
									}
								}
							}
						} else {
							if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
								if (data.status !== 'APPROVED') {
									modifiedData.push(data.ppapPackagePartsEntityID);
									data.pswStaStatus = 'REJECTED';
									data.pswStaComments = this.pswStaComments;
									data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
									data.pswStaUserId = sessionStorage.userId;
									data.pswStaStatusOn = new Date();
									this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
										|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
									if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
										(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
										if (data.pswPdStatus === 'APPROVED') {
											data.overallPswStatus = 'REJECTED';
										} else if (data.pswPdStatus === 'REJECTED') {
											data.overallPswStatus = 'REJECTED';
										} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
											data.overallPswStatus = 'REJECTED';
										} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
											data.overallPswStatus = 'REJECTED';
										} else if (data.pswPdStatus === '') {
											data.overallPswStatus = 'REJECTED';
										}
									} else {
										data.overallPswStatus = data.pswStaStatus;
									}
									data.status = data.overallPswStatus;
									data.overallPpapStatus = data.overallPswStatus;
								}
							}
						}
					}
					this.saveCommentsForRejection(modifiedData, this.partNumberDetail);
				} else {
					this.messageService.add({severity: 'error', summary: 'Please provide STA Comments'});
				}
			} else {
				if (this.pswPdComments !== undefined && this.pswPdComments.length !== 0) {
					for (const data of this.partNumberDetail) {
						if (Number(this.familyPswId) !== 0) {
							if (data.familyPswId === Number(this.familyPswId)) {
								if (data.status !== 'APPROVED') {
									modifiedData.push(data.ppapPackagePartsEntityID);
									data.pswPdStatus = 'REJECTED';
									data.pswPdComments = this.pswPdComments;
									//data.pswPdStatusBy = sessionStorage.userId;
									data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
									data.pswPdUserId = sessionStorage.userId;
									data.pswPdStatusOn = new Date();
									if (data.pswStaStatus === 'APPROVED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === 'REJECTED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === '') {
										data.overallPswStatus = 'REJECTED';
									}
									data.status = data.overallPswStatus;
									data.overallPpapStatus = data.overallPswStatus;
								}
							}
							if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
								if (data.status !== 'APPROVED') {
									modifiedData.push(data.ppapPackagePartsEntityID);
									data.pswPdStatus = 'REJECTED';
									data.pswPdComments = this.pswPdComments;
									//data.pswPdStatusBy = sessionStorage.userId;
									data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
									data.pswPdUserId = sessionStorage.userId;
									data.pswPdStatusOn = new Date();
									if (data.pswStaStatus === 'APPROVED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === 'REJECTED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === '') {
										data.overallPswStatus = 'REJECTED';
									}
									data.status = data.overallPswStatus;
									data.overallPpapStatus = data.overallPswStatus;
								}
							}
						} else {
							if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
								if (data.status !== 'APPROVED') {
									modifiedData.push(data.ppapPackagePartsEntityID);
									data.pswPdStatus = 'REJECTED';
									data.pswPdComments = this.pswPdComments;
									//data.pswPdStatusBy = sessionStorage.userId;
									data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
									data.pswPdUserId = sessionStorage.userId;
									data.pswPdStatusOn = new Date();
									if (data.pswStaStatus === 'APPROVED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === 'REJECTED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === '') {
										data.overallPswStatus = 'REJECTED';
									}
									data.status = data.overallPswStatus;
									data.overallPpapStatus = data.overallPswStatus;
								}
							}
						}
					}
					this.saveCommentsForRejection(modifiedData, this.partNumberDetail);
				} else {
					this.messageService.add({severity: 'error', summary: 'Please provide PD Comments'});
				}
			}
		}
		// this.saveCommentsForRejection(modifiedData, this.partNumberDetail);
	}

	needsClarification() {
		const modifiedData = [];
		if (this.pswApproverAccessControl.get('approverConditionEnabled')) {
			if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
				if (this.pswStaComments !== undefined && this.pswStaComments.length !== 0) {
					for (const data of this.partNumberDetail) {
						if (Number(this.familyPswId) !== 0) {
							if (data.familyPswId === Number(this.familyPswId)) {
								if (data.status !== 'APPROVED') {
									modifiedData.push(data.ppapPackagePartsEntityID);
									data.pswStaStatus = 'NEEDS CLARIFICATION';
									data.pswStaComments = this.pswStaComments;
									//data.pswStaStatusBy = sessionStorage.userId;
									data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
									data.pswStaUserId = sessionStorage.userId;
									data.pswStaStatusOn = new Date();
									this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
										|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
									if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
										(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
										if (data.pswPdStatus === 'APPROVED') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										} else if (data.pswPdStatus === 'REJECTED') {
											data.overallPswStatus = 'REJECTED';
										} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										} else if (data.pswPdStatus === '') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										}
									} else {
										data.overallPswStatus = data.pswStaStatus;
									}
									data.status = data.overallPswStatus;
									data.overallPpapStatus = data.overallPswStatus;
								}
							} else {
								if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
									if (data.status !== 'APPROVED') {
										modifiedData.push(data.ppapPackagePartsEntityID);
										data.pswStaStatus = 'NEEDS CLARIFICATION';
										data.pswStaComments = this.pswStaComments;
										//data.pswStaStatusBy = sessionStorage.userId;
										data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
										data.pswStaUserId = sessionStorage.userId;
										data.pswStaStatusOn = new Date();
										this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
											|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
										if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
											(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
											if (data.pswPdStatus === 'APPROVED') {
												data.overallPswStatus = 'NEEDS CLARIFICATION';
											} else if (data.pswPdStatus === 'REJECTED') {
												data.overallPswStatus = 'REJECTED';
											} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
												data.overallPswStatus = 'NEEDS CLARIFICATION';
											} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
												data.overallPswStatus = 'NEEDS CLARIFICATION';
											} else if (data.pswPdStatus === '') {
												data.overallPswStatus = 'NEEDS CLARIFICATION';
											}
										} else {
											data.overallPswStatus = data.pswStaStatus;
										}
										data.status = data.overallPswStatus;
										data.overallPpapStatus = data.overallPswStatus;
									}
								}
							}
						} else {
							if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
								if (data.status !== 'APPROVED') {
									modifiedData.push(data.ppapPackagePartsEntityID);
									data.pswStaStatus = 'NEEDS CLARIFICATION';
									data.pswStaComments = this.pswStaComments;
									//data.pswStaStatusBy = sessionStorage.userId;
									data.pswStaStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
									data.pswStaUserId = sessionStorage.userId;
									data.pswStaStatusOn = new Date();
									this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
										|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
									if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
										(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
										if (data.pswPdStatus === 'APPROVED') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										} else if (data.pswPdStatus === 'REJECTED') {
											data.overallPswStatus = 'REJECTED';
										} else if (data.pswPdStatus === 'NEEDS CLARIFICATION') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										} else if (data.pswPdStatus === 'INTERIM ACCEPTED') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										} else if (data.pswPdStatus === '') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										}
									} else {
										data.overallPswStatus = data.pswStaStatus;
									}
									data.status = data.overallPswStatus;
									data.overallPpapStatus = data.overallPswStatus;
								}
							}
						}
					}
					this.SaveCommentsForClarification(modifiedData, this.partNumberDetail);
				} else {
					this.messageService.add({severity: 'error', summary: 'Please provide STA Comments'});
				}
			} else {
				if (this.pswPdComments !== undefined && this.pswPdComments.length !== 0) {
					for (const data of this.partNumberDetail) {
						if (Number(this.familyPswId) !== 0) {
							if (data.familyPswId === Number(this.familyPswId)) {
								if (data.status !== 'APPROVED') {
									modifiedData.push(data.ppapPackagePartsEntityID);
									data.pswPdStatus = 'NEEDS CLARIFICATION';
									data.pswPdComments = this.pswPdComments;
									//data.pswPdStatusBy = sessionStorage.userId;
									data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
									data.pswPdUserId = sessionStorage.userId;
									data.pswPdStatusOn = new Date();
									if (data.pswStaStatus === 'APPROVED') {
										data.overallPswStatus = 'NEEDS CLARIFICATION';
									} else if (data.pswStaStatus === 'REJECTED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
										data.overallPswStatus = 'NEEDS CLARIFICATION';
									} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
										data.overallPswStatus = 'NEEDS CLARIFICATION';
									} else if (data.pswStaStatus === '') {
										data.overallPswStatus = 'NEEDS CLARIFICATION';
									}
									data.status = data.overallPswStatus;
									data.overallPpapStatus = data.overallPswStatus;
								}
							} else {
								if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
									if (data.status !== 'APPROVED') {
										modifiedData.push(data.ppapPackagePartsEntityID);
										data.pswPdStatus = 'NEEDS CLARIFICATION';
										data.pswPdComments = this.pswPdComments;
										//data.pswPdStatusBy = sessionStorage.userId;
										data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
										data.pswPdUserId = sessionStorage.userId;
										data.pswPdStatusOn = new Date();
										if (data.pswStaStatus === 'APPROVED') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										} else if (data.pswStaStatus === 'REJECTED') {
											data.overallPswStatus = 'REJECTED';
										} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										} else if (data.pswStaStatus === '') {
											data.overallPswStatus = 'NEEDS CLARIFICATION';
										}
										data.status = data.overallPswStatus;
										data.overallPpapStatus = data.overallPswStatus;
									}
								}
							}
						} else {
							if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
								if (data.status !== 'APPROVED') {
									modifiedData.push(data.ppapPackagePartsEntityID);
									data.pswPdStatus = 'NEEDS CLARIFICATION';
									data.pswPdComments = this.pswPdComments;
									//data.pswPdStatusBy = sessionStorage.userId;
									data.pswPdStatusBy = sessionStorage.displayLeadName + ' ' + '-' + ' ' + sessionStorage.userId;
									data.pswPdUserId = sessionStorage.userId;
									data.pswPdStatusOn = new Date();
									if (data.pswStaStatus === 'APPROVED') {
										data.overallPswStatus = 'NEEDS CLARIFICATION';
									} else if (data.pswStaStatus === 'REJECTED') {
										data.overallPswStatus = 'REJECTED';
									} else if (data.pswStaStatus === 'NEEDS CLARIFICATION') {
										data.overallPswStatus = 'NEEDS CLARIFICATION';
									} else if (data.pswStaStatus === 'INTERIM ACCEPTED') {
										data.overallPswStatus = 'NEEDS CLARIFICATION';
									} else if (data.pswStaStatus === '') {
										data.overallPswStatus = 'NEEDS CLARIFICATION';
									}
									data.status = data.overallPswStatus;
									data.overallPpapStatus = data.overallPswStatus;
								}
							}
						}

					}
					this.SaveCommentsForClarification(modifiedData, this.partNumberDetail);
				} else {
					this.messageService.add({severity: 'error', summary: 'Please provide PD Comments'});
				}
			}
		}
		// this.SaveCommentsForClarification(modifiedData, this.partNumberDetail);
	}

	setOverAllPartStatus(data) {
		if (data.overallPpapStatus === 'APPROVED' && data.overallPswStatus === 'APPROVED') {
			data.status = 'APPROVED';
		} else if (data.overallPpapStatus === 'APPROVED' && data.overallPswStatus === 'REJECTED') {
			data.status = 'REJECTED';
		} else if (data.overallPpapStatus === 'APPROVED' && data.overallPswStatus === 'NEEDS CLARIFICATION') {
			data.status = 'NEEDS CLARIFICATION';
		} else if (data.overallPpapStatus === 'APPROVED' && data.overallPswStatus === 'INTERIM ACCEPTED') {
			data.status = 'INTERIM ACCEPTED';
		} else if (data.overallPpapStatus === 'REJECTED' && data.overallPswStatus === 'APPROVED') {
			data.status = 'REJECTED';
		} else if (data.overallPpapStatus === 'REJECTED' && data.overallPswStatus === 'REJECTED') {
			data.status = 'REJECTED';
		} else if (data.overallPpapStatus === 'REJECTED' && data.overallPswStatus === 'NEEDS CLARIFICATION') {
			data.status = 'REJECTED';
		} else if (data.overallPpapStatus === 'REJECTED' && data.overallPswStatus === 'INTERIM ACCEPTED') {
			data.status = 'REJECTED';
		} else if (data.overallPpapStatus === 'NEEDS CLARIFICATION' && data.overallPswStatus === 'APPROVED') {
			data.status = 'NEEDS CLARIFICATION';
		} else if (data.overallPpapStatus === 'NEEDS CLARIFICATION' && data.overallPswStatus === 'REJECTED') {
			data.status = 'REJECTED';
		} else if (data.overallPpapStatus === 'NEEDS CLARIFICATION' && data.overallPswStatus === 'NEEDS CLARIFICATION') {
			data.status = 'NEEDS CLARIFICATION';
		} else if (data.overallPpapStatus === 'NEEDS CLARIFICATION' && data.overallPswStatus === 'INTERIM ACCEPTED') {
			data.status = 'NEEDS CLARIFICATION';
		} else if ((data.overallPswStatus === 'AWAITING APPROVAL' || data.overallPswStatus === 'LEVEL 1 SELF APPROVED') && data.overallPpapStatus === 'APPROVED') {
			if (this.ppapLevel !== '1') {
				data.status = 'AWAITING APPROVAL';
			} else {
				data.status = 'LEVEL 1 SELF APPROVED';
			}
		} else if ((data.overallPswStatus === 'AWAITING APPROVAL' || data.overallPswStatus === 'LEVEL 1 SELF APPROVED') && data.overallPpapStatus === 'REJECTED') {
			data.status = 'REJECTED';
		} else if ((data.overallPswStatus === 'AWAITING APPROVAL' || data.overallPswStatus === 'LEVEL 1 SELF APPROVED') && data.overallPpapStatus === 'NEEDS CLARIFICATION') {
			data.status = 'NEEDS CLARIFICATION';
		} else if ((data.overallPswStatus === 'AWAITING APPROVAL' || data.overallPswStatus === 'LEVEL 1 SELF APPROVED') && data.overallPpapStatus === 'INTERIM ACCEPTED') {
			if (this.ppapLevel !== '1') {
				data.status = 'AWAITING APPROVAL';
			} else {
				data.status = 'LEVEL 1 SELF APPROVED';
			}
		}
	}

	private saveCommentsForRejection(modifiedData, partNumberDetail) {
		// this.setOverAllPartStatus(partNumberDetail);
		partNumberDetail = partNumberDetail.filter(part => modifiedData.includes(part.ppapPackagePartsEntityID))
		this.digitalPpapPackageService.rejectPpapPackageForParts(partNumberDetail).subscribe(response => {
			console.log(response);
			sessionStorage.setItem('rejectMessage', JSON.stringify({
				status: 'success',
				message: 'PPAP Package Rejected Successfully'
			}));
			this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
			//this.messageService.add({severity: 'success', summary: 'PPAP Package Rejected Successfully',life:5000});
		});
	}

	private saveApprovedStatus(modifiedData, partNumberDetail) {
		// this.setOverAllPartStatus(partNumberDetail);
		// sessionStorage.setItem('approveMessage', JSON.stringify({
		// 	status: 'success',
		// 	message: 'PPAP Package Approved Successfully'
		// }));
		// //this.messageService.add({severity: 'success', summary: 'PPAP Package Approved Successfully'});
		// this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId+'/'+this.mode]);
		//Added for US3652011
		// let saveModifiedData:PpapPackageParts[]  = [];
		// saveModifiedData.push();
		partNumberDetail = partNumberDetail.filter(part => modifiedData.includes(part.ppapPackagePartsEntityID))
		this.confirmationService.confirm({
			message: 'Ensure all required PPAP evidences uploaded by Supplier are reviewed and met Ford Phased PPAP Requirements. Are you sure to approve now?',
			header: 'Approve Confirmation',
			icon: 'pi pi-info-circle',
			accept: () => {
				/*for (const data of this.partNumberDetail) {
					data.pswStaStatus = 'APPROVED';
					data.status = 'APPROVED';
					data.overallPpapStatus = 'APPROVED';
					console.log('enter for loop',data.ppapStaStatus);
				}*/
				this.digitalPpapPackageService.approvePpapPackageForParts(partNumberDetail).subscribe(response => {
					console.log(response);
					sessionStorage.setItem('approveMessage', JSON.stringify({
						status: 'success',
						message: 'PPAP Package Approved Successfully'
					}));
					//this.messageService.add({severity: 'success', summary: 'PPAP Package Approved Successfully'});
					this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
				});
			},
			reject: () => {
				this.disableApproverActions = true;
				/*this.digitalPpapPackageService.approvePpapPackageForParts(partNumberDetail).subscribe(response => {
					console.log(response);
				});*/
				this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
			}
			//Ended for US3652011
		});
	}

	private saveInterimAcceptedStatus(modifiedData, partNumberDetail) {
		partNumberDetail = partNumberDetail.filter(part => modifiedData.includes(part.ppapPackagePartsEntityID));
		// this.setOverAllPartStatus(partNumberDetail);
		this.digitalPpapPackageService.approvePpapPackageForParts(partNumberDetail).subscribe(response => {
			console.log(response);
			sessionStorage.setItem('interimAcceptedMessage', JSON.stringify({
				status: 'success',
				message: 'PPAP Package Accepted Successfully'
			}));
			//this.messageService.add({severity: 'success', summary: 'PPAP Package Accepted Successfully'});
			this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
		});
	}

	private SaveCommentsForClarification(modifiedData, partNumberDetail) {
		// this.setOverAllPartStatus(partNumberDetail);
		partNumberDetail = partNumberDetail.filter(part => modifiedData.includes(part.ppapPackagePartsEntityID))
		this.digitalPpapPackageService.needsClarificationForParts(partNumberDetail).subscribe(response => {
			console.log(response);
			sessionStorage.setItem('needsClarificationMessage', JSON.stringify({
				status: 'success',
				message: 'PPAP Package Needs Clarification'
			}));
			//this.messageService.add({severity: 'success', summary: 'PPAP Package Needs Clarification'});
			this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
		});
	}

	selectRadiobutton(meetDesignReq: string) {
		if (meetDesignReq === 'Yes') {
			this.isDesignExplnVisible = false;
			if (this.isDesignExplnVisible === false) {
				this.pswPartDetailSave.designReqExpln = '';
			}
		}
		if (meetDesignReq === 'No') {
			this.isDesignExplnVisible = true;
		}
		if (meetDesignReq === null || meetDesignReq === undefined) {
			this.isDesignExplnVisible = false;
		}
	}

	private saveInterimPpapStatusCheckboxValues() {
		if (this.ppapLevel === '1' && this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '0')
			this.pswPartDetailSave.interimPpapStatus = false;
		else
			this.pswPartDetailSave.interimPpapStatus = this.selectedInterimCheckBox;
	}

	private savePhaseCheckboxValues() {
		this.ppapPackagePartSave = new PpapPackageParts(this.ppapPackageParts);
		this.familyPswId = sessionStorage.getItem('familyPswId');
		console.log(this.familyPswId);
		if (this.familyPswId !== undefined && this.familyPswId !== null) {
			this.ppapPackagePartSave.familyPswId = this.familyPswId;
		}
		this.ppapPackagePartSave.phase1Phase2 = this.selectedPhaseValue;
		this.supplierViewService.savePhaseValueData(this.ppapPackagePartSave, this.ppapPackagePartsEntityId).subscribe(resp => {
			console.log('Save Data: ' + resp);
		});
	}

	inputNumberValidationWithFiveDigits(event: any) {
		const invalidChars = [
			'-',
			'+',
			'e',
		];
		if (invalidChars.includes(event.key)) {
			event.preventDefault();
		}
		// Check if the text is selected allow to continue
		if (document.getSelection().toString() === event.target.value.toString()) {
			console.log('selection condition matched, returning true');
			return true;
		}

		// checking for zero on first char position
		if (event.target.value.length === 0) {
			//	console.log('length is zero so checking entered value is zero or not');
			if (event.key === '0') {
				//	console.log('entered values is zero returning false');
				return false;
			}
		}
		//checking entered value is between 0 and 9 or dot
		if (event.key >= '0' && event.key <= '9' || event.key === '.') {
			//	console.log('Entered values is between 0 and 9');
		} else {
			//	console.log('Entered key is not between 0 and 9');
			return false;
		}
		//checking after . there can be five digit
		const targetValue = event.target.value.toString();
		//	console.log('Target value in string format: ' + tarValue);
		const splitarr = targetValue.split('.');
		console.log('splitted value');
		console.log(splitarr);
		if (splitarr.length > 1) {
			console.log('splitted second value: ' + splitarr[1]);
			console.log('length of second array: ');
			console.log(splitarr[1].length);
			if (event.key === '.') {
				return false;
			}
			if (splitarr[1].length === 5) {
				console.log('Five digits present. so returning false for Sixth digit');
				return false;
			}
		}
		return true;
	}

	/*onSelectPaReqSourceDropdown(paReqSrc: any) {
		if (paReqSrc !== null && paReqSrc !== undefined) {
			this.selectedPaReqSourcDropdown = this.pswPartDetailSave.paReqSource.name;
		}
	}*/

	savePaReqSourceDropdown() {
		/*if (this.selectedPaReqSourcDropdown !== null && this.selectedPaReqSourcDropdown !== undefined) {
			this.pswPartDetailSave.paReqSource = this.selectedPaReqSourcDropdown;
		} else*/
		if (this.pswPartDetailSave.paReqSource !== null && this.pswPartDetailSave.paReqSource !== undefined) {
			// if (this.pswPartDetailSave.paReqSource !== null && this.pswPartDetailSave.paReqSource !== undefined) {
			this.pswPartDetailSave.paReqSource = this.pswPartDetailSave.paReqSource;
			// } else {
			// 	this.pswPartDetailSave.paReqSource = null;
			// }
		} else {
			this.pswPartDetailSave.paReqSource = null;
		}
		// else {
		// 	this.pswPartDetailSave.reqSubmitLvl = this.submissionLevelOption[0].name;
		// }
	}

	/*onSelectAftrPaReqSourceDropdown(aftrPaReqSrc: any) {
		if (aftrPaReqSrc !== null && aftrPaReqSrc !== undefined) {
			this.selectedAfterPaReqSourcDropdown = this.pswPartDetailSave.aftrPaReqSource.name;
		}
	}*/

	saveAftrPaReqSourceDropdown() {
		/*if (this.selectedAfterPaReqSourcDropdown !== null && this.selectedAfterPaReqSourcDropdown !== undefined) {
			this.pswPartDetailSave.aftrPaReqSource = this.selectedAfterPaReqSourcDropdown;
		} else*/
		if (this.pswPartDetailSave.aftrPaReqSource !== null && this.pswPartDetailSave.aftrPaReqSource !== undefined) {
			// if (this.pswPartDetailSave.aftrPaReqSource.name !== null && this.pswPartDetailSave.aftrPaReqSource.name !== undefined) {
			this.pswPartDetailSave.aftrPaReqSource = this.pswPartDetailSave.aftrPaReqSource;
			// } else {
			// 	this.pswPartDetailSave.aftrPaReqSource = null;
			// }
		} else {
			this.pswPartDetailSave.aftrPaReqSource = null;
		}
		// else {
		// 	this.pswPartDetailSave.reqSubmitLvl = this.submissionLevelOption[0].name;
		// }
	}

	selectInterimPpapCheckbox(selectedInterimCheckBox: boolean) {
		if (selectedInterimCheckBox === true) {
			this.isShownMandatory = true;
		} else {
			this.isShownMandatory = false;
		}
	}

	private validatePhase3Data() {
		if ((this.pswPartDetailSave.custPartName === undefined || this.pswPartDetailSave.custPartName === null
				|| this.pswPartDetailSave.custPartName === '') ||
			(this.pswPartDetailSave.custPartNumber === undefined || this.pswPartDetailSave.custPartNumber === null
				|| this.pswPartDetailSave.custPartNumber === '') ||
			(this.pswPartDetailSave.engChangeLvl === undefined || this.pswPartDetailSave.engChangeLvl === null
				|| this.pswPartDetailSave.engChangeLvl === '') ||
			(this.pswPartDetailSave.engChangeLvlDate === undefined || this.pswPartDetailSave.engChangeLvlDate === null
				|| this.pswPartDetailSave.engChangeLvlDate === '') ||
			(this.pswPartDetailSave.orgName === undefined || this.pswPartDetailSave.orgName === null
				|| this.pswPartDetailSave.orgName === '') ||
			(this.pswPartDetailSave.supplierCode === undefined || this.pswPartDetailSave.supplierCode === null
				|| this.pswPartDetailSave.supplierCode === '') ||
			(this.pswPartDetailSave.street === undefined || this.pswPartDetailSave.street === null
				|| this.pswPartDetailSave.street === '') ||
			(this.pswPartDetailSave.city === undefined || this.pswPartDetailSave.city === null
				|| this.pswPartDetailSave.city === '') ||
			(this.pswPartDetailSave.state === undefined || this.pswPartDetailSave.state === null
				|| this.pswPartDetailSave.state === '') ||
			(this.pswPartDetailSave.postalCode === undefined || this.pswPartDetailSave.postalCode === null
				|| this.pswPartDetailSave.postalCode === '') ||
			(this.pswPartDetailSave.country === undefined || this.pswPartDetailSave.country === null
				|| this.pswPartDetailSave.country === '') ||
			(this.pswPartDetailSave.drawingNumber === undefined || this.pswPartDetailSave.drawingNumber === null
				|| this.pswPartDetailSave.drawingNumber === '') ||
			(this.pswPartDetailSave.orgPartNumber === undefined || this.pswPartDetailSave.orgPartNumber === null
				|| this.pswPartDetailSave.orgPartNumber === '') ||
			(this.pswPartDetailSave.addEngChanges === undefined || this.pswPartDetailSave.addEngChanges === null
				|| this.pswPartDetailSave.addEngChanges === '') ||
			(this.pswPartDetailSave.addEngChangesDate === undefined ||
				this.pswPartDetailSave.addEngChangesDate === null || this.pswPartDetailSave.addEngChangesDate === '') ||
			(this.pswPartDetailSave.safetyRegulations === undefined || this.pswPartDetailSave.safetyRegulations === null
				|| this.pswPartDetailSave.safetyRegulations === '') ||
			(this.pswPartDetailSave.poNumber === undefined || this.pswPartDetailSave.poNumber === null
				|| this.pswPartDetailSave.poNumber === '') ||
			(this.pswPartDetailSave.weight === undefined || this.pswPartDetailSave.weight === null
				|| this.pswPartDetailSave.weight === '') ||
			(this.pswPartDetailSave.checkAidNumber === undefined || this.pswPartDetailSave.checkAidNumber === null
				|| this.pswPartDetailSave.checkAidNumber === '') ||
			(this.pswPartDetailSave.checkAidEngChangeLvl === undefined || this.pswPartDetailSave.checkAidEngChangeLvl === null
				|| this.pswPartDetailSave.checkAidEngChangeLvl === '') ||
			(this.pswPartDetailSave.checkAidEngChangeLvlDate === undefined || this.pswPartDetailSave.checkAidEngChangeLvlDate === null
				|| this.pswPartDetailSave.checkAidEngChangeLvlDate === '') ||
			(this.pswPartDetailSave.custDivision === undefined || this.pswPartDetailSave.custDivision === null
				|| this.pswPartDetailSave.custDivision === '') ||
			(this.pswPartDetailSave.buyerCode === undefined || this.pswPartDetailSave.buyerCode === null
				|| this.pswPartDetailSave.buyerCode === '') ||
			(this.pswPartDetailSave.application === undefined || this.pswPartDetailSave.application === null
				|| this.pswPartDetailSave.application === '') ||
			(this.pswPartDetailSave.custConcernReport === undefined || this.pswPartDetailSave.custConcernReport === null
				|| this.pswPartDetailSave.custConcernReport === '') ||
			(this.pswPartDetailSave.imds === undefined || this.pswPartDetailSave.imds === null
				|| this.pswPartDetailSave.imds === '') ||
			(this.pswPartDetailSave.polymerPartsISO === undefined || this.pswPartDetailSave.polymerPartsISO === null
				|| this.pswPartDetailSave.polymerPartsISO === '') ||
			(this.pswPartDetailSave.submissionReason === undefined || this.pswPartDetailSave.submissionReason === null
				|| this.pswPartDetailSave.submissionReason === '') ||
			(this.pswPartDetailSave.submitResultFor === undefined || this.pswPartDetailSave.submitResultFor === null
				|| this.pswPartDetailSave.submitResultFor === '') ||
			(this.pswPartDetailSave.meetDesignReq === undefined || this.pswPartDetailSave.meetDesignReq === null
				|| this.pswPartDetailSave.meetDesignReq === '') ||
			(this.pswPartDetailSave.moldCavityProdProc === undefined || this.pswPartDetailSave.moldCavityProdProc === null
				|| this.pswPartDetailSave.moldCavityProdProc === '') ||
			(this.pswPartDetailSave.paReqSource === undefined || this.pswPartDetailSave.paReqSource === null
				|| this.pswPartDetailSave.paReqSource === '') ||
			(this.pswPartDetailSave.paReqSrcDetail === undefined || this.pswPartDetailSave.paReqSrcDetail === null
				|| this.pswPartDetailSave.paReqSrcDetail === '' || this.pswPartDetailSave.paReqSrcDetail.length <= 0) ||
			(this.pswPartDetailSave.paReqSrcDate === undefined || this.pswPartDetailSave.paReqSrcDate === null) ||
			(this.pswPartDetailSave.paReqAPW === undefined || this.pswPartDetailSave.paReqAPW === null
			|| this.pswPartDetailSave.paReqAPW === 0 ? true : (this.pswPartDetailSave.paReqAPW > this.pswPartDetailSave.paReqMPW)) ||
			(this.pswPartDetailSave.paReqMPW === undefined || this.pswPartDetailSave.paReqMPW === null
			|| this.pswPartDetailSave.paReqMPW === 0 ? true : (this.pswPartDetailSave.paReqAPW > this.pswPartDetailSave.paReqMPW)) ||
			(this.pswPartDetailSave.demonCpctyAPPC === undefined || this.pswPartDetailSave.demonCpctyAPPC === null
			|| this.pswPartDetailSave.demonCpctyAPPC === 0 ? true : (this.pswPartDetailSave.demonCpctyAPPC > this.pswPartDetailSave.demonCpctyMPPC)) ||
			(this.pswPartDetailSave.demonCpctyMPPC === undefined || this.pswPartDetailSave.demonCpctyMPPC === null
			|| this.pswPartDetailSave.demonCpctyMPPC === 0 ? true : (this.pswPartDetailSave.demonCpctyAPPC > this.pswPartDetailSave.demonCpctyMPPC)) ||
			(this.pswPartDetailSave.demonCpctyDate === undefined || this.pswPartDetailSave.demonCpctyDate === null
				|| this.pswPartDetailSave.demonCpctyDate === '') ||
			(this.pswPartDetailSave.aftrPaReqSource !== undefined && this.pswPartDetailSave.aftrPaReqSource !== null
				&& this.pswPartDetailSave.aftrPaReqSource !== '') ||
			(this.pswPartDetailSave.aftrPaReqSrcDetail !== undefined && this.pswPartDetailSave.aftrPaReqSrcDetail !== null
				&& this.pswPartDetailSave.aftrPaReqSrcDetail.length > 0) ||
			(this.pswPartDetailSave.aftrPaReqSrcDate !== undefined && this.pswPartDetailSave.aftrPaReqSrcDate !== null &&
				this.pswPartDetailSave.aftrPaReqSrcDate.toString() !== '') ||
			(this.pswPartDetailSave.aftrPaReqAPW !== undefined && this.pswPartDetailSave.aftrPaReqAPW !== null
				&& this.pswPartDetailSave.aftrPaReqAPW !== 0) ||
			(this.pswPartDetailSave.aftrPaReqMPW !== undefined && this.pswPartDetailSave.aftrPaReqMPW !== null
				&& this.pswPartDetailSave.aftrPaReqMPW !== 0) ||
			(this.pswPartDetailSave.aftrPaReqMetDate !== undefined && this.pswPartDetailSave.aftrPaReqMetDate !== null &&
				this.pswPartDetailSave.aftrPaReqMetDate.toString() !== '') ||
			(this.pswPartDetailSave.prodRate === undefined || this.pswPartDetailSave.prodRate === null
				|| this.pswPartDetailSave.prodRate === '') ||
			(this.pswPartDetailSave.prodHours === undefined || this.pswPartDetailSave.prodHours === null
				|| this.pswPartDetailSave.prodHours === '') ||
			(this.pswPartDetailSave.prodStreams === undefined || this.pswPartDetailSave.prodStreams === null
				|| this.pswPartDetailSave.prodStreams === '') ||
			(this.pswPartDetailSave.comments === undefined || this.pswPartDetailSave.comments === null
				|| this.pswPartDetailSave.comments === '') ||
			(this.pswPartDetailSave.orgAuthSign === undefined || this.pswPartDetailSave.orgAuthSign === null
				|| this.pswPartDetailSave.orgAuthSign === '') ||
			(this.pswPartDetailSave.authPrintName === undefined || this.pswPartDetailSave.authPrintName === null
				|| this.pswPartDetailSave.authPrintName === '') ||
			(this.pswPartDetailSave.authSignDate === undefined || this.pswPartDetailSave.authSignDate === null) ||
			(this.pswPartDetailSave.authTitle === undefined || this.pswPartDetailSave.authTitle === null
				|| this.pswPartDetailSave.authTitle === '') ||
			(this.pswPartDetailSave.authPhone === undefined || this.pswPartDetailSave.authPhone === null
				|| this.pswPartDetailSave.authPhone === '') ||
			(this.pswPartDetailSave.authEmail === undefined || this.pswPartDetailSave.authEmail === null
				|| this.pswPartDetailSave.authEmail === '') ||
			(this.pswPartDetailSave.custToolTagNumber === undefined || this.pswPartDetailSave.custToolTagNumber === null
				|| this.pswPartDetailSave.custToolTagNumber === '')
			// || (this.pswPartDetailSave.nextAuditPlan === undefined || this.pswPartDetailSave.nextAuditPlan === null
			// 	|| this.pswPartDetailSave.nextAuditPlan === '')
		) {
			//isValid = this.showInvalidErrorMsg();
			this.isValidSave = false;
			/*this.confirmationService.confirm({
				message: 'One or more mandatory fields is blank .Data must be entered in order to sign ' +
					'and submit the psw form.Are you sure you want to\n' +
					'proceed with saving the current data?',
				header: 'Confirmation',
				icon: 'pi pi-info-circle',
				accept: () => {
					//isValidSave = true;
					//this.onSave();
					//this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId]);
					return isValidSave;
				},
				reject: () => {
				}
			});*/
			//return isValid;
		} else {
			console.log('2', this.isValidSave);
			if (this.isOtherTextVisible === true) {
				if (this.pswPartDetailSave.otherReason === undefined ||
					this.pswPartDetailSave.otherReason === null || this.pswPartDetailSave.otherReason === '') {
					this.isOtherTextSave = false;
				} else {
					this.isOtherTextSave = true;
					if (this.isDesignExplnVisible === true) {
						if (this.pswPartDetailSave.designReqExpln === undefined ||
							this.pswPartDetailSave.designReqExpln === null || this.pswPartDetailSave.designReqExpln === '') {
							this.isDesignTextSave = false;
						} else {
							this.isDesignTextSave = true;
						}
					} else {
						this.isDesignTextSave = true;
					}
				}
			} else {
				this.isOtherTextSave = true;
				if (this.isDesignExplnVisible === true) {
					if (this.pswPartDetailSave.designReqExpln === undefined ||
						this.pswPartDetailSave.designReqExpln === null || this.pswPartDetailSave.designReqExpln === '') {
						this.isDesignTextSave = false;
					} else {
						this.isDesignTextSave = true;
					}
				}
				// if (this.pswApproverAccessControl.get('staApprovalConditionsEnabled')) {
				// 	if (this.overallStatus === 'AWAITING APPROVAL' || this.overallStatus === 'LEVEL 1 SELF APPROVED') {
				// 		if (this.pswStaStatus !== '' && this.pswStaStatus !== null) {
				// 			this.needApproverAction = false;
				// 		} else {
				// 			this.needApproverAction = true;
				// 		}
				// 	}
				// } else {
				// 	if (this.overallStatus === 'AWAITING APPROVAL' || this.overallStatus === 'LEVEL 1 SELF APPROVED') {
				// 		if (this.pswPdStatus !== '' && this.pswPdStatus !== null) {
				// 			this.needApproverAction = false;
				// 		} else {
				// 			this.needApproverAction = true;
				// 		}
				// 	}
				// }
			}
			if (this.isShownMandatory === true) {
				if ((this.pswPartDetailSave.engAuthAlert === undefined || this.pswPartDetailSave.engAuthAlert === null
						|| this.pswPartDetailSave.engAuthAlert === '') ||
					(this.pswPartDetailSave.engAuthDesc === undefined || this.pswPartDetailSave.engAuthDesc === null
						|| this.pswPartDetailSave.engAuthDesc === '')) {
					// || (this.pswPartDetailSave.engAuthIncomplPpapReq === undefined || this.pswPartDetailSave.engAuthIncomplPpapReq === null
					// 	|| this.pswPartDetailSave.engAuthIncomplPpapReq === '')
					this.isInterimTextSave = false;
				} else {
					this.isInterimTextSave = true;
				}
			}
			// if (this.isOtherTextVisible === false && this.isDesignExplnVisible === false && this.isShownMandatory === false) {
			// 	this.isValidSave = true;
			// }
			if (Array.isArray(this.pswPartDetailSave.submissionReason) && this.pswPartDetailSave.submissionReason.length !== 0) {
				let i;
				let j = 0;
				for (i = 0; i < this.pswPartDetailSave.submissionReason.length; i++) {
					if (this.pswPartDetailSave.submissionReason[i].length === 0) {
						j++;
					}
					if (this.pswPartDetailSave.submissionReason.length === j) {
						this.isReasonSave = false;
						// this.sectionList.push('SECTION 5:REASON FOR SUBMISSION (Check at least one)');
					}
				}
			} else {
				this.isReasonSave = false;
			}
			if (Array.isArray(this.pswPartDetailSave.submitResultFor) && this.pswPartDetailSave.submitResultFor.length !== 0) {
				let i;
				let j = 0;
				for (i = 0; i < this.pswPartDetailSave.submitResultFor.length; i++) {
					if (this.pswPartDetailSave.submitResultFor[i].length === 0) {
						j++;
					}
					if (this.pswPartDetailSave.submitResultFor.length === j) {
						this.isSubmissionResultSave = false;
						// this.sectionList.push('SECTION 5:REASON FOR SUBMISSION (Check at least one)');
					}
				}
			} else {
				this.isSubmissionResultSave = false;
			}
			console.log('PPAP_PARTS_Response_3: ', this.ppapPartsArray, '\nSIZE_3: ', this.ppapPartsArray.length);
			a: for (const item of this.ppapPartsArray) {
				if ((item.familyPswOrgPartNumber === null || item.familyPswOrgPartNumber === undefined || item.familyPswOrgPartNumber === '')
					|| (item.familyPswPoNumber === null || item.familyPswPoNumber === undefined || item.familyPswPoNumber === '')
					|| (item.familyPswWeight === null || item.familyPswWeight === undefined || item.familyPswWeight === '')
					|| (item.familyPswIMDSIDNo === null || item.familyPswIMDSIDNo === undefined || item.familyPswIMDSIDNo === '')) {
					this.isAnnexureSave = false;
					break a;
				} else {
					this.isAnnexureSave = true;
				}
			}
			if (this.actionMode === 'familyNew')
				if (this.ppapPartsArray.length === 0)
					this.isAnnexureSave = false;
			console.log('PPAP_PARTS_Response_3: ', this.ppapPartsArray, '\nSIZE_3: ', this.ppapPartsArray.length);

			if (this.isOtherTextSave === false || this.isDesignTextSave === false || this.isInterimTextSave === false || this.isReasonSave === false
				|| this.isSubmissionResultSave === false || this.isAnnexureSave === false) {
				this.isValidSave = false;
			} else {
				this.isValidSave = true;
			}
		}
		return this.isValidSave;
	}

	private validatePhaseData() {
		console.log('1', this.isValidSave);
		if ((this.pswPartDetailSave.custPartName === undefined || this.pswPartDetailSave.custPartName === null
				|| this.pswPartDetailSave.custPartName === '') ||
			(this.pswPartDetailSave.custPartNumber === undefined || this.pswPartDetailSave.custPartNumber === null
				|| this.pswPartDetailSave.custPartNumber === '') ||
			(this.pswPartDetailSave.engChangeLvl === undefined || this.pswPartDetailSave.engChangeLvl === null
				|| this.pswPartDetailSave.engChangeLvl === '') ||
			(this.pswPartDetailSave.engChangeLvlDate === undefined || this.pswPartDetailSave.engChangeLvlDate === null
				|| this.pswPartDetailSave.engChangeLvlDate === '') ||
			(this.pswPartDetailSave.drawingNumber === undefined || this.pswPartDetailSave.drawingNumber === null
				|| this.pswPartDetailSave.drawingNumber === '') ||
			(this.pswPartDetailSave.orgPartNumber === undefined || this.pswPartDetailSave.orgPartNumber === null
				|| this.pswPartDetailSave.orgPartNumber === '') ||
			(this.pswPartDetailSave.addEngChanges === undefined || this.pswPartDetailSave.addEngChanges === null
				|| this.pswPartDetailSave.addEngChanges === '') ||
			(this.pswPartDetailSave.addEngChangesDate === undefined || this.pswPartDetailSave.addEngChangesDate === null
				|| this.pswPartDetailSave.addEngChangesDate === '') ||
			(this.pswPartDetailSave.safetyRegulations === undefined || this.pswPartDetailSave.safetyRegulations === null
				|| this.pswPartDetailSave.safetyRegulations === '') ||
			(this.pswPartDetailSave.poNumber === undefined || this.pswPartDetailSave.poNumber === null
				|| this.pswPartDetailSave.poNumber === '') ||
			(this.pswPartDetailSave.weight === undefined || this.pswPartDetailSave.weight === null
				|| this.pswPartDetailSave.weight === '') ||
			(this.pswPartDetailSave.checkAidNumber === undefined || this.pswPartDetailSave.checkAidNumber === null
				|| this.pswPartDetailSave.checkAidNumber === '') ||
			(this.pswPartDetailSave.checkAidEngChangeLvl === undefined || this.pswPartDetailSave.checkAidEngChangeLvl === null
				|| this.pswPartDetailSave.checkAidEngChangeLvl === '') ||
			(this.pswPartDetailSave.checkAidEngChangeLvlDate === undefined || this.pswPartDetailSave.checkAidEngChangeLvlDate === null
				|| this.pswPartDetailSave.checkAidEngChangeLvlDate === '') ||
			(this.pswPartDetailSave.orgName === undefined || this.pswPartDetailSave.orgName === null
				|| this.pswPartDetailSave.orgName === '') ||
			(this.pswPartDetailSave.supplierCode === undefined || this.pswPartDetailSave.supplierCode === null
				|| this.pswPartDetailSave.supplierCode === '') ||
			(this.pswPartDetailSave.street === undefined || this.pswPartDetailSave.street === null
				|| this.pswPartDetailSave.street === '') ||
			(this.pswPartDetailSave.city === undefined || this.pswPartDetailSave.city === null
				|| this.pswPartDetailSave.city === '') ||
			(this.pswPartDetailSave.state === undefined || this.pswPartDetailSave.state === null
				|| this.pswPartDetailSave.state === '') ||
			(this.pswPartDetailSave.postalCode === undefined || this.pswPartDetailSave.postalCode === null
				|| this.pswPartDetailSave.postalCode === '') ||
			(this.pswPartDetailSave.country === undefined || this.pswPartDetailSave.country === null
				|| this.pswPartDetailSave.country === '') ||
			(this.pswPartDetailSave.custDivision === undefined || this.pswPartDetailSave.custDivision === null
				|| this.pswPartDetailSave.custDivision === '') ||
			(this.pswPartDetailSave.buyerCode === undefined || this.pswPartDetailSave.buyerCode === null
				|| this.pswPartDetailSave.buyerCode === '') ||
			(this.pswPartDetailSave.application === undefined || this.pswPartDetailSave.application === null
				|| this.pswPartDetailSave.application === '') ||
			(this.pswPartDetailSave.custConcernReport === undefined || this.pswPartDetailSave.custConcernReport === null
				|| this.pswPartDetailSave.custConcernReport === '') ||
			(this.pswPartDetailSave.imds === undefined || this.pswPartDetailSave.imds === null
				|| this.pswPartDetailSave.imds === '') ||
			(this.pswPartDetailSave.polymerPartsISO === undefined || this.pswPartDetailSave.polymerPartsISO === null
				|| this.pswPartDetailSave.polymerPartsISO === '') ||
			(this.pswPartDetailSave.submissionReason === undefined || this.pswPartDetailSave.submissionReason === null
				|| this.pswPartDetailSave.submissionReason === '') ||
			(this.pswPartDetailSave.submitResultFor === undefined || this.pswPartDetailSave.submitResultFor === null
				|| this.pswPartDetailSave.submitResultFor === '') ||
			(this.pswPartDetailSave.meetDesignReq === undefined || this.pswPartDetailSave.meetDesignReq === null
				|| this.pswPartDetailSave.meetDesignReq === '') ||
			(this.pswPartDetailSave.moldCavityProdProc === undefined || this.pswPartDetailSave.moldCavityProdProc === null
				|| this.pswPartDetailSave.moldCavityProdProc === '') ||
			(this.pswPartDetailSave.paReqSource === undefined || this.pswPartDetailSave.paReqSource === null
				|| this.pswPartDetailSave.paReqSource === '') ||
			(this.pswPartDetailSave.paReqSrcDetail === undefined || this.pswPartDetailSave.paReqSrcDetail === null
				|| this.pswPartDetailSave.paReqSrcDetail === '' || this.pswPartDetailSave.paReqSrcDetail.length <= 0) ||
			(this.pswPartDetailSave.paReqSrcDate === undefined || this.pswPartDetailSave.paReqSrcDate === null
				|| this.pswPartDetailSave.paReqSrcDate === '') ||
			(this.pswPartDetailSave.paReqAPW === undefined || this.pswPartDetailSave.paReqAPW === null
			|| this.pswPartDetailSave.paReqAPW === 0 ? true : (this.pswPartDetailSave.paReqAPW > this.pswPartDetailSave.paReqMPW)) ||
			(this.pswPartDetailSave.paReqMPW === undefined || this.pswPartDetailSave.paReqMPW === null
			|| this.pswPartDetailSave.paReqMPW === 0 ? true : (this.pswPartDetailSave.paReqAPW > this.pswPartDetailSave.paReqMPW)) ||
			(this.pswPartDetailSave.demonCpctyAPPC === undefined || this.pswPartDetailSave.demonCpctyAPPC === null
			|| this.pswPartDetailSave.demonCpctyAPPC === 0 ? true : (this.pswPartDetailSave.demonCpctyAPPC > this.pswPartDetailSave.demonCpctyMPPC)) ||
			(this.pswPartDetailSave.demonCpctyMPPC === undefined || this.pswPartDetailSave.demonCpctyMPPC === null
			|| this.pswPartDetailSave.demonCpctyMPPC === 0 ? true : (this.pswPartDetailSave.demonCpctyAPPC > this.pswPartDetailSave.demonCpctyMPPC)) ||
			(this.pswPartDetailSave.demonCpctyDate === undefined || this.pswPartDetailSave.demonCpctyDate === null
				|| this.pswPartDetailSave.demonCpctyDate === '') ||
			(this.pswPartDetailSave.aftrPaReqSource !== undefined && this.pswPartDetailSave.aftrPaReqSource !== null
				&& this.pswPartDetailSave.aftrPaReqSource !== '') ||
			(this.pswPartDetailSave.aftrPaReqSrcDetail !== undefined && this.pswPartDetailSave.aftrPaReqSrcDetail !== null
				&& this.pswPartDetailSave.aftrPaReqSrcDetail.length > 0) ||
			(this.pswPartDetailSave.aftrPaReqSrcDate !== undefined && this.pswPartDetailSave.aftrPaReqSrcDate !== null &&
				this.pswPartDetailSave.aftrPaReqSrcDate.toString() !== '') ||
			(this.pswPartDetailSave.aftrPaReqAPW !== undefined && this.pswPartDetailSave.aftrPaReqAPW !== null
				&& this.pswPartDetailSave.aftrPaReqAPW !== 0) ||
			(this.pswPartDetailSave.aftrPaReqMPW !== undefined && this.pswPartDetailSave.aftrPaReqMPW !== null
				&& this.pswPartDetailSave.aftrPaReqMPW !== 0) ||
			(this.pswPartDetailSave.aftrPaReqMetDate !== undefined && this.pswPartDetailSave.aftrPaReqMetDate !== null &&
				this.pswPartDetailSave.aftrPaReqMetDate.toString() !== '') ||
			(this.pswPartDetailSave.prodRate === undefined || this.pswPartDetailSave.prodRate === null
				|| this.pswPartDetailSave.prodRate === '') ||
			(this.pswPartDetailSave.prodHours === undefined || this.pswPartDetailSave.prodHours === null
				|| this.pswPartDetailSave.prodHours === '') ||
			(this.pswPartDetailSave.prodStreams === undefined || this.pswPartDetailSave.prodStreams === null
				|| this.pswPartDetailSave.prodStreams === '') ||
			(this.pswPartDetailSave.comments === undefined || this.pswPartDetailSave.comments === null
				|| this.pswPartDetailSave.comments === '') ||
			(this.pswPartDetailSave.orgAuthSign === undefined || this.pswPartDetailSave.orgAuthSign === null
				|| this.pswPartDetailSave.orgAuthSign === '') ||
			(this.pswPartDetailSave.authPrintName === undefined || this.pswPartDetailSave.authPrintName === null
				|| this.pswPartDetailSave.authPrintName === '') ||
			(this.pswPartDetailSave.authSignDate === undefined || this.pswPartDetailSave.authSignDate === null) ||
			(this.pswPartDetailSave.authTitle === undefined || this.pswPartDetailSave.authTitle === null
				|| this.pswPartDetailSave.authTitle === '') ||
			(this.pswPartDetailSave.authPhone === undefined || this.pswPartDetailSave.authPhone === null
				|| this.pswPartDetailSave.authPhone === '') ||
			(this.pswPartDetailSave.authEmail === undefined || this.pswPartDetailSave.authEmail === null
				|| this.pswPartDetailSave.authEmail === '') ||
			(this.pswPartDetailSave.custToolTagNumber === undefined || this.pswPartDetailSave.custToolTagNumber === null
				|| this.pswPartDetailSave.custToolTagNumber === '') ||
			(this.phaseType === 'Phase1' && (this.selectedPhaseValue === undefined || this.selectedPhaseValue === null))) {
			//isValid = this.showInvalidErrorMsg();
			this.isValidSave = false;
			/*this.confirmationService.confirm({
				message: 'One or more mandatory fields is blank .Data must be entered in order to sign ' +
					'and submit the psw form.Are you sure you want to\n' +
					'proceed with saving the current data?',
				header: 'Confirmation',
				icon: 'pi pi-info-circle',
				accept: () => {
					//isValidSave = true;
					//this.onSave();
					//this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId]);
					return isValidSave;
				},
				reject: () => {
				}
			});*/
			//return isValid;
		} else {
			console.log('2', this.isValidSave);
			if (this.isOtherTextVisible === true) {
				if (this.pswPartDetailSave.otherReason === undefined ||
					this.pswPartDetailSave.otherReason === null || this.pswPartDetailSave.otherReason === '') {
					this.isOtherTextSave = false;
				} else {
					this.isOtherTextSave = true;
					if (this.isDesignExplnVisible === true) {
						if (this.pswPartDetailSave.designReqExpln === undefined ||
							this.pswPartDetailSave.designReqExpln === null || this.pswPartDetailSave.designReqExpln === '') {
							this.isDesignTextSave = false;
						} else {
							this.isDesignTextSave = true;
						}
					} else {
						this.isDesignTextSave = true;
					}
				}
			} else {
				this.isOtherTextSave = true;
				if (this.isDesignExplnVisible === true) {
					if (this.pswPartDetailSave.designReqExpln === undefined ||
						this.pswPartDetailSave.designReqExpln === null || this.pswPartDetailSave.designReqExpln === '') {
						this.isDesignTextSave = false;
					} else {
						this.isDesignTextSave = true;
					}
				}
			}
			if (this.isShownMandatory === true) {
				if ((this.pswPartDetailSave.engAuthAlert === undefined || this.pswPartDetailSave.engAuthAlert === null
						|| this.pswPartDetailSave.engAuthAlert === '') ||
					(this.pswPartDetailSave.engAuthDesc === undefined || this.pswPartDetailSave.engAuthDesc === null
						|| this.pswPartDetailSave.engAuthDesc === '')) {
					// ||
					// (this.pswPartDetailSave.engAuthIncomplPpapReq === undefined || this.pswPartDetailSave.engAuthIncomplPpapReq === null
					// 	|| this.pswPartDetailSave.engAuthIncomplPpapReq === '')) {
					this.isInterimTextSave = false;
				} else {
					this.isInterimTextSave = true;
				}
			}
			// if (this.isOtherTextVisible === false && this.isDesignExplnVisible === false && this.isShownMandatory === false) {
			// 	this.isValidSave = true;
			// }
			if (Array.isArray(this.pswPartDetailSave.submissionReason) && this.pswPartDetailSave.submissionReason.length !== 0) {
				let i;
				let j = 0;
				for (i = 0; i < this.pswPartDetailSave.submissionReason.length; i++) {
					if (this.pswPartDetailSave.submissionReason[i].length === 0) {
						j++;
					}
					if (this.pswPartDetailSave.submissionReason.length === j) {
						this.isReasonSave = false;
						// this.sectionList.push('SECTION 5:REASON FOR SUBMISSION (Check at least one)');
					}
				}
			} else {
				this.isReasonSave = false;
			}
			if (Array.isArray(this.pswPartDetailSave.submitResultFor) && this.pswPartDetailSave.submitResultFor.length !== 0) {
				let i;
				let j = 0;
				for (i = 0; i < this.pswPartDetailSave.submitResultFor.length; i++) {
					if (this.pswPartDetailSave.submitResultFor[i].length === 0) {
						j++;
					}
					if (this.pswPartDetailSave.submitResultFor.length === j) {
						this.isSubmissionResultSave = false;
						// this.sectionList.push('SECTION 5:REASON FOR SUBMISSION (Check at least one)');
					}
				}
			} else {
				this.isSubmissionResultSave = false;
			}
			console.log('PPAP_PARTS_Response_4: ', this.ppapPartsArray, '\nSIZE_4: ', this.ppapPartsArray.length);
			a: for (const item of this.ppapPartsArray) {
				if ((item.familyPswOrgPartNumber === null || item.familyPswOrgPartNumber === undefined || item.familyPswOrgPartNumber === '')
					|| (item.familyPswPoNumber === null || item.familyPswPoNumber === undefined || item.familyPswPoNumber === '')
					|| (item.familyPswWeight === null || item.familyPswWeight === undefined || item.familyPswWeight === '')
					|| (item.familyPswIMDSIDNo === null || item.familyPswIMDSIDNo === undefined || item.familyPswIMDSIDNo === '')) {
					this.isAnnexureSave = false;
					break a;
				} else {
					this.isAnnexureSave = true;
				}
			}
			if (this.actionMode === 'familyNew')
				if (this.ppapPartsArray.length === 0)
					this.isAnnexureSave = false;

			if (this.isOtherTextSave === false || this.isDesignTextSave === false || this.isInterimTextSave === false || this.isReasonSave === false
				|| this.isSubmissionResultSave === false || this.isAnnexureSave === false) {
				this.isValidSave = false;
			} else {
				this.isValidSave = true;
			}
		}
		return this.isValidSave;
	}

	submitPage() {
		this.onSave(false);
	}

	checkPpapRequiredElement(messageExists: boolean) {
		this.requiredElementsNotSaved = false;
		this.digitalPpapPackageService.getPpapElementRequired(this.ppapPackageIdentifierId).subscribe(repo => {
			console.log('Ppap Required Check Data for Sumbit: ' + repo);
			if (repo.length > 0) {
				this.digitalPpapPackageService.getPackagePpapElementSubmitCheck(this.ppapPackageIdentifierId).subscribe(repos => {
						console.log('Ppap Required Response Check Data for Submit: ' + repos);
						if (repos.length === 1) {
							messageExists = true;
							const msg = 'Please fill the following PPAP Required Element ' + repos.toString().split(',').join('\r\n');
							this.messageService.add({severity: 'error', summary: msg, life: 5000});
						}
						if (repos.length > 1) {
							messageExists = true;
							const msg = 'Please fill the following PPAP Required Elements ' + repos.toString().split(',').join('\r\n');
							this.messageService.add({severity: 'error', summary: msg, life: 5000});
						}
						if (!messageExists) {
							this.savePartsStatus(messageExists);
						}
					}, error => {
						console.log('Error Log ' + error);
					}
				);
			} else if (repo.length <= 0) {
				this.requiredElementsNotSaved = true;
				if (!messageExists) {
					this.savePartsStatus(messageExists);
				}
				// this.messageService.add({severity: 'error',
				// summary: 'Please Complete PPAP Required Element and Response in PPAP Package', life: 5000});
			}
		}, error => {
			console.log('Error Log ' + error);
		});
	}

	savePartsStatus(messageExists: boolean) {
		// tslint:disable-next-line:prefer-const
		let statusList: any[] = [], formCompleted: boolean;
		let messageExistsFromPPAPPackage = false;
		if (this.cdsidNotSaved && this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			if (this.ppapLevel !== '1') {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Parallel Approver CDSID and Required Elements in PPAP Package',
					life: 8000
				});
			} else {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Parallel Approver CDSID in PPAP Package',
					life: 8000
				});
			}
		} else if (this.cdsidNotSaved && !this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			this.messageService.add({
				severity: 'error',
				summary: 'Please save the Parallel Approver CDSID in PPAP Package',
				life: 6000
			});
		} else if (!this.cdsidNotSaved && this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			if (this.ppapLevel !== '1') {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Required Elements in PPAP Package',
					life: 6000
				});
			}
		} else if (this.cdsidNotSaved && this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			if (this.ppapLevel !== '1') {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Parallel Approver CDSID and Required Elements in PPAP Package',
					life: 6000
				});
			} else {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Parallel Approver CDSID in PPAP Package',
					life: 6000
				});
			}
		} else if (this.cdsidNotSaved && !this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			this.messageService.add({
				severity: 'error',
				summary: 'Please save the Parallel Approver CDSID in PPAP Package',
				life: 4000
			});
		} else if (!this.cdsidNotSaved && this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			if (this.ppapLevel !== '1') {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Required Elements in PPAP Package',
					life: 4000
				});
			}
		}
		if (!messageExistsFromPPAPPackage) {
			if (this.tier2Saved) {
				messageExistsFromPPAPPackage = true;
				this.messageService.add({
					severity: 'info',
					summary: 'Please Upload Excel Tier 2 Details Of Parts in PPAP Package',
					life: 4000
				});
			} else {
				this.digitalPpapPackageService.findAllPpapAssessmentResponse(this.ppapPackageIdentifierId).subscribe(ppapAssessmentResponse => {
					this.ppapAssessmentResponse = ppapAssessmentResponse;
					for (const data of this.partNumberDetail) {
						if (this.ppapPackagePartsEntityId === data.ppapPackagePartsEntityID) {
							if (data.isFormCompleted === 'false') {
								formCompleted = false;
								break;
							} else {
								formCompleted = true;
							}
						}
					}
					if (this.pswAccessControl.get('displayPpapPackageNextBtn')) {
						if (!formCompleted) {
							messageExists = true;
							this.messageService.add({
								severity: 'error',
								summary: 'Please fill all required fields in ePsw form',
								life: 4000
							});
						}
						if (!messageExists) {
							// for (let data of this.partNumberDetail) {
							// 	if (data.status === 'IN PROGRESS') {
							// 		data.status = 'AWAITING APPROVAL';
							// 	} else if (data.status !== 'AWAITING APPROVAL') {
							// 		statusList.push(data.status)
							// 	}
							// }
							this.confirmationService.confirm({
								message: 'Ensure all required PPAP evidences are uploaded as per Ford Phased PPAP Requirements. Are you sure to submit now?',
								header: 'Submit Confirmation',
								icon: 'fa fa-info-circle',
								accept: () => {
									// if (statusList.length === 0) {
									for (const data of this.partNumberDetail) {
										if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
											data.ppapStaStatus = '';
											// data.ppapStaComments = '';
											data.ppapStaStatusOn = null;
											data.ppapStaStatusBy = '';
											data.ppapPdStatus = '';
											// data.ppapPdComments = '';
											data.ppapPdStatusOn = null;
											data.staApprovalRequired = 'true';
											//data.staApprovalRequired = '';
											data.ppapPdStatusBy = '';
											data.pswStaStatus = '';
											// data.pswStaComments = '';
											data.pswStaStatusOn = null;
											data.pswStaStatusBy = '';
											data.pswPdStatus = '';
											// data.pswPdComments = '';
											data.pswPdStatusOn = null;
											data.pswPdStatusBy = '';
											data.submittedBy = sessionStorage.userName;
											data.submittedOn = new Date();
											data.submittedCdsid = sessionStorage.userId.toLocaleUpperCase();
											data.submittedEmailId = sessionStorage.userMailId;
											/*if (this.ppapLevel !== '1') {
												// data.overallPpapStatus = 'AWAITING APPROVAL';
												data.overallPswStatus = 'AWAITING APPROVAL';
												data.status = 'AWAITING APPROVAL';
											} else {
												// data.overallPpapStatus = 'LEVEL 1 SELF APPROVED';
												data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
												data.status = 'LEVEL 1 SELF APPROVED';
											}*/
											this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
												|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
											if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
												(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
												data.overallPswStatus = 'AWAITING APPROVAL';
												data.status = 'AWAITING APPROVAL';
											} else {
												if (this.ppapLevel === '1') {
													if (this.partNumberDetail[0].staApprovalRequired === 'true') {
														data.overallPswStatus = 'AWAITING APPROVAL';
														data.status = 'AWAITING APPROVAL';
													} else {
														data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
														data.status = 'LEVEL 1 SELF APPROVED';
													}
												} else {
													data.overallPswStatus = 'AWAITING APPROVAL';
													data.status = 'AWAITING APPROVAL';
												}
											}
										} else {
											if (data.status.includes('AWAITING APPROVAL')) {
												this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
													|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
												if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
													(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
													data.status = 'AWAITING APPROVAL';
												} else {
													if (this.ppapLevel === '1') {
														if (this.partNumberDetail[0].staApprovalRequired === 'true') {
															data.status = 'AWAITING APPROVAL';
														} else {
															data.status = 'LEVEL 1 SELF APPROVED';
														}
													} else {
														data.status = 'AWAITING APPROVAL';
													}
												}
											}
											/*if (this.ppapLevel !== '1') {
												if(data.status.includes('AWAITING APPROVAL')){
													data.status = 'AWAITING APPROVAL';
												}
											}*/
										}
										if (Number(this.familyPswId) !== 0) {
											if (data.familyPswId === Number(this.familyPswId)) {
												data.ppapStaStatus = '';
												// data.ppapStaComments = '';
												data.ppapStaStatusOn = null;
												data.ppapStaStatusBy = '';
												data.ppapPdStatus = '';
												// data.ppapPdComments = '';
												data.ppapPdStatusOn = null;
												data.ppapPdStatusBy = '';
												data.pswStaStatus = '';
												// data.pswStaComments = '';
												data.pswStaStatusOn = null;
												data.pswStaStatusBy = '';
												data.pswPdStatus = '';
												// data.pswPdComments = '';
												data.pswPdStatusOn = null;
												data.pswPdStatusBy = '';
												data.submittedBy = sessionStorage.userName;
												data.submittedOn = new Date();
												data.submittedCdsid = sessionStorage.userId.toLocaleUpperCase();
												data.submittedEmailId = sessionStorage.userMailId;
												/*if (this.ppapLevel !== '1') {
													// data.overallPpapStatus = 'AWAITING APPROVAL';
													data.overallPswStatus = 'AWAITING APPROVAL';
													data.status = 'AWAITING APPROVAL';
												} else {
													// data.overallPpapStatus = 'LEVEL 1 SELF APPROVED';
													data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
													data.status = 'LEVEL 1 SELF APPROVED';
												}*/
												this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
													|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
												if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
													(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid !==null && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
													data.overallPswStatus = 'AWAITING APPROVAL';
													data.status = 'AWAITING APPROVAL';
												} else {
													if (this.ppapLevel === '1') {
														if (this.partNumberDetail[0].staApprovalRequired === 'true') {
															data.overallPswStatus = 'AWAITING APPROVAL';
															data.status = 'AWAITING APPROVAL';
														} else {
															data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
															data.status = 'LEVEL 1 SELF APPROVED';
														}
													} else {
														data.overallPswStatus = 'AWAITING APPROVAL';
														data.status = 'AWAITING APPROVAL';
													}
												}
											} else {
												if (data.status.includes('AWAITING APPROVAL')) {
													this.ppapPackageIdentifierFromSupplier.pdApprovalRequired = ((this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1'
														|| this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === true) ? '1' : '0');
													if (this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '1' ? true : false ||
														(this.ppapLevel !== '1' && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid && this.ppapPackageIdentifierFromSupplier.pdApproverCdsid.length > 0)) {
														data.status = 'AWAITING APPROVAL';
													} else {
														if (this.ppapLevel === '1') {
															if (this.partNumberDetail[0].staApprovalRequired === 'true') {
																data.status = 'AWAITING APPROVAL';
															} else {
																data.status = 'LEVEL 1 SELF APPROVED';
															}
														} else {
															data.status = 'AWAITING APPROVAL';
														}
													}
												}
											}
										}
									}
									// for (let data of this.ppapAssessmentResponse) {
									// 	data.staPdStatus = null;
									// 	data.staPdStatusOn = null;
									// 	data.staPdStatusBy = null;
									// 	data.staPdRemark = null;
									// }
									if (this.status === 'IN PROGRESS') {
										this.isSubmitted = true;
									}
									this.digitalPpapPackageService.savePartsStatus(this.partNumberDetail, this.isSubmitted, false).subscribe(response => {
										this.digitalPpapPackageService.savePpapElementRecords(this.ppapAssessmentResponse).subscribe(resp => {
											this.router.navigate(['/home-page']);
											this.messageService.add({
												severity: 'success',
												summary: 'Submitted Successfully',
												life: 3000
											});
										});
									});
									// }
								},
								reject: () => {
								}
							});
						}
					}
				});
			}
		}
	}

	getFormCompletionStatus() {
		console.log('Part Number - getFormCompletionStatus  = opportunity 1');
		this.digitalService.getAllData(this.ppapPackageIdentifierId).subscribe(resp => {
			console.log('Part Number - getAllData - network call completed - setting part number ');
			this.partNumberDetail = resp.listOfPpapPackageParts;
			console.log(this.partNumberDetail)
			for (const data of this.partNumberDetail) {
				this.supplierViewService.getRetrievalData(data.ppapPackagePartsEntityID).subscribe(
					response => {
						const respOne = response.pswPartDetailEntity;
						for (const dataOne of this.partNumberDetail) {
							if (respOne.ppapPackagePartsEntityID === dataOne.ppapPackagePartsEntityID) {
								//dataOne.nextAuditLayout = respOne.nextAuditPlan;
								dataOne.isFormCompleted = respOne.isFormCompleted;
								this.status = dataOne.status;
								//data.nextAuditLayout = this.nextAuditPlan;
							}
						}
						this.partNumberDetail = this.partNumberDetail;
						for (const dataOne of this.partNumberDetail) {
							if (dataOne.isFormCompleted === 'false') {
								this.disableSubmit = false;
								break;
							} else {
								this.disableSubmit = true;
							}
						}
					});
				if (!this.pswApproverAccessControl.get('approverConditionEnabled')) {
					for (const dataOne of this.partNumberDetail) {
						if (this.ppapPackagePartsEntityId === dataOne.ppapPackagePartsEntityID) {
							if (dataOne.status === 'REJECTED' || dataOne.status === 'NEEDS CLARIFICATION' || dataOne.status === 'INTERIM ACCEPTED') {
								this.hideSubmit = true;
							}
						}
					}
				}
			}
		});
	}

	getSelectedPartDataSupplierView() {
		console.log('*** Calling getAllData  *** colorpart should be called below');
		this.digitalService.getAllData(this.ppapPackageIdentifierId).subscribe(resp => {
			console.log('resp.listOfPpapPackageParts' + resp.listOfPpapPackageParts);
			console.log('tesssst'+this.ppapPackageIdentifierId);
			resp.listOfPpapPackageParts[0].ppapPackageIdentifierID;
			this.partNumberDetail = resp.listOfPpapPackageParts;
			try {
				for (const data of this.partNumberDetail) {
					if (data.ppapPackagePartsEntityID === this.ppapPackagePartsEntityId) {
						this.programCode = data.programCode;
						this.noPartPrefix = data.noPartPrefix;
						this.noPartBase = data.noPartBase;
						this.noPartSuffix = data.noPartSuffix;
						this.ppapLevel = data.ppapLevel;
						this.onSelectDropdown();
						this.getInterimDisplay();
					}
				}
			} catch (error) {
				console.error('Log error', error);
			}
			console.log('Script execution continues');
			console.log('*** Current mode: ' + this.mode);
			if (this.mode === 'view') {
				this.getColorPartsForViewMode();
			}
		});
	}

	private getInterimDisplay() {
		if (this.displayPpapPhase === 'Phase1' || this.displayPpapPhase === 'Phase2') {
			if (this.ppapLevel === '1' && this.ppapPackageIdentifierFromSupplier.pdApprovalRequired === '0') {
				this.isInterimCheckboxVisible = false;
				this.isShownMandatory = false;
			} else {
				this.isInterimCheckboxVisible = true;
				//this.isShownMandatory = true;
			}
		} else {
			this.isInterimCheckboxVisible = false;
		}

	}

	//Added for US3853075
	newRow() {
		return {
			/*sNo: '',
			partNumber: '',
			partDescription: '', status: '',
			lastUpdatedBy: '', lastUpdatedOn: '',
			select: ''*/
			ppapPackageIdentifierID: '',
			ppapPackagePartsEntityID: '',
			programCode: '',
			noPartPrefix: '', noPartBase: '',
			noPartSuffix: '', programDescription: '',
			rarDt: '', qualDt: '',
			prodDt: '', capDt: '', status: '', ppapLevel: '',
			select: ''
		};
	}

	addRowDisable() {
		console.log(this.ppapPartsArray);
		(document.getElementById('addRow') as HTMLInputElement).disabled = true;
	}

	rowEnableDisable() {
		if (this.partNumbers !== undefined) {
			this.partDisableEnable = false;
		}
	}

	newRowCheck() {
		if (this.partNumbers !== undefined && this.partPappLevel !== undefined) {
			this.partDisableEnable = false;
		}
		if (this.partNumbersOption.length < 1) {
			this.messageService.add({
				severity: 'info',
				summary: 'Info',
				detail: 'No Parts available to add for the selected PSW form detail.'
			});
			this.getAllDataForParts();
			// this.getPrefixBaseSuffixForParts();
		}
	}

	getAllDataForParts() {
		this.digitalPpapPackageService.getAllData(this.ppapPackageIdentifierId).subscribe(resp => {
			this.ppapPartsArray = resp.listOfPpapPackageParts;
			console.log('PPAP_PARTS_Response_5: ', this.ppapPartsArray, '\nSIZE_5: ', this.ppapPartsArray.length);
			// this.ppapPartsArray.sort();
			this.ppapPartsArray.sort((a, b) => (a.noPartBase < b.noPartBase ? -1 : 1));
			this.ppapPartsArrayLength = this.ppapPartsArray.length;
		});
		// if (this.ppapPartsArray.length === 1)
		// 	this.disableDelete = true;
		// else
		// 	this.disableDelete = false;
	}

	// onRowEditSaveForParts(prefixBaseSuffix, ppapPartsArray: any) {
	// 	// if (prefixBaseSuffix !== undefined) {
	// 	// 	this.supplierViewService.savePswFamilyParts(prefixBaseSuffix, this.ppapPackagePartsEntityId).subscribe(
	// 	// 		respOne => {
	// 				this.supplierViewService.saveFamilyAnnex(prefixBaseSuffix, this.ppapPackagePartsEntityId,ppapPartsArray).subscribe(
	// 					respThree => {
	// 						this.supplierViewService.getFamilyRetrievalData(this.familyPswId).subscribe(respTwo => {
	// 							this.ppapPartsArray = respTwo;
	// 							(document.getElementById('addRow') as HTMLInputElement).disabled = false;
	// 							this.getNonFamilyParts();
	// 							this.retrieveFamilyAnnexDetails();
	// 						});
	// 					});
	// 			// });
	// 	// }
	// }

	onRowDeleteForParts(ppapParts: PpapPackageParts) {
		// this.ppapPartsArray = this.ppapPartsArray.filter(e => e.ppapPackageIdentifierID !== '');
		console.log('PPAP Parts Array Length: ' + this.ppapPartsArray.length);
		console.log('PPAP_PARTS_Response_6: ', this.ppapPartsArray, '\nSIZE_6: ', this.ppapPartsArray.length);
		if (this.ppapPartsArray.length === 1) {
			if (ppapParts.ppapPackagePartsEntityID !== null && ppapParts.ppapPackagePartsEntityID !== undefined) {
				this.confirmationService.confirm({
					message: 'Are you sure you want to delete the part from this family ??',
					header: 'Confirm',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						this.supplierViewService.deletePswPart(ppapParts.ppapPackagePartsEntityID).subscribe(resp => {
							this.supplierViewService.getFamilyRetrievalData(this.familyPswId).subscribe(respOne => {
								this.ppapPartsArray = respOne;
								console.log('PPAP_PARTS_Response_7: ', this.ppapPartsArray, '\nSIZE_7: ', this.ppapPartsArray.length);
								this.router.navigate(['/supplierView/' + this.ppapPackageIdentifierId + '/' + this.mode]);
								const msg = 'PSW Part is deleted successfully';
								this.messageService.add({
									severity: 'info',
									summary: 'Info',
									detail: msg,
									life: 3000
								});
								this.getNonFamilyParts();
							});
						});
					},
					reject: () => {
					}
				});
			}
		} else {
			if (ppapParts.ppapPackagePartsEntityID !== null && ppapParts.ppapPackagePartsEntityID !== undefined) {
				this.confirmationService.confirm({
					message: 'Are you sure you want to delete the part from this family ??',
					header: 'Confirm',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						this.supplierViewService.deletePswPart(ppapParts.ppapPackagePartsEntityID).subscribe(resp => {
							this.supplierViewService.getFamilyRetrievalData(this.familyPswId).subscribe(respOne => {
								this.ppapPartsArray = respOne;
								console.log('PPAP_PARTS_Response_8: ', this.ppapPartsArray, '\nSIZE_8: ', this.ppapPartsArray.length);
								const msg = 'PSW Part is deleted successfully';
								this.messageService.add({
									severity: 'info',
									summary: 'Info',
									detail: msg,
									life: 3000
								});
								this.getNonFamilyParts();
								this.retrieveFamilyAnnexDetails()
							});
						});
					},
					reject: () => {
					}
				});
			}
		}
	}

	checkIfPresent() {
		let packagelist;
		let data;
		packagelist = this.partNumberDetail.map(parts => parts.ppapPackagePartsEntityID);
		const found = packagelist.filter(value => sessionStorage.getItem('checkedEntityIdList') !== null && !sessionStorage.getItem('checkedEntityIdList').includes(value));
		data = this.partNumberDetail.filter(parts => found.includes(parts.ppapPackagePartsEntityID))
			.map(parts => parts.noPartPrefix + '-' + parts.noPartBase + '-' + parts.noPartSuffix);
		for (const dataValue of data) {
			this.partNumbersOption.push({label: dataValue, value: dataValue})
		}
	}

	getNonFamilyParts() {
		this.supplierViewService.getFamilyRetrievalData(0).subscribe(respOne => {
			this.partNumbersOption = [];
			if (respOne.length > 0) {
				for (const parts of respOne) {
					this.partNumbersOption.push({
						label: parts.noPartPrefix + '-' + parts.noPartBase + '-' + parts.noPartSuffix,
						value: parts.noPartPrefix + '-' + parts.noPartBase + '-' + parts.noPartSuffix
					})
				}
			} else {
				(document.getElementById('addRow') as HTMLInputElement).disabled = true;
			}
		});
	}

	//Added for US4027839
	async downloadFamilyPSWAnnexure() {
		console.log('Download Family PSW Annexure');
		await this.updateFamilyPSWAnnexureOptionalHeader(5).then();
		this.supplierViewService.downloadFamilyPSWAnnexureExcel(this.familyPswId);
	}

	onFamilyAnnexureExcelUpload(event) {
		console.log(event.target.files[0].type);
		if (event.target.files && event.target.files[0]) {
			console.log('Upload Excel file called');
			console.log(this.ppapPackageIdentifierId)
			const file = event.target.files[0];
			const uploadFamilyAnnexureExcel = new FormData();
			uploadFamilyAnnexureExcel.append('excelFile', file, file.name);
			uploadFamilyAnnexureExcel.append('ppapPackagePartsEntityID', this.ppapPackagePartsEntityId.toString());
			uploadFamilyAnnexureExcel.append('ppapPackageIdentifierId', this.ppapPackageIdentifierId.toString());
			if (event.target.files[0].type === 'application/vnd.ms-excel.sheet.macroEnabled.12' || event.target.files[0].type === 'application/vnd.ms-excel.sheet.macroenabled.12') {
				this.supplierViewService.uploadFamilyAnnexureExcel(uploadFamilyAnnexureExcel).subscribe(resp => {
					if (resp.toString().length > 0) {
						console.log('Excel upload response');
						this.messageService.add({
							severity: 'success',
							summary: 'success',
							detail: 'Uploaded Family Annexure Excel Successfully.'
						});
						this.getAllData();
						this.getFamilyPSWAnnexureOptionalHeader();
						// (document.getElementById('addRow') as HTMLInputElement).disabled = false;
					} else {
						this.messageService.add({
							severity: 'warn',
							summary: 'warning',
							detail: 'Please Upload Excel with Data',
							life: 5000
						});
					}
				}, error => {
					console.log('Error on upload Tier2Excel');
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Unable to upload Excel Family PSW Annexture. Please fill out all mandatory fields in the excel till you see ' +
							'Green Success after save, then upload again.',
						life: 5000
					});
				});
			} else {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Please use sample file type format.',
					life: 5000
				});
			}

		}
	}

	private retrieveFamilyAnnexDetails() {
		this.supplierViewService.getFamilyAnnexDetails(this.familyPswId).subscribe(resp => {
			this.ppapPartsArray = resp;
			console.log('PPAP_PARTS_Response_9: ', this.ppapPartsArray, '\nSIZE_9: ', this.ppapPartsArray.length);
			// for(const item of this.ppapPartsArray){
			// 	this.supplierViewService.getFamilyAnnexDetails(item.ppapPackagePartsEntityID).subscribe(respOne => {
			// 		this.ppapPartsArray = respOne;
			// 	});
			// }
		});
	}

	onRowEditInit(rowData: PpapPackageParts) {
		this.clonedData[rowData.ppapPackagePartsEntityID] = {...rowData};
	}

	onRowEditSaveForParts(packageParts: PpapPackageParts) {
		if (packageParts.familyPswOrgPartNumber !== undefined && packageParts.familyPswOrgPartNumber !== null && packageParts.familyPswOrgPartNumber !== ''
			&& packageParts.familyPswPoNumber !== undefined && packageParts.familyPswPoNumber !== null && packageParts.familyPswPoNumber !== ''
			&& packageParts.familyPswWeight !== undefined && packageParts.familyPswWeight !== null && packageParts.familyPswWeight !== ''
			&& packageParts.familyPswIMDSIDNo !== undefined && packageParts.familyPswIMDSIDNo !== null && packageParts.familyPswIMDSIDNo !== '') {
			console.log('indise edit save');
			// if (packageParts.familyPswOrgPartNumber !== undefined && packageParts.familyPswPoNumber !== undefined
			// 	&& packageParts.familyPswWeight !== undefined && packageParts.familyPswIMDSIDNo !== undefined) {
			this.supplierViewService.updateData(packageParts).subscribe(resp => {
				this.loading = false;
				// this.getAllData();
			}, error => {
				this.loading = false;
			});
			// } else {
			// 	this.messageService.add({
			// 		severity: 'error',
			// 		summary: 'Error',
			// 		detail: 'Please Fill the Required Fields in Family PSW Annexure!',
			// 		life: 5000
			// 	});
			// 	this.getAllData();
			// }
		} else {
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Please Fill the Required Fields in Family PSW Annexure!',
				life: 5000
			});
			// this.getAllData();
		}
	}

	private getAllData() {
		this.loading = true;
		this.supplierViewService.getAllFamilyAnnexPswDetails(this.ppapPackageIdentifierId).subscribe(resp => {
			this.ppapPartsArray = resp;
			console.log('PPAP_PARTS_Response_10: ', this.ppapPartsArray, '\nSIZE_10: ', this.ppapPartsArray.length);
			for (const data of this.ppapPartsArray) {
				this.familyId = data.familyPswId;
			}
			if (this.familyId !== undefined && this.familyId !== null) {
				sessionStorage.setItem('familyPswId', this.familyId.toString());
				this.familyPswId = sessionStorage.getItem('familyPswId');
			}
			this.loading = false;
			// for(const item of this.ppapPartsArray){
			// 	this.supplierViewService.getFamilyAnnexDetails(item.ppapPackagePartsEntityID).subscribe(respOne => {
			// 		this.ppapPartsArray = respOne;
			// 	});
			// }
			if (this.actionMode === 'familyView' || this.ppapPartsArray.length !== 0)
				this.spinnerService.hideFamilySpinner();
		});
	}

	newRowForColorParts() {
		return {
			colorPartsFamilyPswId: '',
			ppapPackagePartsEntityID: '',
			programName: '',
			whiteparts: '',
			noPartPrefix: '', noPartBase: '',
			noPartSuffix: '', partDescription: '',
			siteCode: '', partNumber: ''
		};
	}

	addRow() {
		this.colorPartNumbers = null;
		(document.getElementById('addColorParts') as HTMLInputElement).disabled = true;
	}

	colorPartDisable(partNumber) {
		if (partNumber !== undefined) {
			this.colorPartDisableEnable = false;
		}
	}

	newColorParts() {
		for (const data of this.colorPartNumbersOption) {
			if (!this.partNumberList.includes(data.label)) {
				this.partNumberList.push(data.label);
			}
		}
		for (const data of this.colorPartsList) {
			if (this.partNumberList.includes(data.partNumber)) {
				this.partNumberList = this.partNumberList.filter(part => part !== data.partNumber);
			}
		}
		this.colorPartNumbersOption = [];
		for (const data of this.partNumberList) {
			this.colorPartNumbersOption.push({
				label: data,
				value: data
			});
		}
	}

	onRowEditSaveForColorParts(colorPartNumbers) {
		if (colorPartNumbers !== undefined) {
			this.digitalPpapPackageService.addNewPart(colorPartNumbers, this.partNumberDetail[0].ppapPackagePartsEntityID).subscribe(savedParts => {
				console.log(savedParts);
				this.colorPartsList = savedParts;
				for (const data of this.colorPartsList) {
					this.partNumberList = this.partNumberList.filter(i => i === data.partNumber);
				}
				if (this.colorPartsList.length !== this.colorPartsListFromUnfiltered.length) {
					(document.getElementById('addColorParts') as HTMLInputElement).disabled = false;
				}
			});
		}
	}

	onRowDeleteForColorParts(colorParts: any) {
		if (colorParts.colorPartsFamilyPswId !== null && colorParts.colorPartsFamilyPswId !== undefined) {
			this.confirmationService.confirm({
				message: 'Are you sure you want to delete the color part?',
				header: 'Confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					for (const data of this.colorPartsList) {
						this.partNumberList = this.partNumberList.filter(i => i === data.partNumber);
					}
					for (const data of this.colorPartNumbersOption) {
						this.partNumberList.push(data.label);
					}
					if (!this.partNumberList.includes(colorParts.partNumber)) {
						this.colorPartNumbersOption.push({
							label: colorParts.noPartPrefix + '-' + colorParts.noPartBase + '-' + colorParts.noPartSuffix,
							value: colorParts.noPartPrefix + '-' + colorParts.noPartBase + '-' + colorParts.noPartSuffix
						});
					}
					this.digitalPpapPackageService.deleteColorParts(colorParts).subscribe(response => {
						if (response.genericString === 'Success') {
							this.digitalPpapPackageService.getAllColorPartsFamilyPswList().subscribe(resp => {
								console.log('getColorPartsFamilyPswList' + resp);
								this.colorPartsList = resp;
								this.enableAddParts = true;
								(document.getElementById('addColorParts') as HTMLInputElement).disabled = false;
								const msg = 'Color Part is deleted successfully';
								this.messageService.add({
									severity: 'info',
									summary: 'Info',
									detail: msg,
									life: 3000
								});
							});
						}
					});
				},
				reject: () => {
				}
			});
		}
	}

	getAllSupplierDetails() {
		console.log('getAllSupplierDetails Called');
		this.supplierViewService.getAllPswData(this.ppapPackageIdentifierId).subscribe(resp3 => {
			console.log('resp3', resp3);
			if (null !== resp3 && resp3.length > 0) {
				for (const item of resp3) {
					if (item.authSignDate !== null && this.actionMode === 'view' || this.actionMode === 'familyView') {
						console.log('enter into',);
						this.pswPartDetailSave.authSignDate = item.authSignDate;
					} else {
						console.log('enter into else');
						this.pswPartDetailSave.authSignDate = item.authSignDate !== null ? item.authSignDate:new Date();
						console.log('enter into else ## ' + this.pswPartDetailSave.authSignDate)
					}
				}
			} else {
				this.pswPartDetailSave.authSignDate = new Date();
			}
		});

		// console.log('enter view', this.actionMode);
		// // @ts-ignore
		// if(this.actionMode!=='familyNew')  {
		// 	console.log('enter view', this.actionMode);
		// 	this.pswPartDetailSave.authSignDate = this.pswStaStatusOn;
		//
		// } else {
		// 	this.pswPartDetailSave.authSignDate = new Date();
		// }
		// if (this.actionMode!=='new') {
		//   this.pswPartDetailSave.authSignDate = this.pswStaStatusOn
		// } else {
		// 	this.pswPartDetailSave.authSignDate = new Date();
		//
		//  }
		this.homePageService.getDataOfSelectedRow(this.ppapPackageIdentifierId).subscribe(resp2 => {
			this.ppapPackageIdentifier = resp2.ppapPackageIdentifierEntity;
			this.homePageService.getSupplierName(this.ppapPackageIdentifier.siteCode).subscribe(respOne => {
				console.log('Response for Supplier Name : ' + respOne);
				this.supplierViewService.getAllPswData(this.ppapPackageIdentifierId).subscribe(resp3 => {
					console.log('resp3', resp3);
					for (const item of resp3) {
						if (item.authSignDate !== null && this.actionMode === 'view' || this.actionMode === 'familyView') {
							console.log('enter into',);
							this.pswPartDetailSave.authSignDate = item.authSignDate;
						} else {
							console.log('enter into else');
							this.pswPartDetailSave.authSignDate = item.authSignDate !== null ? item.authSignDate:new Date();
						}
						if (item.supplierCode !== null) {
							this.pswPartDetailSave.supplierCode = item.supplierCode;
						} else {
							this.pswPartDetailSave.supplierCode = respOne.simsahSelectedSiteCodeC;
						}
						if (item.orgName !== null) {
							this.pswPartDetailSave.orgName = item.orgName;
						} else {
							this.pswPartDetailSave.orgName = respOne.simsahSelectedSiteName;
						}
						if (item.street !== null) {
							this.pswPartDetailSave.street = item.street;
						} else {
							this.pswPartDetailSave.street = respOne.simsahStaSelectedSiteStreetName;
						}
						if (item.city !== null) {
							this.pswPartDetailSave.city = item.city;
						} else {
							this.pswPartDetailSave.city = respOne.simsahStaSelectedSiteCityName;
						}
						if (item.state !== null) {
							this.pswPartDetailSave.state = item.state;
						} else {
							this.pswPartDetailSave.state = respOne.simsahStaSelectedStateProvince;
						}
						if (item.postalCode !== null) {
							this.pswPartDetailSave.postalCode = item.postalCode;
						} else {
							this.pswPartDetailSave.postalCode = respOne.simsahStaSelectedSitePost;
						}
						if (item.country !== null) {
							this.pswPartDetailSave.country = item.country;
						} else {
							this.pswPartDetailSave.country = respOne.simsahStaSelectedCountry;
						}
						if (item.country !== null) {
							this.pswPartDetailSave.authTitle = item.authTitle;
						} else {
							this.pswPartDetailSave.authTitle = 'Quality Manager';
						}
						if (item.orgAuthSign !== null) {
							this.pswPartDetailSave.orgAuthSign = item.orgAuthSign;
						} else {
							this.pswPartDetailSave.orgAuthSign = respOne.simsahProdQcMgr;
						}
						if (item.authPrintName !== null) {
							this.pswPartDetailSave.authPrintName = item.authPrintName;
						} else {
							this.pswPartDetailSave.authPrintName = respOne.simsahProdQcMgr;
						}

						//this.pswPartDetailSave.authSignDate = new Date();

						if (item.authEmail !== null) {
							this.pswPartDetailSave.authEmail = item.authEmail;
						} else {
							this.pswPartDetailSave.authEmail = respOne.simsahProdQcMgrEmail;
						}
						if (item.authPhone !== null) {
							this.pswPartDetailSave.authPhone = item.authPhone;
						} else {
							this.pswPartDetailSave.authPhone = respOne.simsahProdQcMgrPhone;
						}
					}
				});
			});
		});
	}

	/**
	 * Fetching Optional Column Header based on Family PSW Annexure Identifier ID
	 */
	getFamilyPSWAnnexureOptionalHeader() {
		console.log(this.ppapPackageIdentifierId);
		this.supplierViewService.fetchFamilyPSWAnnexureOptionalHeader(this.ppapPackageIdentifierId).subscribe(resp1 => {
			const resp = resp1.familyPswAnnexureOptionalHeader;
			console.log(resp);
			if (resp !== undefined && resp !== null) {
				this.familyPswOptionalObject = resp;
				this.familyPswAnnexureOptionalHeaderpartNumberList[0] = resp.familyPswAnnexureOptionalHeader1;
				this.familyPswAnnexureOptionalHeaderpartNumberList[1] = resp.familyPswAnnexureOptionalHeader2;
				this.familyPswAnnexureOptionalHeaderpartNumberList[2] = resp.familyPswAnnexureOptionalHeader3;
				this.familyPswAnnexureOptionalHeaderpartNumberList[3] = resp.familyPswAnnexureOptionalHeader4;
				this.familyPswAnnexureOptionalHeaderpartNumberList[4] = resp.familyPswAnnexureOptionalHeader5;
			}
		});
	}

	/**
	 * Updating/Inserting Optional Column Header based on Family PSW Annexure Identifier ID
	 */
	async updateFamilyPSWAnnexureOptionalHeader(optional) {
		if (this.familyHeaderID !== undefined && this.familyHeaderID !== null) {
			this.familyPswOptionalObject.familyPswAnnexureOptionalHeaderID = this.familyHeaderID;
		}
		console.log(optional);
		console.log(this.ppapPackageIdentifierId);
		this.familyPswOptionalObject.ppapPackageIdentifierID = this.ppapPackageIdentifierId;
		if (this.familyPswAnnexureOptionalHeaderpartNumberList[0].trim().length === 0) {
			this.familyPswAnnexureOptionalHeaderpartNumberList[0] = 'APW';
			this.messageService.add({
				severity: 'info',
				summary: 'Annexure Optional Header cannot be blank',
				life: 5000,
			});
		} else if (optional === 0 && (this.familyPswAnnexureOptionalHeaderpartNumberList[0] === this.familyPswAnnexureOptionalHeaderpartNumberList[1] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[0] === this.familyPswAnnexureOptionalHeaderpartNumberList[2] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[0] === this.familyPswAnnexureOptionalHeaderpartNumberList[3] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[0] === this.familyPswAnnexureOptionalHeaderpartNumberList[4])) {
			this.familyPswAnnexureOptionalHeaderpartNumberList[0] = 'APW';
			this.messageService.add({
				severity: 'info',
				summary: 'Annexure Optional Header Already Exists',
				life: 5000,
			});
		}
		if (this.familyPswAnnexureOptionalHeaderpartNumberList[1].trim().length === 0) {
			this.familyPswAnnexureOptionalHeaderpartNumberList[1] = 'MPW';
			this.messageService.add({
				severity: 'info',
				summary: 'Annexure Optional Header cannot be blank',
				life: 5000,
			});
		} else if (optional === 1 && (this.familyPswAnnexureOptionalHeaderpartNumberList[1] === this.familyPswAnnexureOptionalHeaderpartNumberList[0] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[1] === this.familyPswAnnexureOptionalHeaderpartNumberList[2] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[1] === this.familyPswAnnexureOptionalHeaderpartNumberList[3] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[1] === this.familyPswAnnexureOptionalHeaderpartNumberList[4])) {
			this.familyPswAnnexureOptionalHeaderpartNumberList[1] = 'MPW';
			this.messageService.add({
				severity: 'info',
				summary: 'Annexure Optional Header Already Exists',
				life: 5000,
			});
		}
		if (this.familyPswAnnexureOptionalHeaderpartNumberList[2].trim().length === 0) {
			this.familyPswAnnexureOptionalHeaderpartNumberList[2] = 'APPC';
			this.messageService.add({
				severity: 'info',
				summary: 'Annexure Optional Header cannot be blank',
				life: 5000,
			});
		} else if (optional === 2 && (this.familyPswAnnexureOptionalHeaderpartNumberList[2] === this.familyPswAnnexureOptionalHeaderpartNumberList[1] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[2] === this.familyPswAnnexureOptionalHeaderpartNumberList[0] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[2] === this.familyPswAnnexureOptionalHeaderpartNumberList[3] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[2] === this.familyPswAnnexureOptionalHeaderpartNumberList[4])) {
			this.familyPswAnnexureOptionalHeaderpartNumberList[2] = 'APPC';
			this.messageService.add({
				severity: 'info',
				summary: 'Annexure Optional Header Already Exists',
				life: 5000,
			});
		}
		if (this.familyPswAnnexureOptionalHeaderpartNumberList[3].trim().length === 0) {
			this.familyPswAnnexureOptionalHeaderpartNumberList[3] = 'MPPC';
			this.messageService.add({
				severity: 'info',
				summary: 'Annexure Optional Header cannot be blank',
				life: 5000,
			});
		} else if (optional === 3 && (this.familyPswAnnexureOptionalHeaderpartNumberList[3] === this.familyPswAnnexureOptionalHeaderpartNumberList[1] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[3] === this.familyPswAnnexureOptionalHeaderpartNumberList[2] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[3] === this.familyPswAnnexureOptionalHeaderpartNumberList[0] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[3] === this.familyPswAnnexureOptionalHeaderpartNumberList[4])) {
			this.familyPswAnnexureOptionalHeaderpartNumberList[3] = 'MPPC';
			this.messageService.add({
				severity: 'info',
				summary: 'Annexure Optional Header Already Exists',
				life: 5000,
			});
		}
		if (this.familyPswAnnexureOptionalHeaderpartNumberList[4].trim().length === 0) {
			this.familyPswAnnexureOptionalHeaderpartNumberList[4] = 'OPTIONAL';
			this.messageService.add({
				severity: 'info',
				summary: 'Annexure Optional Header cannot be blank',
				life: 5000,
			});
		} else if (optional === 4 && (this.familyPswAnnexureOptionalHeaderpartNumberList[4] === this.familyPswAnnexureOptionalHeaderpartNumberList[1] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[4] === this.familyPswAnnexureOptionalHeaderpartNumberList[2] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[4] === this.familyPswAnnexureOptionalHeaderpartNumberList[3] ||
			this.familyPswAnnexureOptionalHeaderpartNumberList[4] === this.familyPswAnnexureOptionalHeaderpartNumberList[0])) {
			this.familyPswAnnexureOptionalHeaderpartNumberList[4] = 'OPTIONAL';
			this.messageService.add({
				severity: 'info',
				summary: 'Annexure Optional Header Already Exists',
				life: 5000,
			});
		}
		this.familyPswOptionalObject.familyPswAnnexureOptionalHeader1 = this.familyPswAnnexureOptionalHeaderpartNumberList[0];
		this.familyPswOptionalObject.familyPswAnnexureOptionalHeader2 = this.familyPswAnnexureOptionalHeaderpartNumberList[1];
		this.familyPswOptionalObject.familyPswAnnexureOptionalHeader3 = this.familyPswAnnexureOptionalHeaderpartNumberList[2];
		this.familyPswOptionalObject.familyPswAnnexureOptionalHeader4 = this.familyPswAnnexureOptionalHeaderpartNumberList[3];
		this.familyPswOptionalObject.familyPswAnnexureOptionalHeader5 = this.familyPswAnnexureOptionalHeaderpartNumberList[4];
		console.log(this.familyPswOptionalObject);
		await this.supplierViewService.updateFamilyPSWAnnexureOptionalHeader(this.familyPswOptionalObject).toPromise().then(resp => {
			console.log(resp);
			this.familyPswOptionalObject = resp;
			this.familyHeaderID = resp.familyPswAnnexureOptionalHeaderID;
		});
	}

	onOptionalFocusOut(optional) {
		console.log(this.ppapPackageIdentifierId);
		this.updateFamilyPSWAnnexureOptionalHeader(optional);
	}

	afterClick() {
		this.optionaltext1.changes.subscribe(() => {
			if (!this.isOptional1) {
				this.optionaltext1.first.nativeElement.focus();
			}
		});
		this.optionaltext2.changes.subscribe(() => {
			if (!this.isOptional2) {
				this.optionaltext2.first.nativeElement.focus();
			}
		});
		this.optionaltext3.changes.subscribe(() => {
			if (!this.isOptional3) {
				this.optionaltext3.first.nativeElement.focus();
			}
		});
		this.optionaltext4.changes.subscribe(() => {
			if (!this.isOptional4) {
				this.optionaltext4.first.nativeElement.focus();
			}
		});
		this.optionaltext5.changes.subscribe(() => {
			if (!this.isOptional5) {
				this.optionaltext5.first.nativeElement.focus();
			}
		});
	}

	// In PSW Form page and opened in edit mode  -
	// get the color parts from unfiltered table and store in color parts table and
	// retrieve from color parts table and display in UI
	private async getColorParts() {
		this.pswResponse = await this.homePageService.getDataOfSelectedRow(this.ppapPackageIdentifierId)
			.toPromise()
			.catch((err) => console.log('error in fetch ppapdetails'))
		if (this.pswResponse instanceof PswResponse) {
			this.ppapPackageIdentifier = this.pswResponse.ppapPackageIdentifierEntity;
		}
		const siteCode = this.ppapPackageIdentifier.siteCode;
		if (this.mode !== 'view') {
			if (this.isHideAnnexureForIndividualpart) {
				this.digitalPpapPackageService.getColorPartsByPpapPartIdsAndSiteCode(
					this.ppapPackagePartsEntityId,
					siteCode).subscribe(colorPartsList => {
					this.colorPartsList = colorPartsList;
				});
			} else {
				if (this.partNumberDetail.length >= 1) {
					this.digitalPpapPackageService.retrievefromUnfilteredAndSaveGetFromColorPartsFamilyPswListWithSiteCode(
						this.partNumberDetail,
						siteCode).subscribe(colorPartsList => {
						this.colorPartsList = colorPartsList;
					});
				} else {
					this.digitalService.getAllData(this.ppapPackageIdentifierId).subscribe(resp => {
						this.partNumberDetail = resp.listOfPpapPackageParts;
						this.digitalPpapPackageService.retrievefromUnfilteredAndSaveGetFromColorPartsFamilyPswListWithSiteCode(
							this.partNumberDetail,
							siteCode).subscribe(colorPartsList => {
							this.colorPartsList = colorPartsList;
						});
					});
				}
			}
		}
	}

	private getColorPartsForViewMode() {
		if (this.isHideAnnexureForIndividualpart) {
			this.digitalPpapPackageService.getColorPartsByPpapPartIdsAndSiteCode(this.ppapPackagePartsEntityId, this.ppapPackageIdentifier.siteCode).subscribe(colorPartsList => {
				this.colorPartsList = colorPartsList;
			});
		} else {
			if (this.partNumberDetail.length >= 1) {
				this.digitalPpapPackageService.getColorPartsFamilyPswList(this.partNumberDetail).subscribe(colorPartsList => {
					this.colorPartsList = colorPartsList;
				});
			} else {
				this.digitalService.getAllData(this.ppapPackageIdentifierId).subscribe(resp => {
					this.partNumberDetail = resp.listOfPpapPackageParts;
				});
				if (this.partNumberDetail.length >= 1) {
					this.digitalPpapPackageService.getColorPartsFamilyPswList(this.partNumberDetail).subscribe(colorPartsList => {
						this.colorPartsList = colorPartsList;
					});
				}
			}
		}
	}

	/**
	 *
	 * @param value - input user entered value
	 * @param maxLength - maximum eligible length
	 * @param fieldName - input field name
	 * if the user entered input is greater than the maxLength
	 * show the warning message for 3 Sec
	 */
	onInputChange(value: string, maxLength: number, fieldName: string = '') {
		if (value.length > maxLength) {
			try {
				this.messageService.clear();
				this.messageService.add({
					severity: 'warn',
					summary: 'warning',
					detail: 'You have exceeded the characters limit. Please try to limit your update less than ' + maxLength + ' characters.',
					life: 3000
				});
			} catch(e) {
			}
		}
	}

	getEcarDetails(ppapPackageIdentifierId: any) {
		const defaultEcarRecord: PpapElementRecords = {
			ppapPackageAssessmentResponseEntityID: 0,
			ppapPackageIdentifierID: 0,
			sectionId: 0,
			questionId: 0,
			sectionName: '',
			sectionSortSequence: 0,
			questionName: '',
			questionSortSequence: 0,
			status: null,
			document: null,
			proprietary: null,
			supplierRemark: null,
			lastUpdatedBy: '',
			lastUpdatedOn: new Date(),
			staPdStatus: null,
			staPdRemark: null,
			staPdStatusBy: null,
			staPdStatusOn: null,
			createdBy: null,
			createdOn: null
		};

		const questionSortSequence = 17.04;
		this.digitalService.findEcarDetails(ppapPackageIdentifierId, questionSortSequence)
			.subscribe({
			next: (response: PpapElementRecords[]) => {
				console.log('getEcarDetails response:', response);
				this.errorState = false;
				this.ecarPpapAssessmentResponse = []; // Reset array

				if (response && response.length > 0) {
					const apiRecord = response[0];
					const ecarRecord = new PpapElementRecords(defaultEcarRecord);

					for (const key in defaultEcarRecord) {
						if (defaultEcarRecord.hasOwnProperty(key)) {
							if (apiRecord && apiRecord.hasOwnProperty(key) && apiRecord[key] !== null && apiRecord[key] !== undefined) {
								ecarRecord[key] = apiRecord[key];
							} else {
								ecarRecord[key] = defaultEcarRecord[key];
							}
						}
					}
					this.checkStatus = ecarRecord.status ?? '';
					this.initialRemark = ecarRecord.supplierRemark ?? '';
					if(this.checkStatus === 'OK') {
						this.supplierRemark = ecarRecord.supplierRemark ?? '';
					}
					if (this.supplierRemark === null || this.supplierRemark === '') {
						this.errorState = true;
					}
					this.ecarPpapAssessmentResponse.push(ecarRecord);
				} else {
					this.errorState = true;
				}
			},
			error: (error) => {
				this.errorState = true;
			}
		});
	}

	savePpapRecords() {
		console.log('this.supplierRemark' + this.supplierRemark);
		if (this.ecarPpapAssessmentResponse.length > 0 &&
			this.supplierRemark !== this.ecarPpapAssessmentResponse[0].supplierRemark &&
			this.supplierRemark !== undefined && this.supplierRemark !== null &&
			this.supplierRemark !== '') {
			this.ecarPpapAssessmentResponse[0].supplierRemark = this.supplierRemark;
			this.digitalService.savePpapElementRecords(this.ecarPpapAssessmentResponse).subscribe(
				() => {
					console.log('Save successful!');
				},
				(error) => {
					console.error('Save failed:', error);
				}
			);
		}
	}
}
