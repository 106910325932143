export class PpapElementRecords {
	ppapPackageAssessmentResponseEntityID: number;
	ppapPackageIdentifierID: number;
	sectionId: number;
	questionId: number;
	sectionName: string;
	sectionSortSequence: number;
	questionName: string;
	questionSortSequence: number;
	status: string;
	document: string;
	proprietary: string;
	supplierRemark: string;
	lastUpdatedBy: string;
	lastUpdatedOn: Date;
	staPdStatus: string;
	staPdRemark: string;
	staPdStatusBy: string;
	staPdStatusOn: Date;
	createdBy: string;
	createdOn: Date;

	constructor( ppapElementRecords : PpapElementRecords ) {
		Object.assign(this,ppapElementRecords);
	}
}
