import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	Output,
	EventEmitter,
	AfterViewInit,
} from '@angular/core';
import {PswPartDetail} from '../../../models/psw-part-detail';
import {PpapPackageParts} from '../../../models/ppap-package-parts';
import {MessageService} from 'primeng/api';

@Component({
	selector: 'print-psw-form',
	templateUrl: './print-psw-form.component.html',
	styleUrls: ['./print-psw-form.component.scss'],
})
export class PrintPswFormComponent implements OnInit, AfterViewInit {
	@Input() pswPartDetailSave: PswPartDetail;
	@Input() phase: any;
	@Input() selectedInterimCheckBox: any;
	@Input() overallStatus: any;
	@Input() pswStaStatusBy: any;
	@Input() pswStaStatusOn: any;
	@Input() pswPdStatusBy: any;
	@Input() pswPdStatusOn: any;
	@Input() approverCdsidStaMail: string;
	@Input() approverCdsidStaName: string;
	@Input() approverCdsidPdMail: string;
	@Input() approverCdsidPdName: string;
	//Added for US3853075
	@Input() ppapPartsArray: PpapPackageParts[];
	@Input() familyPswAnnexureOptionalHeaderpartNumberList: string[];
	@Input() familyPswId: number;
	@Input() entityIdList: string;
	@Input() actionMode: any;
	@Input() colorPartsList: any;
	@Output() loadingEvent : EventEmitter<boolean> = new EventEmitter();
	@Input() supplierRemark: string;

	constructor(public messageService: MessageService, public changeDetection: ChangeDetectorRef) {

	}

	ngOnInit(): void {
	}

	ngAfterViewInit() {
		this.attachEventListeners();
	}

	/**
	 * Based on the action mode and entityId list
	 * select the div element id to be printed.
	 * if family mode print familyPSWToPrint
	 * else myDivToPrint
	 */
	print() {
		let printDivId: string;
		if (this.actionMode === 'familyView' && this.entityIdList.length > 1) {
			printDivId = 'familyPSWToPrint';
		} else {
			printDivId = 'myDivToPrint'
		}
		this.printDiv(printDivId)
	}

	/**
	 * Open the print in new window and popup print options
	 * As soon as print operation completed, closes the newWindow
	 * @param divId - id of the element to be printed
	 * All required styles for print should be done here. Not in scss file
	 */
	printDiv(divId: string) {
		const head: any = window.document.head
		const title = 'ePSW';

		const divToPrint = document.getElementById(divId);
		if (divToPrint) {
			this.loadingEvent.emit(true)
			const newWin = window.open('', '_blank');
			if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
				this.messageService.add({
					severity: 'warn',
					summary: 'warning',
					detail: 'Please enable the popup in the browser and try print again'
				});
				this.loadingEvent.emit(false)
				return;
			}

			newWin.document.write(`
    <html>
      <head>
        <title>` + title + `</title>
        <style>
        @media print {
   				body {
      					-webkit-print-color-adjust: exact;
   				}
   				 header, footer {
        			display: none;
      			}
   				@page {
					size : A4
				}

				#hidden_div {
					display: block !important;
					width: 210mm !important;
				}
				#hidden_family_div {
					display: block !important;
					width: 210mm !important;
				}
				}
				body {
					margin: 0;
					overflow: visible !important;
					-webkit-print-color-adjust: exact !important;
				}
				#app-footer {
					display: none !important;
				}
				.myDivToPrint {
					//line-height:0px !important;
					background-color: white;
					height: 100% !important;
					width: 210mm;
					position: absolute;
					top: 0;
					left: 0;
					margin: 0;
					padding: 0;
					font-size: 8px;
					line-height: 18px;
					-webkit-print-color-adjust: exact !important;
					//overflow: auto !important;
					//page-break-before: always;
				}
				#hidden_family_div {
					display: block;
				}
				.familyPSWToPrint {
					//line-height:0px !important;
					background-color: white;
					height: 100%;
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					margin: 0;
					padding: 0;
					font-size: 8px;
					line-height: 18px;
					-webkit-print-color-adjust: exact !important;
					//page-break-before: auto;
				}
				.pagebreak {
					clear: both;
					page-break-after: always;
				}
				#radioPrintN3 .ui-radiobutton .ui-radiobutton-box {
					-webkit-transform: scale(0.4);
				}
				#radioPrintN3  .ui-radiobutton-label {
		 				margin: 0 !important;
		 				font-size: 0.4rem;
				}
				#radioPrintN3  .ui-radiobutton .ui-radiobutton-box {
					width: 16px;
					height: 16px;
				}
				#radioPrintN3  .ui-radiobutton  {
					display: inline-block !important;
					vertical-align: middle !important;;
					margin: 0 !important;;
					width: unset !important;;
					height: unset !important;;
				}
				#radioPrintN3 .ui-radiobutton .ui-radiobutton-box.ui-state-active {
					display: flex !important;
					justify-content: center !important;
					align-items: center !important;
				}

				.ui-chkbox .ui-chkbox-box {
					width: 8px;
					height: 8px;
				}

				.ui-chkbox {
					width: 8px;
					height: 8px;
				}
				.ui-chkbox .ui-chkbox-box {
					width: 10px !important;
					height: 10px !important;
					.ui-chkbox-icon {
						font-size: 8px !important;
						display: none !important;
					}
				}
				ui-chkbox .ui-chkbox-box {
					font-size: 8px;
				}
				.ui-chkbox {
					display: inline-block;
					vertical-align: middle;
					margin: 0;
					width: 10px !important;
					height: 10px !important;
				}

		#header-checkBox .ui-chkbox-label {
			font-size: 0.8rem;
			font-weight: 700;
		}

		#header-checkBox .ui-chkbox .ui-chkbox-box {
			width: 14px !important;
			height: 14px !important;
			display: flex;
    		align-items: center;
    		justify-content: center;
			.ui-chkbox-icon {
				font-size: 16px !important;
			}
		}


		#header-checkBox {
			display: flex;
    		justify-content: center;
    		align-items: center;
		}

		#header-checkBox .ui-chkbox .ui-chkbox-box.ui-state-active {
			display: flex !important;
			justify-content: center !important;
			align-items: center !important;
		}

		#header-checkBox .ui-chkbox-label {
		 	margin: 0 !important;
		 	font-size: 0.8rem;
		}

		#header-checkBox .ui-chkbox {
			display: inline-flex !important;
   			justify-content: center !important;
    		align-items: center !important;
    		margin: 0 !important;
   			width: 20px !important;
    		height: 20px !important;
		}

		#header-checkBox .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
			width: 8px !important;
			height: 8px !important;
			display: unset !important;
			margin: 0!important;
			padding: 0 !important;
			position: unset !important;
			left: unset !important;
			right: unset !important;
		}


		table {
			border: 1px solid black;
		}
		th,
		td {
			border-bottom: 1px solid black;
			border-right: 1px solid black;
		}

		 th,
		td {
			width: 200px;
		}

		.ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
			background-color: #2861A4 !important;
		}

		.ui-radiobutton .ui-radiobutton-box.ui-state-active {
			border-color: #102B4E !important;;
    		background-color: white !important;
    		color: #2861A4 !important;;
		}

		.phases {
			display: flex;
    		justify-content: center;
    		align-items: center;
		}

		.phases .ui-radiobutton .ui-radiobutton-box.ui-state-active {
			display: flex !important;
			justify-content: center !important;
			align-items: center !important;
		}

		.phases .ui-radiobutton .ui-radiobutton-box {
					width: 14px;
					height: 14px;
		}
		.phases .ui-radiobutton  {
					display: inline-block !important;
					vertical-align: middle !important;;
					margin: 0 !important;;
					width: 20px !important;;
					height: 20px !important;;
		}
		.phases .ui-radiobutton-label {
		 	margin: 0 !important;
		 	font-size: 0.8rem;
		}

		.phases .ui-radiobutton {
			display: inline-flex !important;
   			justify-content: center !important;
    		align-items: center !important;
		}

		.phases .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon {
			width: 8px !important;
			height: 8px !important;
			display: unset !important;
			margin: 0!important;
			padding: 0 !important;
			position: unset !important;
			left: unset !important;
			right: unset !important;
		}

		 .ui-chkbox .ui-chkbox-box {
			width: 8px;
			height: 8px;
		}

		 .ui-chkbox {
			width: 8px;
			height: 8px;
		}

		 .ui-chkbox-label {
			font-size: 8px;
		}






		/* #checkBox .ui-chkbox .ui-chkbox-box {*/
		/*	width: 18px;*/
		/*	height: 18px;*/
		/*	.ui-chkbox-icon {*/
		/*		font-size: unset !important;*/
		/*	}*/
		/*}*/

		/* #checkBox ui-chkbox .ui-chkbox-box {*/
		/*	font-size: unset;*/
		/*}*/

		/* #checkBox .ui-chkbox {*/
		/*	width: 18px;*/
		/*	height: 18px;*/

		/*}*/

		 #checkBox .ui-chkbox-label {
			font-size: 13px;
		}
		//Added for US3853075
		 #hidden_family_div {
			display: none;
		}

	  </style>
      </head>

      <body>
        <div class="myDivToPrint">${divToPrint.innerHTML}</div>
      </body>
    </html>
  `);

			// Copy the stylesheets from the main document to the new window
			const cssLinks: any[] = Array.from(document.querySelectorAll('link[rel=\'stylesheet\']'));
			cssLinks.forEach(link => {
				const newLink = newWin.document.createElement('link');
				newLink.rel = 'stylesheet';
				newLink.href = link.href;
				newWin.document.head.appendChild(newLink);
			});

			// Copy inline styles
			const inlineStyles = Array.from(document.querySelectorAll('style'));
			inlineStyles.forEach(style => {
				const newStyle = newWin.document.createElement('style');
				newStyle.textContent = style.textContent;
				newWin.document.head.appendChild(newStyle);
			});
			// Add custom print-specific styles

			newWin.document.close();

			// Ensure the new window is fully loaded before printing
			newWin.onload = () => {
				setTimeout(() => {
					newWin.scrollTo(0, 0)
					newWin.print();
					newWin.close();

					// Polling mechanism to detect if the window is closed manually
					const checkWindowClosed = setInterval(() => {
						if (newWin.closed) {
							clearInterval(checkWindowClosed);
							this.loadingEvent.emit(false)
							this.changeDetection.detectChanges();
							document.body.style.pointerEvents = 'auto'
						}}, 100);

					newWin.onbeforeunload = () => {
						clearInterval(checkWindowClosed);
						this.loadingEvent.emit(false)
						this.changeDetection.detectChanges();
						document.body.style.pointerEvents = 'auto'
					};
				}, 300);
			};
		}
	}

	closeWindowAndOpenInNewTab() {
	}

	attachEventListeners() {
		const inputs = document.querySelectorAll('input');
		inputs.forEach(input => {
			input.addEventListener('click', this.handleMouseClick);
		});
	}

	handleMouseClick(event: MouseEvent) {
		console.log('Input clicked', event);
	}

	reEnableMouseEvents() {
		const inputs = document.querySelectorAll('input');
		inputs.forEach(input => {
			input.addEventListener('click', this.handleMouseClick);
		});
		this.focusInput(); // Optionally set focus to an input field
	}

	focusInput() {
		const input = document.querySelector('input');
		if (input) {
			input.focus();
		}
	}
}
